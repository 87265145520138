import React, {useContext, useEffect} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Context } from './Context/AuthContext';
import PrivateRoute from "./PrivateRoute";
import history from "../routers/History";

import Login from "../pages/Login";
import Email from "../pages/Cadastro/Email";
import ConfirmarEmail from "../pages/Cadastro/ConfirmarEmail";
import Users from "../pages/Users";
import NovoDocumento from "../pages/NovoDocumento";
import PrimeiroCertificado from "../pages/Cadastro/PrimeiroCertificado";
import ProfilePage from "../pages/ProfilePage";
import VisualizarDocumento from "../pages/VisualizarDocumento";
import Pastas from "../pages/Pastas";
import VerificarAutenticidade from "../pages/VerificarAutenticidade";
import ForbiddenAccess from "../pages/ForbiddenAccess";
import CheckDocument from "../pages/Cadastro/CheckDocument";
import Username from "../pages/Cadastro/Username";
import VerificacaoIdentidade from "../pages/verificacao-identidade/VerificacaoIdentidade";
import Seflie from "../pages/verificacao-identidade/selfie/Seflie";
import FotoDocumento from "../pages/verificacao-identidade/foto-documento/FotoDocumento";
import CriarSenhaAssinatura from "../pages/CriarSenhaAssinatura";
import ForgotPasswordPage from "../pages/authentication/ForgotPasswordPage";
import RecoveryPasswordPage from "../pages/authentication/RecoveryPasswordPage";
import CreatePasswordPage from "../pages/Cadastro/CreatePasswordPage";
import PoliceTerms from "../pages/PoliceTerms";
import MyDocumentsPage from "../pages/documents/MyDocumentsPage";
import DocumentSent from "../pages/documents/DocumentsSentPage";
import Contacts from "../pages/contacts/Contacts";
import Dashboard from "../pages/dashboard/Dashboard";
import DocumentPhotoIOS from "../pages/verificacao-identidade/foto-documento/DocumentPhotoIOS";
import PIXValidation from "../pages/verificacao-identidade/pix/PIXValidation";
import Payment from "../pages/payment/Payment";
import APIInfo from "../pages/api/APIInfo";
import {isAuthorized, PERMISSIONS} from "../utils/permission/checkPermission";

function CustomRoute({ isPrivate, isVisitant, path, permission, ...rest }) {
  const { loading, isValidToken } = useContext(Context);
  const { setDarkMode, setSettings } = useContext(Context)

  useEffect(() => {
    try {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
        setSettings('darkMode', true)
        setDarkMode(e.matches)
      });

      const settings = JSON.parse(localStorage.getItem('settings'))
      const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
      setDarkMode(darkMode || settings.darkMode)
      setSettings(setSettings({darkMode: darkMode || settings.darkMode}))

      return () => {
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {});
      }
    } catch (e) {}
  }, [])

  if (loading)
    return <h1>Loading...</h1>

  if (isPrivate !== undefined && !isValidToken()) {
    const lastLocation = window.location.pathname;
    return <Redirect to={`/login?redirectTo=${lastLocation}`} />
  } else if (isPrivate !== undefined && isValidToken()) {
    if (isAuthorized(permission)) {
      return <PrivateRoute {...rest} />
    } else {
      history.push("/acesso-proibido")
    }
  }

  return <Route {...rest} />;
}

export default function Routes() {
  return (
    <Switch>
      <CustomRoute isVisitant exact path="/" component={Login} title="Login" />
      <CustomRoute isVisitant path="/login/:primeiroAcesso" component={Login} title="Login" />
      <CustomRoute isVisitant path="/login" component={Login} title="Login" />

      <CustomRoute exact isVisitant path="/nova-conta" component={Email} title="Nova conta" />
      <CustomRoute exact isVisitant path="/confirmar-email" component={ConfirmarEmail} title="Confirmar e-mail" />
      <CustomRoute exact isVisitant path="/iniciar-cadastro" component={CheckDocument} title="Iniciar cadastro" />
      <CustomRoute exact isVisitant path="/nome" component={Username} title="Seu nome" />
      <CustomRoute exact isVisitant path="/criar-senha" component={CreatePasswordPage} title="Criar senha" />
      <CustomRoute exact isVisitant path="/politica-privacidade-e-termos-de-uso" component={PoliceTerms} title="Política de privacidade e termos de uso" />

      <CustomRoute exact isVisitant path="/esqueci-minha-senha" component={ForgotPasswordPage} title="Esqueci minha senha" />
      <CustomRoute exact isVisitant path="/recuperar-senha" component={RecoveryPasswordPage} title="Recuperar senha" />

      <CustomRoute isPrivate single exact path="/verificar-identidade" component={VerificacaoIdentidade} title="Verificação de identidade" />
      <CustomRoute isPrivate single exact path="/pix" component={PIXValidation} title="Verificação via PIX" />
      <CustomRoute isPrivate single exact path="/selfie" component={Seflie} title="Selfie" />
      <CustomRoute isPrivate single exact path="/foto-documento" component={FotoDocumento} title="Foto do documento" />
      <CustomRoute isPrivate single exact path="/foto-documento-ios" component={DocumentPhotoIOS} title="Foto do documento" />

      <CustomRoute isPrivate single exact path="/primeiro-certificado" component={PrimeiroCertificado} title="Primeiro certificado" />

      <CustomRoute
        title="Documentos recebidos"
        path="/documentos"
        permission={PERMISSIONS.documents}
        isPrivate exact component={MyDocumentsPage} />

      <CustomRoute
        title="Usuários"
        path="/usuarios"
        permission={PERMISSIONS.collaborators}
        isPrivate exact component={Users}  />

      <CustomRoute
        title="Documentos enviados"
        path="/documentos-enviados"
        permission={PERMISSIONS.documents}
        isPrivate exact component={DocumentSent} />

      <CustomRoute
        path="/documentos/:id"
        permission={PERMISSIONS.documents}
        isPrivate exact component={VisualizarDocumento} />

      <CustomRoute
        title="Novo documento"
        path="/novo-documento"
        permission={PERMISSIONS.documents}
        isPrivate exact component={NovoDocumento} />

      <CustomRoute
        title="Verificar autenticidade"
        path="/verificar-autenticidade/:codDoc"
        exact component={VerificarAutenticidade} />
      <CustomRoute
        title="Verificar autenticidade"
        path="/verificar-autenticidade"
        exact component={VerificarAutenticidade} />

      <CustomRoute exact path="/criar-senha-assinatura" component={CriarSenhaAssinatura} title="Criar senha de assinatura" />

      <CustomRoute
        title="Contatos"
        path="/contatos"
        permission={PERMISSIONS.contacts}
        isPrivate exact component={Contacts} />

      <CustomRoute isPrivate exact path="/pastas" component={Pastas} title="Minhas pastas" mostrarDocumentos={true} />

      <CustomRoute
        title="Minha conta"
        path="/conta"
        permission={PERMISSIONS.profile}
        isPrivate exact component={ProfilePage} />

      <CustomRoute
        title="Pagamento"
        path="/pagamento"
        permission={PERMISSIONS.payment}
        isPrivate exact component={Payment} />

      <CustomRoute isPrivate exact path="/acesso-proibido" component={ForbiddenAccess} title="Acesso proibido" />

      <CustomRoute
        title="Dashboard"
        path="/dashboard"
        isPrivate exact component={Dashboard} />

      <CustomRoute
        title="API"
        path="/api"
        permission={PERMISSIONS.api}
        isPrivate exact component={APIInfo} />
    </Switch>
  );
}
