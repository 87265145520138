import React, {useContext, useEffect, useState} from 'react';

import SinglePage from "../components/template/SinglePage";
import Progress from "../components/template/Progress";
import API from "../services/api";
import {REGISTER} from "../services/registrationService";
import getTitle from "../utils/handleError";
import {Context} from "../routers/Context/AuthContext";
import ErrorAlert from "../components/utils/error-alert";
import {POLICY_TERMS} from "../services/authService";
import {getAnalytics, logEvent} from "firebase/analytics";

export default function PoliceTerms(props) {

  const [links, setLinks] = useState(null)
  const [acceptedContracts, setAcceptedContracts] = useState(
    new Array(3).fill(false)
  )
  const [accepted, setAccepted] = useState(false)
  const [progress, setProgress] = useState(false)
  const [error, setError] = useState(null)
  const { handleLogin } = useContext(Context)
  const analytics = getAnalytics()

  useEffect(() => {
    getLinks()
  }, [])

  const handleCheck = (position) => {
    const checked = acceptedContracts.map((item, index) =>
      index === position ? !item : item
    );
    setAcceptedContracts(checked)
    areChecked(checked)
  }
  
  const areChecked = (checked) => {
    let isChecked = true;
    checked.forEach(item => {
      if (!item) {
        isChecked = false;
      }
    })
    setAccepted(isChecked)
  }

  const getLinks = () => {
    API.get(POLICY_TERMS)
      .then((response) => {
        const result = response.data.data;
        setLinks(result);
      })
      .catch((error) => {
        const result = error.response.data;
        setError({
          title: getTitle(result.code),
          message: result.error
        });
      })
  }

  function finalizeRegistration (data) {
    // region sign_up
    logEvent(analytics, 'sign_up')
    // endregion

    setError(null);
    setProgress(true);
    API.post(REGISTER, data)
      .then((response) => {
        setProgress(false)
        const result = response.data.data;
        handleLogin(result);
      })
      .catch((error) => {
        setProgress(false)
        const result = error.response.data;
        setError({
          title: getTitle(result.code),
          message: result.error
        });
      });
  }

  return (
    <SinglePage>
      <div style={{marginBottom: 15}}>
        <p style={{fontWeight: 700, fontSize: 24, marginBottom: 0}}>
          Termos de uso e política de privacidade
        </p>
        <span className="description-md">Confirmo que li, entendi e concordo em vincular-me aos Termos de Uso, Política de Privacidade e Marco Jurídico.</span>
      </div>

      <div>
        <ErrorAlert error={error} />

        {
          links !== null ?
            <>
              <ul className="policy-terms">
                <li className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="checkbox-appearance"
                    style={{zoom: 1.5}}
                    value=""
                    checked={acceptedContracts[1]}
                    onChange={() => handleCheck(1)} />
                  <a href={links.termosUso} target="_blank" style={{marginLeft: 8}}>Termos de uso</a>
                </li>

                <li className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="checkbox-appearance"
                    style={{zoom: 1.5}}
                    value=""
                    checked={acceptedContracts[0]}
                    onChange={() => handleCheck(0)} />
                  <a href={links.politicaPrivacidade} target="_blank" style={{marginLeft: 8}}>Política de privacidade</a>
                </li>

                <li className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="checkbox-appearance"
                    style={{zoom: 1.5}}
                    value=""
                    checked={acceptedContracts[2]}
                    onChange={() => handleCheck(2)} />
                  <a href={links.marcoJuridico} target="_blank" style={{marginLeft: 8}}>Marco jurídico</a>
                </li>
              </ul>

              <button
                type="button"
                disabled={!accepted || progress}
                className="btn btn-assinauai btn-block"
                onClick={() => {
                  finalizeRegistration({
                    ...props.location.state
                  })
                }}>
                { progress ? <span><i className="fa fa-spinner fa-spin"/></span> : 'Continuar' }
              </button>
            </>
            :
            <Progress />
        }
      </div>
    </SinglePage>
  )

}