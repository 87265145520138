import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import ButtonPrimaryBlock from "../template/buttons/ButtonPrimaryBlock";
import ButtonLinkBlock from "../template/buttons/ButtonLinkBlock";
import API from "../../services/api";
import {handleError} from "../../utils/handleError";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";
import {CANCEL_PROCESS} from "../../services/documentService";

export default function CancelProcess({documentId, callback}) {

  const [show, setShow] = useState(false)
  const [progress, setProgress] = useState(false)

  const cancel = () => {
    setProgress(true)
    API.delete(`${CANCEL_PROCESS}/${documentId}`).then(() => {
      showNotification(
        "",
        "Processo cancelado!",
        NOTIFICATION_TYPE.success,
        NOTIFICATION_POSITION.topRight
      )
      setShow(false)
      callback()
    }).catch((error) => {
      handleError(error.response.data);
    })
      .finally(() => setProgress(false));
  }

  return (
    <>
      <div>
        <p className="description-sm">Gostaria de cancelar o processo de assinatura deste documento?</p>
        <button type="button"
                className="btn btn-block btn-link btn-sm"
                style={{color: 'var(--au-red)'}}
                onClick={() => setShow(true)}>
          <i className="fas fa-ban" /> Cancelar processo
        </button>
      </div>

      <Modal size="sm" show={show} centered>
        <Modal.Body>
          <p className="description-md">
            Você quer realmente <strong>cancelar este processo</strong> de assinatura?
          </p>

          <p className="description-md">
            Esta ação não poderá ser desfeita.
          </p>

          <div>
            <ButtonPrimaryBlock
              text="Continuar"
              progress={progress}
              action={() => cancel() }/>

            <ButtonLinkBlock
              text="Não cancelar o processo"
              progress={false}
              action={() => setShow(false) }/>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )

}