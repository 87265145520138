import Version from "./Version";

function Footer () {
  return (
    <>
      <aside className="control-sidebar control-sidebar-dark">
      </aside>

      <footer className="main-footer">
        Assina Uai - 2022
        <div className="float-right d-none d-sm-inline-block">
          <Version />
        </div>
      </footer>
    </>
  );
}

export default Footer;
