import React, {useEffect, useState} from "react";

import CheckMobile from "../../utils/checkMobile";
import OpenInMobile from "../../components/liveness/OpenInMobile";
import SinglePage from "../../components/template/SinglePage";
import history from "../../routers/History";
import {Card} from "react-bootstrap";

import "./css/identity.css"
import {checkIdentityVerification} from "../../utils/checkIdentityVerification";

export default function VerificacaoIdentidade() {

  const checkMobile = CheckMobile()

  const [option, setOption] = useState(null)
  const [identityAlreadyVerified, setIdentityAlreadyVerified] = useState(false)

  useEffect(() => {
    if (!identityAlreadyVerified) {
      checkIdentityVerification()
      setIdentityAlreadyVerified(true)
    }

    if (isPJ()) {
      history.push('/pix')
    }
  }, []);

  const isPJ = () => {
    if (localStorage.getItem('guard') !== "collaborator") {
      const user = JSON.parse(localStorage.getItem('user'));
      return user.type === "JURIDICAL_PERSON";
    }
    return false;
  }

  const renderSelfie = () => {
    return (
      <>
        <div style={{marginBottom: 15}}>
          <span className="description-md">Precisamos verificar sua identidade. É bem rápido, precisamos apenas de algumas fotos.</span>
        </div>

        <>
          {
            checkMobile ?
              <>
                <div>
                  <ul className="liveness-help-ul">
                    <li><i className="fas fa-camera"/> Fique em frente à câmera e centralize o seu rosto.</li>
                    <li><i className="far fa-lightbulb"/> Encontre um lugar com uma boa iluminação.</li>
                    <li><i className="fas fa-glasses"/> No momento da selfie, não use óculos, chapéus ou outros acessórios.</li>
                  </ul>

                  <button type="button" onClick={() => {history.push("/selfie")}}
                          className="btn btn-assinauai btn-block">
                    Abrir câmera
                  </button>
                </div>
              </>
              :
              <OpenInMobile />
          }
        </>
      </>
    )
  }

  const renderOptions = () => {
    return (
      <>
        <div style={{marginBottom: 15}}>
          <span className="description-md">O processo para verificar sua identidade é bem rápido! Você pode validar pelo <strong style={{color: "var(--au-orange)"}}>PIX</strong> ou <strong style={{color: "var(--au-orange)"}}>Análise Facial</strong>.</span>
        </div>

        <div>
          <p className="description-md">Selecione uma opção: <span className="au-red">*</span></p>

          <Card className="option-liveness" onClick={() => history.push('/pix')}>
            <Card.Body>
              <div>
                <p className="title-sm" style={{marginBottom: 0}}>PIX</p>
                <p className="description-sm" style={{marginBottom: 0}}>
                  Verificar minha identidade via PIX.
                </p>
              </div>
            </Card.Body>
          </Card>

          <Card className="option-liveness" onClick={() => setOption('selfie')}>
            <Card.Body>
              <p className="title-sm" style={{marginBottom: 0}}>Análise facial</p>
              <p className="description-sm" style={{marginBottom: 0}}>
                Verificar minha identidade via análise facial + foto do documento.
              </p>
            </Card.Body>
          </Card>
        </div>
      </>
    )
  }

  return (
    <SinglePage
      title="Verificação de identidade"
      showBackPress={option === "selfie"}
      action={() => setOption(null)}>
      <>
        {
          option === null ?
            renderOptions()
            :
            renderSelfie()
        }
      </>
    </SinglePage>
  )

}