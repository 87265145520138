import React from "react";

import history from "../../routers/History";
import "../../styles/login.css";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import SinglePage from "../../components/template/SinglePage";

export default function Username (props) {

  const validationSchema = Yup.object({
    primeiroNome: Yup.string()
      .trim()
      .required('O campo nome é obrigatório'),
    ultimoNome: Yup.string()
      .trim()
      .required('O campo sobrenome é obrigatório')
  });
  const initialValues = {
    primeiroNome: "",
    ultimoNome: ""
  };
  const renderError = (message) => <div style={{display: 'block'}} className="invalid-feedback">{message}</div>;

  return (
    <SinglePage>
      <div style={{marginBottom: 15}}>
        <p className="title-md">Nova conta</p>
        <span className="description-md">
            <strong>4º Passo:</strong> E aí, como você se chama?
          </span>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, {}) => {
          history.push('/criar-senha', {
            ...props.location.state,
            firstName: values.primeiroNome.trim(),
            lastName: values.ultimoNome.trim()
          });
        }}>
        {(formik) => (
          <Form>
            <div className="form-group">
              <label className="label">
                Primeiro nome: <span className="au-red">*</span>
              </label>
              <Field
                name="primeiroNome"
                type="text"
                className="form-control"
              />
              <ErrorMessage name="primeiroNome" render={renderError} />
            </div>

            <div className="form-group">
              <label className="label">
                Sobrenome: <span className="au-red">*</span>
              </label>
              <Field
                name="ultimoNome"
                type="text"
                className="form-control"
              />
              <ErrorMessage name="ultimoNome" render={renderError} />
            </div>

            <div className="botao-login">
              <button type="submit" disabled={!formik.isValid} className="btn btn-assinauai btn-block">
                Continuar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </SinglePage>
  )
}
