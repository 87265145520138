import React, {useEffect, useState} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  SubTitle,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import API from "../../services/api";
import {handleError} from "../../utils/handleError";
import {CONSUME} from "../../services/payment-service";
import Progress from "../template/Progress";
import {parseDateToBR} from "../../utils/dataUtils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  SubTitle,
  Tooltip,
  Legend
);

export default function RegisteredUse() {

  const [progress, setProgress] = useState(false)
  const [graph, setGraph] = useState(null)
  const [options, setOptions] = useState(null)

  useEffect(() => {
    getValues()
  }, [])

  const getValues = () => {
    setProgress(true)
    API.get(CONSUME)
      .then((response) => {
        const result = response.data.data
        if (!(result instanceof Array)) {
          createGraph(result)
        }
      })
      .catch((error) => {
        handleError(error.response.data)
      })
      .finally(() => setProgress(false))
  }

  const createGraph = (values) => {
    setGraph({
      labels: values.graph.labels.map((label) => parseDateToBR(label) ),
      datasets: [
        {
          label: 'Documentos',
          data: values.graph.documents,
          borderColor: '#7986cb',
          backgroundColor: '#939ed5',
        },
        {
          label: 'Liveness',
          data: values.graph.liveness,
          borderColor: '#aed581',
          backgroundColor: '#bedd9a',
        },
      ],
    })

    setOptions({
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Consumo registrado no período',
        },
        subtitle: {
          display: true,
          text: `${parseDateToBR(values.period.start)} - ${parseDateToBR(values.period.end)}`
        }
      }
    })
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <p className="description-md text-muted">Consumo no período</p>
        {
          progress ? <Progress /> : null
        }

        {
          graph && options ?
            <Line options={options} height={"100%"} data={graph} />
            :
            <div>
              <p className="description-sm">
                <i className="fas fa-chart-line"/> Sem dados de consumo.
              </p>
            </div>
        }
      </div>
    </div>
  )

}