import API from "../../services/api";
import {useEffect, useState} from "react";
import ListaNotificacoes from "./ListaNotificacoes";
import {diferencaEmSegundos, segundosParaDhms} from "../../utils/dataUtils";
import history from "../../routers/History";
import {MARK_AS_READ, NOTIFICATIONS} from "../../services/notificationService";
import {handleError} from "../../utils/handleError";
import {getAnalytics, logEvent} from "firebase/analytics";

function Notifications (props) {

  const [notifications, setNotifications] = useState([])
  const [showList, setShowList] = useState(false)
  const analytics = getAnalytics()

  useEffect(() => {
    getNotifications();
  }, [props]);

  const getNotifications = () => {
    API.get(NOTIFICATIONS)
      .then((response) => {
        const result = response.data.data;
        setNotifications(result.notifications);
      }).catch((error) => {
      handleError(error.response.data);
    });
  }

  const abrirDocumento = (notificacaoId, docId, index) => {
    setShowList(false);
    markAsRead(notificacaoId, false, index)
    history.push(`/documentos/${docId}`);
  }

  const markAsRead = async (id, marcarTodas, index) => {
    return await API.put(MARK_AS_READ, {
      id: id,
      markAllAsRead: marcarTodas
    })
      .then(() => {
        if (!marcarTodas) {
          let novaListaNotificacoes = [...notifications];
          novaListaNotificacoes.splice(index, 1);
          setNotifications(novaListaNotificacoes);
        } else {
          setNotifications([]);
        }
      })
      .catch((error) => {
        handleError(error.response.data);
      });
  }

  return (
    <>
      <li className="nav-item dropdown d-none d-sm-block d-md-block d-lg-block">
        <a className="nav-link" data-toggle="dropdown" href="#">
          <i className="far fa-bell"/>
          <span className="badge badge-warning navbar-badge">{notifications.length}</span>
        </a>
        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          {
            notifications.length === 0 ?
              <span className="dropdown-item dropdown-header">Não há notificações</span>
              :
              <>
                <span className="dropdown-item dropdown-header">
                  {notifications.length} {notifications.length === 1 ? 'notificação' : 'notificações'}</span>
                <div className="dropdown-divider"/>
              </>
          }

          {
            notifications.slice(0, 3).map((notification, index) => (
              <div key={index}>
                <a onClick={() => {
                  if (notification.data.docId !== undefined) {
                    abrirDocumento(notification.id, notification.data.docId, index);
                  }
                }}
                   style={{
                     borderLeft: notification.data.aviso.includes("rejeitou") ? '3px solid #dc3545' : '',
                     cursor: notification.data.docId !== undefined ? 'pointer' : ''
                   }}
                   className="dropdown-item notification" >
                  <div>
                    <p>
                      <i style={{color: notification.data.aviso.includes("rejeitou") ? '#dc3545' : ''}}
                         className="fas fa-file-signature"/> {notification.data.aviso}
                    </p>
                    <p style={{textAlign: 'right'}} className="text-muted text-sm">
                      {segundosParaDhms(diferencaEmSegundos(notification.data))}
                    </p>
                  </div>
                </a>
                <div className="dropdown-divider"/>
              </div>
            ))
          }
          {
            notifications.length > 0 ?
              <>
                <div className="dropdown-divider"/>
                <a onClick={() => {
                  // region show_notifications
                  logEvent(analytics, 'show_notifications')
                  // endregion
                  setShowList(true);
                }} style={{textAlign: 'center', cursor: 'pointer'}} className="dropdown-item dropdown-footer">
                  Todas as notificações
                </a>
              </>
              :
              null
          }
        </div>
      </li>

      <li className="nav-item d-block d-sm-none" onClick={() => {
        // region show_notifications
        logEvent(analytics, 'show_notifications')
        // endregion
        setShowList(true)
      }}>
        <a className="nav-link">
          <i className="far fa-bell"/>
          <span className="badge badge-warning navbar-badge">{notifications.length}</span>
        </a>
      </li>

      <ListaNotificacoes
        notificacoes={notifications}
        show={showList}
        ocultarLista={() => setShowList(false)}
        abrirDocumento={(notificacaoId, docId, index) => {abrirDocumento(notificacaoId, docId, index)}}
        markAsRead={async (notificacaoId, marcarTodas, index) => { await markAsRead(notificacaoId, marcarTodas, index)}}
      />
    </>
  );

}

export default Notifications;
