import ApolloClient from "apollo-boost";
import {URL_BASE} from "./endpoint";

const apolloClient = new ApolloClient({
  uri: `${URL_BASE}/graphql`,
  request: (operation) => {
    const token = localStorage.getItem("token");
    operation.setContext({
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
  }
});

export default apolloClient;
