import history from "../../routers/History";
import React, {useEffect, useState} from "react";
import API from "../../services/api";
import {MY_CERTIFICATE} from "../../services/certificateService";
import {handleError} from "../../utils/handleError";
import {parseDateToBR} from "../../utils/dataUtils";

export default function SenhaAssinatura() {

  const [loading, setLoading] = useState(true);
  const [certificate, setCertificate] = useState(null);

  useEffect(() => {
    getMyCertificate()
  }, [])

  const getMyCertificate = () => {
    API.get(MY_CERTIFICATE)
      .then((response) => {
        const result = response.data.data;
        setCertificate(result);
      }).catch((error) => {
      const result = error.response.data;
      if (
        result.code === undefined ||
        result.code === -1 ||
        result.code !== "E006") {
        handleError(error.response.data);
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <>
      {
        loading ?
          <span><i className="fa fa-spinner fa-spin"/> Carregando </span>
          :
          <>
            {
              certificate == null ?
                <div style={{textAlign: 'center'}}>
                  <h6>Você ainda não criou a sua senha de assinatura</h6>
                  <p style={{fontSize: '12px', margin: 0}}>Clique no botão abaixo para criar a sua senha de assinatura!</p>
                  <p style={{fontSize: '12px'}}>Será com esta senha que você irá assinar os seus documentos recebidos.</p>
                  <button className="btn btn-assinauai" onClick={() => {
                    const lastLocation = window.location.pathname;
                    history.push(`/criar-senha-assinatura?redirectTo=${lastLocation}`);
                  }}>
                    Criar senha de assinatura
                  </button>
                </div>
                :
                <table className="table table-borderless">
                  <thead>
                  <tr>
                    <th scope="col"/>
                    <th scope="col">Criada em</th>
                    <th scope="col">Expira em</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th style={{fontWeight: 100}}>Senha de assinatura</th>
                    <th style={{fontWeight: 100}}>{parseDateToBR(certificate.createdAt)}</th>
                    <th style={{fontWeight: 100}}>{parseDateToBR(certificate.deadline)}</th>
                  </tr>
                  </tbody>
                </table>
            }
          </>
      }
    </>
  )

}