import React, {useEffect, useState} from "react";
import {Dropdown} from "react-bootstrap";

import "./filter.css"
import DatePicker from "react-datepicker";
import StatusInfo from "./StatusInfo";
import CheckMobile from "../../../utils/checkMobile";
import Switch from "react-switch";

export default function DocumentsSentFilter({search}) {

  const [showFilter, setShowFilter] = useState(false)
  const [documentName, setDocumentName] = useState("")
  const [status, setStatus] = useState({
    pending: false,
    notSigned: false,
    rejected: false,
    concluded: false,
    canceled: false,
  })
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [showArchive, setShowArchive] = useState(false)
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }
  const checkMobile = CheckMobile()

  useEffect(() => {
    fetchDocuments()
  }, [showArchive])

  const fetchDocuments = () => {
    search({
      documentName,
      status: getStatusFilter(),
      startDate,
      endDate,
      showArchive
    })
  }

  const handleArchive = () => {
    setShowArchive(!showArchive)
  }

  const getStatusFilter = () => {
    let filter = "";
    if (status.pending) {
      filter += "&status[]=pending"
    }

    if (status.rejected) {
      filter += "&status[]=rejected"
    }

    if (status.notSigned) {
      filter += "&status[]=not_signed"
    }

    if (status.concluded) {
      filter += "&status[]=concluded"
    }

    if (status.canceled) {
      filter += "&status[]=canceled"
    }

    return filter
  }

  return (
    <>
      {
        checkMobile ?
          <div style={{marginBottom: '1rem'}}>
            <button type="button"
                    className="btn btn-link btn-sm btn-block"
                    onClick={() => setShowFilter(!showFilter)}>
              <i className="fas fa-filter"/> { !showFilter ? "Exibir filtro" : "Ocultar filtro"}
            </button>
          </div>
          :
          null
      }

      {
        showFilter || !checkMobile ?
          <div style={{marginBottom: '1rem'}}>
            <div className="row">
              <form className="form-inline" onSubmit={e => { e.preventDefault() }}>
                <div className="form-group mb-3 input-full-sm">
                  <input placeholder="Nome do documento"
                         autoComplete="off" className="form-control form-control-sm"
                         type="search"
                         value={documentName}
                         onChange={(e) => setDocumentName(e.target.value)}
                         onKeyDown={(e) => {
                           if (e.key === 'Enter') fetchDocuments()
                         }} />
                </div>

                <div className="form-group mb-3 input-full-sm">
                  <DatePicker
                    className="form-control form-control-sm"
                    placeholderText="Prazo"
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    isClearable={true}
                    locale="pt-BR"
                    dateFormat="P"
                    selectsRange
                  />
                </div>

                <div id="status-filter" className="mb-3 d-flex align-items-center"
                     style={{marginRight: 8}}>
                  <div className="form-group input-full-sm" style={{marginBottom: 0}}>
                    <Dropdown autoClose="outside">
                      <Dropdown.Toggle variant="default" id="dropdown-status">
                        Filtrar por status
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setStatus({...status, pending: !status.pending})}
                                       className="d-flex align-items-center">
                          <div className="d-flex align-items-center" style={{marginRight: 8}}>
                            <input readOnly={true} type="checkbox" className="checkbox-filter" checked={status.pending} />
                          </div>
                          <div>
                            <i className="fas fa-flag" style={{color: "var(--au-yellow)"}}/> Pendente
                          </div>
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => setStatus({...status, rejected: !status.rejected})}
                                       className="d-flex align-items-center">
                          <div className="d-flex align-items-center" style={{marginRight: 8}}>
                            <input readOnly={true} type="checkbox" className="checkbox-filter" checked={status.rejected} />
                          </div>
                          <div>
                            <i className="fas fa-flag" style={{color: "var(--au-red)"}}/> Rejeitado
                          </div>
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => setStatus({...status, concluded: !status.concluded})}
                                       className="d-flex align-items-center">
                          <div className="d-flex align-items-center" style={{marginRight: 8}}>
                            <input readOnly={true} type="checkbox" className="checkbox-filter" checked={status.concluded} />
                          </div>
                          <div>
                            <i className="fas fa-flag" style={{color: "var(--au-green)"}}/> Concluído
                          </div>
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => setStatus({...status, notSigned: !status.notSigned})}
                                       className="d-flex align-items-center">
                          <div className="d-flex align-items-center" style={{marginRight: 8}}>
                            <input readOnly={true} type="checkbox" className="checkbox-filter" checked={status.notSigned} />
                          </div>
                          <div>
                            <i className="fas fa-flag" style={{color: "var(--au-grey)"}}/> Não assinado
                          </div>
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => setStatus({...status, canceled: !status.canceled})}
                                       className="d-flex align-items-center">
                          <div className="d-flex align-items-center" style={{marginRight: 8}}>
                            <input readOnly={true} type="checkbox" className="checkbox-filter" checked={status.canceled} />
                          </div>
                          <div>
                            <i className="fas fa-flag" /> Cancelado
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <StatusInfo />
                </div>

                <button
                  type="button"
                  onClick={() => fetchDocuments()}
                  className="btn btn-sm btn-assinauai mb-3 btn-sm-block">
                  <i className="fa fa-filter"/> Buscar
                </button>
              </form>
            </div>

            <div className="row">
              <div className="d-flex justify-content-center justify-content-md-start justify-content-lg-start">
                <label className="d-flex justify-content-center align-items-center">
                  <Switch
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={handleArchive}
                    height={14}
                    width={28}
                    onColor="#F1581B"
                    activeBoxShadow="#F1581B"
                    offColor="#6c757d"
                    checked={showArchive} />
                  <span className="checkbox-au-input">
                    Exibir documentos arquivados
                  </span>
                </label>
              </div>
            </div>
          </div>
          :
          null
      }
    </>
  )

}