import { gql } from "@apollo/client";

const PASTAS_USUARIO = gql`
  {
    usuario {
      pastas {
        id
        nome
        qtdDocs
        qtdDirs
      }
    }
  }
`;

const PASTAS_CONTATO = gql`
  query BuscarContato($contatoId: ID) {
    usuario {
      contatos(id: $contatoId) {
        pastas {
          id
          nome
          qtdDocs
          qtdDirs
        }
      }
    }
  }
`;

const BUSCAR_PASTA = gql`
  query BuscarPasta($id: ID!, $docId: ID) {
    pasta(id: $id) {
      id
      nome
      qtdDocs
      qtdDirs
      possuiDocumento(id: $docId) {
        docId
      }
      diretorios {
        id
        nome
        qtdDocs
        qtdDirs
      }
      documentos {
        id
        docId
        dono
        nome
        dataCriacao
        prazo
        docAberto
        qtdAssinantes
        qtdAssinaram
        qtdRejeitaram
      }
    }
  }
`;

const CRIAR_PASTA = gql`
  mutation CriarPasta($nome: String!, $parentId: Int, $contatoId: Int) {
    criarPasta(nome: $nome, parentId: $parentId, contatoId: $contatoId) {
      id
      nome
    }
  }
`;

const VINCULAR_DOCUMENTO = gql`
  mutation VincularDoc($pastaId: ID!, $docId: ID!, $add: Boolean!) {
    vincularDocPasta(pastaId: $pastaId, docId: $docId, add: $add) {
      qtdDocs
      possuiDocumento(id: $docId) {
        docId
      }
    }
  }
`;

const querys = {
  CRIAR_PASTA,
  BUSCAR_PASTA,
  PASTAS_USUARIO,
  PASTAS_CONTATO,
  VINCULAR_DOCUMENTO
};

export default querys;

