export const newDocumentTutorial = [
  {
    selector: '.process-name',
    content: 'Defina um nome para o documento que será assinado.',
  },
  {
    selector: '.deadline',
    content: 'Existe um prazo para assinar este documento? Se sim, você pode informar um prazo para a assinatura deste documento.',
  },
  {
    selector: '.signatory',
    content: 'Agora é só adicionar os assinantes do documento. Você pode selecionar um dos seus contatos ou apenas incluir o e-mail do assinante. Você pode incluir mais de 1 assinante.',
  },
];