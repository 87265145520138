import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import CreditCard from "./CreditCard";
import ButtonPrimary from "../template/buttons/ButtonPrimary";
import API from "../../services/api";
import {CREATE_SUBSCRIPTION} from "../../services/payment-service";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";
import {handleError} from "../../utils/handleError";
import ErrorAlert from "../utils/error-alert";
import pad from "../../utils/credit-card";

export default function NewSubscription({plan, onHide}) {

  const [progress, setProgress] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [price, setPrice] = useState("")
  const [error, setError] = useState(null)

  const renderChoosePaymentMethod = () => {
    return (
      <CreditCard showCheckbox={true} setPaymentMethod={setPaymentMethod} />
    )
  }

  const renderBrand = (brand) => {
    switch (brand) {
      case "visa":
        return <i className="fab fa-cc-visa"/>
      case "mastercard":
        return <i className="fab fa-cc-mastercard"/>
      default:
        return <i className="far fa-credit-card"/>
    }
  }

  const createSubscription = () => {
    setError(null)

    if (price === "" && !plan.onDemand) {
      setError({
        title: " ",
        message: "Por favor, selecione se o pagamento será mensal ou anual"
      })
      return;
    }

    setProgress(true)
    API.post(CREATE_SUBSCRIPTION, {
      plan: plan.id,
      price: plan.onDemand ? null : price,
      creditCard: plan.onDemand ? null : paymentMethod.id
    })
      .then(() => {
        showNotification(
          "",
          "Assinatura atualizada",
          NOTIFICATION_TYPE.success,
          NOTIFICATION_POSITION.bottomLeft
        )
        onHide()
      }).catch((error) => {
      handleError(error.response.data)
    })
      .finally(() => setProgress(false))
  }

  const convertAmount = (unitAmount) => {
    return unitAmount.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
  }

  const convertInterval = (interval) => {
    switch (interval) {
      case 'year':
        return 'ano'
      default:
        return 'mês'
    }
  }

  return (
    <Modal show={plan} onHide={() => {
      setError(null)
      onHide()
    }} size="md" centered>
      <Modal.Body>
        {
          plan ?
            <>
              <h4 style={{marginBottom: '1rem'}} className="text-muted">Contratar plano</h4>

              {
                !paymentMethod && !plan.onDemand ?
                  renderChoosePaymentMethod()
                  :
                  <div>
                    <p className="description-md text-muted">
                      Você está iniciando a contratação do plano: <span style={{color: 'var(--au-orange)'}}>{plan.name}</span>
                    </p>

                    {
                      !plan.onDemand ?
                        <>
                          <table className="table table-borderless table-sm cards-table">
                            <thead>
                            <tr>
                              <th scope="col">Bandeira</th>
                              <th scope="col">Últimos 4 dígitos</th>
                              <th scope="col">Expiração</th>
                              <th scope="col"/>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <th style={{fontSize: 18}}>{renderBrand(paymentMethod.brand)}</th>
                              <th>{paymentMethod.last4}</th>
                              <th>{pad(paymentMethod.expMonth, 2)}/{paymentMethod.expYear}</th>
                              <th>
                                <button onClick={() => setPaymentMethod(null)} className="btn btn-link btn-sm">
                                  <i style={{color: 'var(--au-red)'}} className="fas fa-times"/>
                                </button>
                              </th>
                            </tr>
                            </tbody>
                          </table>

                          <div className="form-group">
                            <label>Preço:</label>
                            <select className="form-select form-select-sm"
                                    onChange={(e) => setPrice(e.target.value)}>
                              <option selected value="">Escolha um dos valores disponíves</option>
                              {
                                plan.prices.map(price =>
                                  <option value={price.id}>
                                    {convertAmount(price.unitAmount)}/{convertInterval(price.interval)}
                                  </option>
                                )
                              }
                            </select>
                            <span className="text-muted text-sm">O plano anual inclui 10% de desconto</span>
                          </div>

                          <ErrorAlert error={error} />
                        </>
                        :
                        null
                    }

                    <div className="d-flex justify-content-end">
                      <ButtonPrimary
                        text="Contratar plano"
                        progress={progress}
                        action={() => createSubscription()} />
                    </div>
                  </div>
              }
            </>
            :
            null
        }
      </Modal.Body>
    </Modal>
  )

}