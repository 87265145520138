import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import {Card} from "react-bootstrap";
import {CardBody} from "reactstrap";

ChartJS.register(ArcElement, Tooltip, Legend);


export default function VerificationMethodGraph({values}) {

  const data = {
    labels: ['PIX', 'NEXTCODE'],
    datasets: [
      {
        label: 'Verificação de identidade',
        data: [values.pix, values.nextcode],
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }


  return (
    <Card className="card-mobile">
      <CardBody>
        <Pie data={data} />
      </CardBody>
    </Card>
  )

}