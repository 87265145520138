import React, {useEffect, useState} from "react";

import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import ptBr from 'date-fns/locale/pt-Br';
import CheckMobile from "../../../utils/checkMobile";
import Switch from "react-switch";
import {getAnalytics, logEvent} from "firebase/analytics";
registerLocale('pt-BR', ptBr)

export default function Filter({search}) {

  const [showFilter, setShowFilter] = useState(false)
  const [documentName, setDocumentName] = useState("")
  const [category, setCategory] = useState("pending")
  const [sentBy, setSentBy] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [showArchive, setShowArchive] = useState(false)
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const checkMobile = CheckMobile()
  const analytics = getAnalytics();

  useEffect(() => {
    fetchDocuments()
  }, [category, showArchive])

  const fetchDocuments = () => {
    search({
      category,
      sentBy: sentBy,
      startDate,
      endDate,
      documentName,
      showArchive
    })
  }

  return (
    <>
      {
        checkMobile ?
          <>
            <div style={{marginBottom: '1rem'}}>
              <button type="button"
                      className="btn btn-link btn-sm btn-block"
                      onClick={() => setShowFilter(!showFilter)}>
                <i className="fas fa-filter"/> { !showFilter ? "Exibir filtro" : "Ocultar filtro"}
              </button>
            </div>

            <div style={{display: !showFilter ? 'block' : 'none'}}>
              <div className="row">
                <form className="form-inline">
                  <div className="form-group mb-3 input-full-sm">
                    <select className="form-select form-select-sm"
                            defaultValue={category}
                            onChange={(e) => {
                              const category = e.target.value;
                              // region document_category_change
                              logEvent(analytics, 'document_category_change', {
                                category: category
                              })
                              // endregion
                              setCategory(category)
                            }}>
                      <option value="pending">Documentos pendentes</option>
                      <option value="signed">Documentos assinados</option>
                      <option value="notSigned">Documentos não assinados</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </>
          :
          null
      }

      {
        showFilter || !checkMobile ?
          <div style={{marginBottom: '1rem'}}>
            <div className="row">
              <form className="form-inline">
                <div className="form-group mb-3 input-full-sm">
                  <select className="form-select form-select-sm"
                          defaultValue={category}
                          onChange={(e) => {
                            const category = e.target.value;
                            // region document_category_change
                            logEvent(analytics, 'document_category_change', {
                              category: category
                            });
                            // endregion
                            setCategory(category)
                          }}>
                    <option value="pending">Documentos pendentes</option>
                    <option value="signed">Documentos assinados</option>
                    <option value="notSigned">Documentos não assinados</option>
                  </select>
                </div>

                <div className="form-group mb-3 input-full-sm">
                  <input placeholder="Nome do documento"
                         autoComplete="off" className="form-control form-control-sm"
                         type="search"
                         value={documentName}
                         onChange={(e) => setDocumentName(e.target.value)}
                         onKeyDown={(e) => {
                           if (e.key === 'Enter') fetchDocuments()
                         }} />
                </div>

                <div className="form-group mb-3 input-full-sm">
                  <div className="input-group" style={{marginBottom: '0 !important'}}>
                    <input placeholder="Enviado por"
                           autoComplete="off" className="form-control form-control-sm"
                           type="search"
                           value={sentBy}
                           onChange={(e) => setSentBy(e.target.value)}
                           onKeyDown={(e) => {
                             if (e.key === 'Enter') fetchDocuments()
                           }} />
                  </div>
                </div>

                <div className="form-group mb-3 input-full-sm">
                  <DatePicker
                    className="form-control form-control-sm"
                    placeholderText="Prazo"
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    isClearable={true}
                    locale="pt-BR"
                    dateFormat="P"
                    selectsRange
                  />
                </div>

                <button
                  type="button"
                  onClick={() => fetchDocuments()}
                  className="btn btn-sm btn-assinauai mb-3 btn-sm-block">
                  <i className="fa fa-filter"/> Buscar
                </button>
              </form>
            </div>

            <div className="row">
              <div className="d-flex justify-content-center justify-content-md-start justify-content-lg-start">
                <label className="d-flex justify-content-center align-items-center">
                  <Switch
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => {
                      // region show_archived_documents
                      logEvent(analytics, 'show_archived_documents', {
                        category: category
                      });
                      // endregion
                      setShowArchive(!showArchive)
                    }}
                    height={14}
                    width={28}
                    onColor="#F1581B"
                    activeBoxShadow="#F1581B"
                    offColor="#6c757d"
                    checked={showArchive} />
                  <span className="checkbox-au-input">
                    Exibir documentos arquivados
                  </span>
                </label>
              </div>
            </div>
          </div>
          :
          null
      }
    </>
  )

}