import React, { createContext, useState } from 'react';

import useAuth from './hooks/useAuth';

const Context = createContext();

function AuthProvider({ children }) {
  const { authenticated, loading, handleLogin, handleLogout, isValidToken } = useAuth();
  const [secaoConfirmarEmail, setSecaoConfirmarEmail] = useState(true);
  const [secaoDadosUsuario, setSecaoDadosUsuario] = useState(false);
  const [planoGratuitoContratado, setContratacaoPlanoGratuito] = useState(false);
  const [pagamentoPendente, setPagamentoPendente] = useState(false);
  const [darkMode, setDarkMode] = useState(false)

  const setSettings = (newValue) => {
    const settings = JSON.parse(localStorage.getItem('settings'))
    localStorage.setItem('settings', JSON.stringify({
      ...settings,
      ...newValue
    }))
  }

  return (
    <Context.Provider value={{
      loading, authenticated, handleLogin, handleLogout, isValidToken,
      secaoConfirmarEmail, setSecaoConfirmarEmail,
      secaoDadosUsuario, setSecaoDadosUsuario,
      planoGratuitoContratado, setContratacaoPlanoGratuito,
      pagamentoPendente, setPagamentoPendente,
      darkMode, setDarkMode, setSettings
    }}>
      {children}
    </Context.Provider>
  );
}

export { Context, AuthProvider };