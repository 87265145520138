import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../utils/notifications";

const firebaseConfig = {
  apiKey: "AIzaSyAK82Vy_wJC8xv24Htltn8nvBeR4SVxT5U",
  authDomain: "assina-uai.firebaseapp.com",
  projectId: "assina-uai",
  storageBucket: "assina-uai.appspot.com",
  messagingSenderId: "888091439135",
  appId: "1:888091439135:web:5605ccfa882824aa89e52a",
  measurementId: "G-7GQD3M6W7C"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
  showNotification(
    payload.data.title,
    payload.data.message,
    NOTIFICATION_TYPE.info,
    NOTIFICATION_POSITION.topCenter
  )
});

export const getTokenFCM = () => {
  return getToken(messaging, {vapidKey: 'BGhPUoHPKLjGCYvWnFoAQmsQc0ppkvvxkwDgJcvAvwqeVai2GQIOR_kutcMiLPFXjDkDv_d9YzthQ_wOf-JjqeE'})
    .then((currentToken) => {
      return currentToken;
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      return null;
    })
}