import Documento from "../components/documentos/Documento";

function VisualizarDocumento () {

  return (
    <>
      <Documento />
    </>
  );

}

export default VisualizarDocumento;
