import React from "react";

export default function ButtonDefault({text, icon, progress, action, style}) {

  return (
    <button className={`btn btn-default ${style}`}
            disabled={progress}
            onClick={() => action()}>
      {
        progress ?
          <><i className="fa fa-spinner fa-spin"/></>
          :
          <><i className={icon} /> {text}</>
      }
    </button>
  )

}