import React from 'react';
import ReactDOM from 'react-dom';
import ReactNotification from 'react-notifications-component';

import { Router } from 'react-router-dom';
import { ApolloProvider } from "@apollo/client";

import { AuthProvider } from './routers/Context/AuthContext';
import apolloClient from "./services/apolloClient";
import Routes from './routers/Routes';
import history from './routers/History';
import "./services/firebase-service"
import "./utils/check-navigator"

import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/global.scss";
import "./styles/text.css";
import "./styles/colos.scss";
import "./styles/margin.css";
import "./styles/mobile.css";
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';

ReactDOM.render(
  <React.StrictMode>
    <ReactNotification />
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <Router history={history}>
          <Routes />
        </Router>
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
