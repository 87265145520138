import React from "react";

import CancelProcess from "./CancelProcess";

export default function DocumentOptions({document, updateDocument}) {

  return (
    <>
      {
        document.isOwner ?
          <div>
            <hr />
            <div className="d-flex align-items-center flex-column">
              <CancelProcess
                documentId={document.id}
                callback={() => updateDocument()} />
            </div>
          </div>
          :
          null
      }
    </>
  )

}