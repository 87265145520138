import React, {useEffect, useState} from "react";
import Progress from "../template/Progress";
import API from "../../services/api";
import {GET_VALUES} from "../../services/dashboard";
import {handleError} from "../../utils/handleError";
import {CardBody} from "reactstrap";
import {Card} from "react-bootstrap";
import VerificationMethodGraph from "./VerificationMethodGraph";

export default function Values() {

  const [progress, setProgress] = useState(false)
  const [values, setValues] = useState(null)

  useEffect(() => {
    getValues()
  }, [])

  const getValues = () => {
    setProgress(true)
    API.get(GET_VALUES)
      .then((response) => {
        const result = response.data.data
        setValues(result)
        console.log(result)
      })
      .catch((error) => {
        handleError(error.response.data)
      })
      .finally(() => setProgress(false))
  }

  return (
    <>
      {
        !progress ?
          <>
            {
              values != null ?
                <div className="row">
                  <div className="col-md-12">
                    <Card className="card-mobile">
                      <CardBody>
                        <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                          <p className="text-gray-dark text-xl">
                            <i className="far fa-user"/>
                          </p>
                          <p className="d-flex flex-column text-right">
                            <span className="font-weight-bold">{values.numberUsers}</span>
                            <span className="text-muted">Nº DE USUÁRIOS</span>
                          </p>
                        </div>

                        <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                          <p className="text-gray-dark text-xl">
                            <i className="far fa-file-pdf"/>
                          </p>
                          <p className="d-flex flex-column text-right">
                            <span className="font-weight-bold">{values.numberProcesses}</span>
                            <span className="text-muted">Nº DE PROCESSOS</span>
                          </p>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-0">
                          <p className="text-gray-dark text-xl">
                            <i className="fas fa-file-contract"/>
                          </p>
                          <p className="d-flex flex-column text-right">
                            <span className="font-weight-bold">{values.numberSignatures}</span>
                            <span className="text-muted">Nº PROC. CONCLUÍDOS</span>
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  </div>

                  <div className="col-md-12">
                    <VerificationMethodGraph values={values.validationsNumber} />
                  </div>
                </div>
                :
                null
            }
          </>
          :
          <Progress />
      }
    </>
  )

}