import React from "react";

import DocumentsSentPage from "../../pages/documents/DocumentsSentPage";
import EditContact from "./EditContact";
import ProfilePicture from "../usuario/ProfilePicture";

export default function ContactDetails ({contact, updateContact}) {

  return (
    <div className="row">
      <div className="col-md-6 col-lg-4">
        <div className="card d-flex flex-fill">
          <div className="card-body pt-0">
            <div className="row">
              <div className="col-9">
                <h2 className="lead"><b>{contact.name != null ? contact.name : "Nome não cadastrado"}</b></h2>
                <p className="text-muted text-sm"><b>Documentos: </b><br /> 0 enviado(s) </p>
                <ul className="ml-4 mb-0 fa-ul text-muted">
                  <li className="small">
                    <span className="fa-li"><i className="fa fa-envelope"/></span> E-mail: {contact.email}
                  </li>
                  <li className="small">
                    {
                      contact.verified ?
                        <>
                          <span className="fa-li"><i className="far fa-id-card verified"/></span> Cadastro verificado
                        </>
                        :
                        <>
                          <span className="fa-li"><i className="far fa-id-card not-verified"/></span> Cadastro não verificado
                        </>
                    }
                  </li>
                </ul>
              </div>
              <div className="col-3 text-center">
                <ProfilePicture isProfile={true} contactId={contact.id} />
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="text-right">
              <EditContact contact={contact} updateContact={(contact) => updateContact(contact)} />
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-8">
        <DocumentsSentPage contactID={contact.userID} />
      </div>
    </div>
  )
}
