import apolloClient from "../services/apolloClient";
import queries from "../querys/usuario/DadosUsuario";
import {popBackStack} from "../routers/PopBackStack";

export function checkIdentityVerification() {
  apolloClient.query({
    query: queries.VERIFIED_IDENTITY,
    variables: {},
    fetchPolicy: 'no-cache'
  })
    .then(result => {
      if (result.data.usuario.cadVerificado) {
        popBackStack();
      }
    })
    .catch(() => {})
    .finally(() => {});
}