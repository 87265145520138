import React from "react";

export default function ButtonPrimaryBlock({text, progress, action}) {

  return (
    <button style={{marginTop: 8, marginBottom: 8}}
            className="btn btn-assinauai btn-block"
            disabled={progress}
            onClick={() => action()}>
      {
        progress ?
          <><i className="fa fa-spinner fa-spin"/></>
          :
          <>{text}</>
      }
    </button>
  )

}