import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import BreadcrumbPastas from "../components/pastas/BreadcrumbPastas";
import Pasta from "../components/pastas/Pasta";

import apolloClient from "../services/apolloClient";
import querys  from "../querys/pastas/Pastas";
import {store} from "react-notifications-component";
import DocumentosPasta from "../components/pastas/DocumentosPasta";
import locais from "../utils/localPasta";
import {appendingParameterToURL} from "../utils/urlUtils";

const queryString = require('query-string');

function Pastas(props) {

  const [diretorios, setDiretorios] = useState([{
    id: 0,
    nome: ''
  }]);
  const [mudarDir, setMudarDir] = useState(false);
  const [dirAtual, setDirAtual] = useState({
    id: null,
    diretorios: [],
    documentos: []
  });
  const [pastaSelecionada, selecionarPasta] = useState(null);
  const [criandoPasta, setCriandoPasta] = useState(false);
  const [buscandoPasta, setBuscandoPasta] = useState(false);

  useEffect (async () => {
    if (dirAtual.id == null) {
      const {pasta} = queryString.parseUrl(window.location.href).query;
      if (pasta !== undefined) {
        try {
          await setDiretorios(JSON.parse(JSON.parse(pasta).diretorios));
          abrirPasta(JSON.parse(JSON.parse(pasta).diretorioAberto), false, "Restart");
        } catch (e) {}
      } else {
        setDirAtual({
          ...dirAtual,
          id: 0
        });
        abrirPasta({...dirAtual, id: 0}, false, "Start");
      }
      setHome();
    }

    if (mudarDir) {
      selecionarPasta(null);
      setMudarDir(false);
      abrirPasta(diretorios[diretorios.length - 1], false, "MudouDir");
    }
  }, [props, mudarDir]);

  const setHome = () => {
    const local = props.local !== undefined ? props.local : 0;
    let dirsTemp = diretorios;
    switch (local) {
      case locais.PASTAS_USUARIO:
        dirsTemp[0].nome = 'Minhas pastas';
        break;
      case locais.PASTAS_CONTATO:
        dirsTemp[0].nome = props.nomeContato;
        break;
      case locais.PASTAS_GRUPO:
        dirsTemp[0].nome = 'Pastas do grupo';
        break;
    }
  }

  const addPasta = async () => {
    if (props.adicionandoPasta) {
      props.adicionandoPasta(true);
    }

    const {value: nomePasta} = await Swal.fire({
      title: 'Nova pasta',
      inputLabel: 'Nome da pasta',
      input: 'text',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#F1581B',
      confirmButtonText: 'Adicionar',
    });

    if (nomePasta) {
      setCriandoPasta(true);

      apolloClient.mutate({
        mutation: querys.CRIAR_PASTA,
        variables: {
          nome: nomePasta,
          contatoId: props.contatoId === undefined ? null : parseInt(props.contatoId, 10),
          parentId: dirAtual.id !== 0 ? parseInt(dirAtual.id, 10) : null
        },
        fetchPolicy: 'no-cache'
      })
        .then(result => {
          abrirPasta(dirAtual, false, "AposMutation");
          store.addNotification({
            title: "Pasta criada",
            message: "Pasta criada!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        })
        .catch(() => {
          store.addNotification({
            title: "Ops!",
            message: "Ocorreu um problema ao criar a pasta, tente novamente mais tarde!",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        })
        .finally(() => {
          setCriandoPasta(false);
          if (props.adicionandoPasta) {
            props.adicionandoPasta(false);
          }
        });
    }
  }

  const abrirPasta = (pasta, addDir, origem) => {
    // A pasta passada como parâmetro pode ser o diretório principal
    let dirAberto = parseInt(pasta.id, 10) === 0 ? pasta : dirAtual;
    if (addDir || origem === 'Restart') {
      dirAberto = pasta;
      atualizarDirAtual(pasta);
    }
    setBuscandoPasta(true);
    const queryBusca = getQuery(dirAberto);

    apolloClient.query({
      query: queryBusca,
      variables: {
        id: pasta.id,
        contatoId: props.contatoId
      },
      fetchPolicy: 'no-cache'
    })
      .then(result => {
        let dadosPasta = {}
        if (addDir) {
          let diretoriosTemp = diretorios.slice();
          diretoriosTemp.push(pasta);
          setDiretorios(diretoriosTemp);
          dadosPasta = {
            ...dadosPasta,
            diretorios: JSON.stringify(diretoriosTemp)
          }
        }

        let novoDir = null;
        if (dirAberto.id == 0 || dirAberto.id == null) {
          // Diretório principal
          novoDir = {
            id: 0,
            diretorios: props.contatoId === undefined ? result.data.usuario.pastas : result.data.usuario.contatos[0].pastas,
            documentos: []
          }
        } else {
          novoDir = {
            id: result.data.pasta.id,
            nome: result.data.pasta.nome,
            diretorios: result.data.pasta.diretorios,
            documentos: result.data.pasta.documentos
          };
        }
        setDirAtual(novoDir);
        dadosPasta = {
          ...dadosPasta,
          diretorioAberto: JSON.stringify(novoDir)
        }
        appendingParameterToURL("pasta", JSON.stringify(dadosPasta));
      })
      .finally(() => setBuscandoPasta(false));
  }

  const getQuery = (dirAberto) => {
    const local = props.local !== undefined ? props.local : 0;
    if (dirAberto.id === 0 || dirAberto.id == null) {
      switch (local) {
        case locais.PASTAS_USUARIO:
          return querys.PASTAS_USUARIO;
        case locais.PASTAS_CONTATO:
          return querys.PASTAS_CONTATO;
      }
    }
    return querys.BUSCAR_PASTA;
  }

  const atualizarDirAtual = (pasta) => {
    setDirAtual({
      ...dirAtual,
      id: pasta.id,
      diretorios: [],
      documentos: []
    });
  }

  const reabrirDiretorio = (dir, i) => {
    let dirTemp = diretorios.slice(0, i+1);
    setDiretorios(dirTemp);
    setMudarDir(true);
  }

  return (
    <div className={!props.exibirEscolhaPasta ? "card card-primary" : ""}>
      <div className={!props.exibirEscolhaPasta ? "card-body" : ""}>
        <div className="row">
          <div className="pastas-header">
            <div className="pastas-tree">
              <BreadcrumbPastas
                diretorios={diretorios}
                reabrirDiretorio={reabrirDiretorio}
                mudarDirAtual={setMudarDir} />
            </div>

            <div className="add-pasta">
              <button onClick={() => addPasta()} className="btn btn-assinauai">
                {
                  criandoPasta ?
                    <i className="fas fa-spinner fa-spin"/>
                    :
                    <i className="fa fa-plus"></i>
                } Nova pasta
              </button>
            </div>
          </div>
        </div>

        {
          !buscandoPasta ?
            <div className="container-fluid">
              <div onClick={() => selecionarPasta(null)} style={{marginTop: '1rem'}} className="row">
                {
                  dirAtual.diretorios.map((pasta, i) =>
                    <div key={i} className="col">
                      <Pasta
                        pasta={pasta}
                        pastaSelecionada={pastaSelecionada}
                        selecionarPasta={selecionarPasta}
                        abrirPasta={abrirPasta} />
                    </div>
                  )
                }
              </div>

              {
                dirAtual.documentos.length !== 0 && (props.mostrarDocumentos !== undefined && props.mostrarDocumentos) ?
                  <div className="row">
                    <div className="col-md-12">
                      <DocumentosPasta lista={dirAtual.documentos} />
                    </div>
                  </div>
                  :
                  null
              }

              {
                pastaSelecionada && props.exibirEscolhaPasta ?
                  <div className="d-flex justify-content-center">
                    <button onClick={() => props.selecionarPasta(pastaSelecionada)} style={{textDecoration: 'none'}} className="btn btn-link">
                      <i className="far fa-hand-pointer"></i> Escolher esta pasta: {pastaSelecionada.nome}
                    </button>
                  </div>
                  :
                  null
              }
            </div>
            :
            <div style={{margin: '1rem'}} className="col-sm-12 d-flex justify-content-center">
              <i style={{color: '#F1581B'}} className="fas fa-2x fa-spinner fa-spin"/>
            </div>
        }
      </div>
    </div>
  );

}

export default Pastas;
