import { useState, useEffect } from "react";

import { Tooltip } from 'reactstrap';

function RegrasProcesso (props) {

  const [dataMinima, setDataMinima] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect (() => {
    let data = new Date(),
      ano = data.getFullYear(),
      mes = data.getMonth() + 1 <= 9 ? `0${data.getMonth() + 1}` : `${data.getMonth() + 1}`,
      dia = data.getDate() <= 9 ? `0${data.getDate()}` : `${data.getDate()}`;

    setDataMinima(`${ano}-${mes}-${dia}`);
  }, [props]);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div>
      <div className="form-group deadline">
        <label htmlFor="input-dataSelecionada">
          <i className="fas fa-user-clock"/> Prazo:
        </label>
        <input id="input-dataSelecionada"
          name="dataSelecionada"
          type="date"
          value={props.prazoAss}
          min={dataMinima}
          onChange={(e) => props.handlePrazoAss(e.target.value)}
          style={{marginBottom: "0"}}
          aria-describedby="prazoHelpBlock"
          className="form-control form-group"
        />
        <small id="deadlineHelp" className="form-text text-muted">O prazo para assinatura é opcional.</small>
        <Tooltip placement="right" isOpen={tooltipOpen} target="input-dataSelecionada" toggle={toggle}>
          Defina um prazo para os signatários assinarem o documento.
        </Tooltip>
      </div>
    </div>
  );
}

export default RegrasProcesso;