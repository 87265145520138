import {Card, ProgressBar} from "react-bootstrap";
import {CardBody} from "reactstrap";
import React, {useEffect, useState} from "react";
import {handleError} from "../../utils/handleError";
import Progress from "../../components/template/Progress";
import {CONSUMPTION, SEND_TOKEN} from "../../services/apiService";
import API from "../../services/api";
import {parseDateToBR} from "../../utils/dataUtils";
import ButtonPrimary from "../../components/template/buttons/ButtonPrimary";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";
import WebhookNotifications from "../../components/api/WebhookNotifications";

export default function APIInfo() {

  const [progress, setProgress] = useState(false)
  const [progressToken, setProgressToken] = useState(false)
  const [values, setValues] = useState(null)

  useEffect(() => {
    getConsumption()
  }, [])

  const getConsumption = () => {
    setProgress(true)
    setValues(null)

    API.get(CONSUMPTION)
      .then((response) => {
        const result = response.data.data
        if (!(result instanceof Array)) {
          setValues(result)
        }
      })
      .catch((error) => {
        console.log(error)
        handleError(error.response.data)
      })
      .finally(() => setProgress(false))
  }

  const sendToken = () => {
    setProgressToken(true)

    API.post(SEND_TOKEN)
      .then(() => {
        showNotification(
          "",
          "O token da API foi enviado para o seu e-mail",
          NOTIFICATION_TYPE.info,
          NOTIFICATION_POSITION.bottomLeft
        )
      })
      .catch((error) => {
        handleError(error.response.data)
      })
      .finally(() => setProgressToken(false))
  }

  const getTotal = () => {
    let total = 0
    if (values != null) {
      values.consumption.forEach(value => {
        total += value.total
      })
    }
    console.log(total)
    return total
  }

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <Card className="card-mobile">
            <CardBody>
              <p className="description-md">Uso da API</p>
              {
                progress ? <Progress /> : null
              }

              {
                values != null ?
                  <>
                    {
                      values.consumption.map((data) =>
                        <div style={{marginBottom: 12}}>
                          <div className="d-flex justify-content-between">
                            <p className="m-0 text-muted">{data.endpoint}</p>
                            <p className="m-0 text-muted">{data.total}</p>
                          </div>
                          <ProgressBar variant="warning" now={(data.total / getTotal()) * 100} />
                        </div>
                      )
                    }

                    <div className="d-flex justify-content-between" style={{fontSize: 12, marginTop: '1.5rem'}}>
                      <p className="m-0 text-muted">
                        {parseDateToBR(values.period.start)} - {parseDateToBR(values.period.end)}
                      </p>
                      <p className="m-0 text-muted">
                        <strong>{getTotal()}</strong>
                      </p>
                    </div>
                  </>
                  :
                  <div>
                    <p className="description-sm">
                      <i className="fas fa-chart-line"/> Sem dados de consumo.
                    </p>
                  </div>
              }
            </CardBody>
          </Card>
        </div>

        <div className="col-md-8">
          <Card className="card-mobile">
            <CardBody>
              <WebhookNotifications />
            </CardBody>
          </Card>
        </div>

        <div className="col-md-8">
          <Card className="card-mobile">
            <CardBody>
              <div className="row">
                <div className="col-md-6">
                  <p className="description-md">Documentação da API</p>

                  <p className="description-sm">
                    Acesse nossa documentação para integrar o seu sistema com a nossa API de assinatura.
                  </p>
                  <ButtonPrimary
                    text="Ver documentação"
                    style="btn-assinauai btn-sm"
                    action={() => {
                      window.open("https://documenter.getpostman.com/view/16465705/2s83tGoBpk","_blank")
                    }}
                  />
                </div>

                <div className="col-md-6">
                  <p className="description-md">API Token</p>

                  <p className="description-sm">
                    O token da API é enviado por e-mail. Se você ainda não o tem, clique no botão abaixo para recebê-lo.
                  </p>
                  <p className="description-sm">
                    Obs.: Será criado um novo token de acesso a API e caso você já tenha um, o antigo será desabilitado.
                  </p>
                  <ButtonPrimary
                    text="Enviar token"
                    progress={progressToken}
                    action={sendToken}
                    style="btn-assinauai btn-sm" />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )

}