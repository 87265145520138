import React, { useState } from "react";

import history from "../../routers/History";
import API from "../../services/api";

import "../../styles/login.css";

import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {VALIDATE_EMAIL} from "../../services/validationService";
import ErrorAlert from "../../components/utils/error-alert";
import getTitle from "../../utils/handleError";
import SinglePage from "../../components/template/SinglePage";
import {getAnalytics, logEvent} from "firebase/analytics";

function Email () {

  const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .required('O campo e-mail é obrigatório')
      .email('Informe um e-mail válido')
  });
  const initialValues = {
    email: "",
  };
  const renderError = (message) => <div style={{display: 'block'}} className="invalid-feedback">{message}</div>

  const [progress, setProgress] = useState(false)
  const [error, setError] = useState(null)
  const analytics = getAnalytics()

  async function handleSubmit (data) {
    setError(null);
    setProgress(true);
    await API.post(VALIDATE_EMAIL, data)
      .then((response) => {
        const result = response.data.data;
        history.push(`/confirmar-email`, {
          emailToken: result.token,
          deadline: result.deadline
        });
      })
      .catch((error) => {
        const result = error.response.data;
        setError({
          title: getTitle(result.code),
          message: result.error
        });
      })
      .finally(() => setProgress(false));
  }

  return (
    <SinglePage title="Nova conta" showBackPress={true}>
      <div style={{marginBottom: 15}}>
        <span className="description-md">
            <strong>1º Passo:</strong> Vamos começar validando o seu e-mail!<br/>
            Por gentileza, digite-o abaixo.
          </span>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, {}) => {
          // region send_email_code
          logEvent(analytics, 'send_email_code')
          // endregion
          handleSubmit({
            email: values.email.trim()
          });
        }}>
        {(formik) => (
          <Form>
            <ErrorAlert error={error} />

            <div className="form-group">
              <label>E-mail: <span className="au-red">*</span></label>
              <Field type="text" name="email" className="form-control" placeholder="Digite seu e-mail" />
              <ErrorMessage name="email" render={renderError} />
            </div>

            <div className="botao-login">
              <button type="submit" disabled={!formik.isValid || progress} className="btn btn-assinauai btn-block">
                { progress ? <span><i className="fa fa-spinner fa-spin"/></span> : "Continuar" }
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </SinglePage>
  );
}

export default Email;
