import {Card, Modal} from "react-bootstrap";

import ContactsList from "../../components/contatos/ContactsList";
import API from "../../services/api";
import getTitle from "../../utils/handleError";
import React, {useEffect, useState} from "react";
import {GET_CONTACTS} from "../../services/contactService";
import Progress from "../../components/template/Progress";
import NewContact from "../../components/contatos/NewContact";

import "./contacts.css";
import ContactsFilter from "../../components/contatos/ContactsFilter";
import ErrorAlert from "../../components/utils/error-alert";
import ContactDetails from "../../components/contatos/ContactDetails";
import {getAnalytics, logEvent} from "firebase/analytics";

export default function Contacts() {

  const [progress, setProgress] = useState(false)
  const [contacts, setContacts] = useState([])
  const [filter, setFilter] = useState({
    name: null,
    email: null
  })
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState({
    page: 1,
    perPage: 10
  })
  const [pagination, setPagination] = useState({
    numberPages: 0,
    currentPage: 0
  })
  const [selectedContact, setContact] = useState(null)
  const analytics = getAnalytics()

  useEffect(() => {
    getContacts();
  }, [currentPage])

  const getContacts = () => {
    const { name, email } = filter
    let query = `?page=${currentPage.page}&perPage=${currentPage.perPage}`
    if (name) {
      query += `&name=${name.trim()}`
    }
    if (email) {
      query += `&email=${email.trim()}`
    }

    setProgress(true)
    setError(null)
    API.get(GET_CONTACTS+query)
      .then((response) => {
        const contacts = response.data.data;
        setContacts(contacts.result);
        setPagination({
          numberPages: contacts.numberPages,
          currentPage: contacts.currentPage,
          total: contacts.total
        });
      })
      .catch((error) => {
        const result = error.response.data;
        setError({
          title: getTitle(result.code),
          message: result.error
        });
      })
      .finally(() => setProgress(false))
  }

  const updateContactSelected = (updatedContact) => {
    setContact(updatedContact)
    for(let index = 0; index < contacts.length; index++) {
      if (contacts[index].id === updatedContact.id) {
        contacts[index] = updatedContact;
      }
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Card className="card-mobile">
            <Card.Body>
              <ContactsFilter search={(filter) => {
                setFilter(filter)
                setCurrentPage({
                  ...currentPage,
                  page: 1
                })
              }} />

              <div className="col-md-12 d-flex justify-content-end" style={{marginBottom: '1rem'}}>
                <NewContact />
              </div>

              {
                progress ?
                  <Progress />
                  :
                  <>
                    {
                      !error ?
                        <ContactsList
                          contacts={contacts}
                          pagination={pagination}
                          currentPage={currentPage}
                          changePage={(page) => {
                            setCurrentPage({
                              ...currentPage,
                              page: page
                            })
                          }}
                          changePerPage={(perPage) => {
                            setCurrentPage({
                              page: 1,
                              perPage: perPage
                            })
                          }}
                          openContactDetails={(contact) => {
                            // region open_contact
                            logEvent(analytics, 'open_contact')
                            // endregion
                            setContact(contact)
                          }} />
                        :
                        <div className="row">
                          <div className="col-sm-12 col-md-4 offset-md-4" style={{marginTop: '1rem'}}>
                            <ErrorAlert error={error} />
                          </div>
                        </div>
                    }
                  </>
              }
            </Card.Body>
          </Card>
        </div>
      </div>

      <Modal
        show={selectedContact != null}
        fullscreen={true}
        onHide={() => setContact(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Detalhes do contato</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            selectedContact != null ?
              <ContactDetails
                contact={selectedContact}
                updateContact={(contact) => updateContactSelected(contact)}/>
              :
              null
          }
        </Modal.Body>
      </Modal>
    </>
  )

}