import React from "react";
import {Modal} from "react-bootstrap";

export default function ModalError({
  title, message, positiveButton, negativeButton, positiveAction, negativeAction
}) {

  return (
    <Modal size="sm" show={true} centered>
      <Modal.Body>
        <p className="title-md">{title}</p>
        <span className="description-md">
          <i className="fas fa-exclamation-triangle au-red"/> {message}
        </span>

        <div style={{marginTop: '1rem'}}>
          <button type="button" className="btn btn-assinauai btn-block"
                  onClick={() => positiveAction()}>
            {positiveButton}
          </button>

          <button type="button" className="btn btn-link btn-block"
                  style={{marginTop: 8}} onClick={() => negativeAction()}>
            {negativeButton}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )

}