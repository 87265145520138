import {Offcanvas} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import Notificacao from "./Notificacao";
import ListaVazia from "../documentos/ListaVazia";
import {getAnalytics, logEvent} from "firebase/analytics";
import {Context} from "../../routers/Context/AuthContext";

function ListaNotificacoes(props) {

  const [notificacoes, setNotificacoes] = useState([])
  const [bloquearIteracoes, setBloqueioIteracoes] = useState(false)
  const { darkMode } = useContext(Context)
  const analytics = getAnalytics()

  useEffect(() => {
    setNotificacoes(props.notificacoes);
  }, [props]);

  const limparNotificacoes = async () => {
    setBloqueioIteracoes(true);
    await props.markAsRead("0", true, -1);
    setBloqueioIteracoes(false);
  }

  return (
    <Offcanvas show={props.show}
               onHide={() => {props.ocultarLista()}}
               key={1}
               className={`${darkMode ? 'dark-mode' : ''}`}
               name="end"
               placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Notificações</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="row">
          {
            notificacoes.length > 0 ?
              <div className={bloquearIteracoes ? 'disabled' : ''} style={{marginBottom: '1rem', textAlign: 'end'}}>
                <a style={{textDecoration: 'none', color: '#F1581B', cursor: 'pointer'}}
                   onClick={() => {
                     // region mark_all_as_read
                     logEvent(analytics, 'mark_all_as_read')
                     // endregion
                     limparNotificacoes()
                   }}>
                  {
                    bloquearIteracoes ?
                      <i className="fas fa-spinner fa-spin"/>
                      :
                      <i className="fas fa-tasks"/>
                  } Marcar todas como lida
                </a>
              </div>
              :
              ''
          }

          {
            notificacoes.map((notificacao, index) => (
              <Notificacao key={index} notificacao={notificacao} index={index} bloquearIteracao={bloquearIteracoes} {...props} />
            ))
          }

          {
            notificacoes.length === 0 ? <ListaVazia /> : null
          }
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )

}

export default ListaNotificacoes;
