import React from "react";
import {Alert} from "react-bootstrap";

export const DANGER = "danger";
export const WARNING = "warning";

export default function ErrorAlert({error}) {

  return (
    <>
      {
        error != null ?
          <Alert
            variant={error.type !== undefined ? error.type : "danger"}>
            <Alert.Heading style={{margin: 0}}>
              {error.title.length === 0 ? "Erro" : error.title}
            </Alert.Heading>
            <p style={{margin: 0}}>{error.message}</p>
          </Alert>
          :
          null
      }
    </>
  )

}