import {version} from '../../../package.json';

export default function Version() {

  return (
    <div className="d-flex justify-content-center">
      <small>versão {version}</small>
    </div>
  )

}