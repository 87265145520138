export const getStatus = (document) => {
  switch (document.status) {
    case "concluded":
      return "Concluído"
    case "pending":
      return "Pendente"
    case "rejected":
      return "Rejeitado"
    case "not_signed":
      return "Não assinado"
    case "canceled":
      return "Cancelado"
    default:
      return "Indefinido"
  }
}

export const getStatusColor = (document) => {
  switch (document.status) {
    case "concluded":
      return "var(--au-green)"
    case "pending":
      return "var(--au-yellow)"
    case "rejected":
      return "var(--au-red)"
    case "not_signed":
      return "var(--au-grey)"
    case "canceled":
      return "var(--au-black)"
    default:
      return "--au-text-color"
  }
}

export const DOCUMENT_STATUS = {
  pending: "pending"
}