import fotoPerfil from "../../images/logo_fundo_640_640.jpeg";
import React, {useEffect, useState} from "react";
import API from "../../services/api";
import {handleError} from "../../utils/handleError";
import {GET_PROFILE_PICTURE} from "../../services/authService";

export default function ProfilePicture({isProfile, contactId}) {

  const [picture, setPicture] = useState(null)

  useEffect(() => {
    updatePicture()
  }, [])

  const updatePicture = () => {
    const url = localStorage.getItem("profile_picture")
    if (url === "null" || contactId !== undefined) {
      getPicture()
    } else {
      setPicture(url)
    }
  }

  const getPicture = () => {
    let query = ""
    if (contactId !== undefined) {
      query += `?contactId=${contactId}`
    }

    API.get(GET_PROFILE_PICTURE+query)
      .then((response) => {
        const result = response.data.data;
        setPicture(result.picture)
        if (contactId === undefined) {
          localStorage.setItem("profile_picture", result.picture)
        }
      }).catch((error) => {
      handleError(error.response.data)
    })
  }

  return (
    <img className={isProfile ? "profile-user-img img-fluid img-circle profilepic__image" : "img-circle elevation-2"}
      src={picture === null ? fotoPerfil : picture}
      alt="Foto de perfil" />
  )

}