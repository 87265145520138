import { useContext } from "react";
import { Context } from '../../routers/Context/AuthContext';
import Notifications from "./Notifications";
import history from "../../routers/History";
import DarkMode from "./DarkMode";
import {isAuthorized, PERMISSIONS} from "../../utils/permission/checkPermission";

function Header (props) {

  const { handleLogout } = useContext(Context);
  const { planoGratuitoContratado } = useContext(Context);
  const { darkMode } = useContext(Context)

  const openPaymentScreen = () => {
    history.push(`/pagamento`);
  }

  return (
    <nav className={`main-header navbar navbar-fixed navbar-expand ${darkMode ? 'navbar-dark' : 'navbar-light'}`}>
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"/></a>
        </li>
        <li className="nav-item">
          <a style={{fontWeight: 600, color: "var(--au-orange)"}} className="nav-link">
            {props.title}
          </a>
        </li>
      </ul>
      {
        planoGratuitoContratado ?
          <ul className="navbar-nav ml-auto">
            <li className="nav-item d-none d-sm-none d-md-block">
              <a className="nav-link" href="#" onClick={openPaymentScreen}><i className="fas fa-gift"/> Você está utilizando o plano gratuito!</a>
            </li>
          </ul>
          :
          null
      }
      <ul className="navbar-nav ml-auto">
        {
          isAuthorized(PERMISSIONS.api) ?
            <li className="nav-item d-none d-sm-block" style={{cursor: 'pointer'}}>
              <a className="nav-link" onClick={() => { history.push("/api"); }}>
                <i className="fas fa-laptop-code"/>
              </a>
            </li>
            :
            null
        }

        <DarkMode />

        {
          isAuthorized(PERMISSIONS.documents) ?
            <Notifications />
            :
            null
        }

        <li className="nav-item">
          <a className="nav-link" href="#" onClick={() => {
            handleLogout()
          }}><i className="fas fa-sign-out-alt"/> Sair</a>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
