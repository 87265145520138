import React, {useEffect, useState} from "react";
import Checkbox from "../template/Checkbox";
import API from "../../services/api";
import {ADD_PERMISSIONS, GET_PERMISSIONS} from "../../services/usersService";
import {handleError} from "../../utils/handleError";
import Progress from "../template/Progress";
import ButtonPrimary from "../template/buttons/ButtonPrimary";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";

export default function UserPermissions({user, onUpdate}) {

  const [progress, setProgress] = useState(false)
  const [progressAddPermissions, setProgressAddPermissions] = useState(false)
  const [permissions, setPermissions] = useState([])
  const [roles, setRoles] = useState([])
  const [permissionsSelected, setPermissionsSelected] = useState([])
  const [roleSelected, setRoleSelected] = useState(null)

  useEffect(() => {
    getPermissions()
  }, [])

  const getPermissions = () => {
    setProgress(true)
    API.get(GET_PERMISSIONS)
      .then((response) => {
        const result = response.data.data;
        setPermissions(result.permissions)
        setRoles(result.roles)
        setUsersPermissions(result)
      })
      .catch((error) => {
        handleError(error.response.data);
      })
      .finally(() => setProgress(false))
  }

  const addPermissions = () => {
    setProgressAddPermissions(true)
    API.post(ADD_PERMISSIONS, {
      collaboratorId: user.id,
      permissions: getPermissionsSelected()
    })
      .then(() => {
        showNotification(
          "",
          "Permissões atualizadas!",
          NOTIFICATION_TYPE.success,
          NOTIFICATION_POSITION.bottomLeft
        )
        onUpdate()
      })
      .catch((error) => {
        handleError(error.response.data);
      })
      .finally(() => setProgressAddPermissions(false))
  }

  const handlePermissionCheck = (position) => {
    const aux = permissionsSelected.map((item, index) => index === position ? !item : item)
    setPermissionsSelected(aux)
  }

  const handleRoleSelect = (role) => {
    let check = !(role === roleSelected)
    setRoleSelected(check ? role : null)

    let newPermissions = new Array(permissions.length).fill(false)
    role.permissions.forEach((permission) => {
      let permissionIndex = permissions.findIndex((p) => p.permission === permission.permission );
      if (permissionIndex >= 0) {
        newPermissions[permissionIndex] = check
      }
    })
    setPermissionsSelected(newPermissions)
  }

  const setUsersPermissions = (result) => {
    let auxPermissions = new Array(result.permissions.length).fill(false)

    user.roles.forEach((role) => {
      setRoleSelected(role)
      role.permissions.forEach((permission) => {
        let permissionIndex = result.permissions.findIndex((p) => p.permission === permission.permission );
        if (permissionIndex >= 0) {
          auxPermissions[permissionIndex] = true
        }
      })
    })

    user.permissions.forEach((permission) => {
      let permissionIndex = result.permissions.findIndex((p) => p.permission === permission.permission );
      if (permissionIndex >= 0) {
        auxPermissions[permissionIndex] = true
      }
    })

    setPermissionsSelected(auxPermissions)
  }

  const getPermissionsSelected = () => {
    let result = []

    permissionsSelected.forEach((selected, permissionIndex) => {
      if (selected) {
        let permission = permissions[permissionIndex]
        result.push(permission.permission)
      }
    })

    if (roleSelected) {
      result.push(roleSelected.key)

      roleSelected.permissions.forEach((permission) => {
        let permissionIndex = result.findIndex((p) => p === permission.permission );
        result.splice(permissionIndex, 1)
      })
    }

    return result
  }

  return (
    <>
      <p className="description-md">Permissões do usuário:</p>

      <div className="row">
        {
          progress ?
            <Progress />
            :
            <>
              <div className="col-md-6">
                <table className="table table-hover table-borderless permissions-table">
                  <thead>
                  <tr>
                    <th />
                    <th style={{verticalAlign: "middle" }}>Perfil</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    roles.map((role, index) =>
                      <tr key={index}>
                        <td onClick={(event => event.stopPropagation())}>
                          <Checkbox
                            id={index}
                            isChecked={roleSelected != null && roleSelected.key === role.key}
                            action={() => handleRoleSelect(role)} />
                        </td>
                        <td><p className="m-0">{role.name}</p></td>
                      </tr>
                    )
                  }
                  </tbody>
                </table>
              </div>

              <div className="col-md-6">
                <table className="table table-hover table-borderless permissions-table">
                  <thead>
                  <tr>
                    <th />
                    <th style={{verticalAlign: "middle" }}>Permissão</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    permissions.map((permission, index) =>
                      <tr key={index}>
                        <td onClick={(event => event.stopPropagation())}>
                          <Checkbox
                            id={index}
                            isChecked={permissionsSelected[index]}
                            action={(position) =>  handlePermissionCheck(position)} />
                        </td>
                        <td>
                          <p className="m-0">
                            {permission.name}
                          </p>
                        </td>
                      </tr>
                    )
                  }
                  </tbody>
                </table>
              </div>

              <div className="col-md-12 d-flex justify-content-end">
                <ButtonPrimary
                  text="Atualizar permissões"
                  style="btn-sm"
                  progress={progressAddPermissions}
                  action={() => addPermissions()}/>
              </div>
            </>
        }
      </div>
    </>
  )

}
