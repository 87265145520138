import React from "react";

import ListaVazia from "../documentos/ListaVazia";
import {ListGroup, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import PerPage from "../documentos/PerPage";
import ProfilePicture from "../usuario/ProfilePicture";

export default function ContactsList({
                                       contacts, pagination, changePage, currentPage, changePerPage, openContactDetails
                                     }) {

  const mobileVersion = (contact) => {
    return (
      <div onClick={() => {openContactDetails(contact)}} className="d-flex">
        <div className="profile-img" style={{marginRight: 8}}>
          <ProfilePicture isProfile={false} contactId={contact.id}/>
        </div>

        <div>
          {
            contact.name ?
              contact.name : <span style={{fontStyle: 'italic'}}>Nome não cadastrado</span>
          }

          {
            contact.verified ?
              <p className="m-0 description-sm">
                <i className="far fa-id-card verified"/> identidade verificada
              </p>
              :
              <p className="m-0 description-sm">
                <i className="far fa-id-card not-verified"/> identidade ainda não verificada
              </p>
          }
        </div>
      </div>
    )
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Veja os documentos enviados para este contato.
    </Tooltip>
  );

  return (
    <>
    {
      contacts.length > 0 ?
        <>
          <PerPage total={pagination.total} currentPage={currentPage} changePerPage={changePerPage} />

          <div className="row">
            <div className="d-block d-sm-none">
              <ListGroup>
                {
                  contacts.map((contact, index) =>
                    <ListGroup.Item key={index} >
                      {mobileVersion(contact)}
                    </ListGroup.Item>
                  )
                }
              </ListGroup>
            </div>

            <div className="d-none d-sm-block">
              <Table borderless hover className="contacts-table">
                <thead>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th />
                </tr>
                </thead>
                <tbody>
                {
                  contacts.map((contact, index) =>
                    <tr key={index} style={{cursor: 'pointer'}} onClick={() => {
                      openContactDetails(contact)
                    }}>
                      <td className="d-flex">
                        <div className="profile-img" style={{marginRight: 8}}>
                          <ProfilePicture isProfile={false} contactId={contact.id}/>
                        </div>

                        <div>
                          {
                            contact.name ?
                              contact.name : <span style={{fontStyle: 'italic'}}>Nome não cadastrado</span>
                          }

                          {
                            contact.verified ?
                              <p className="m-0 description-sm">
                                <i className="far fa-id-card verified"/> identidade verificada
                              </p>
                              :
                              <p className="m-0 description-sm">
                                <i className="far fa-id-card not-verified"/> identidade ainda não verificada
                              </p>
                          }
                        </div>
                      </td>
                      <td>
                        {contact.email}
                      </td>
                      <td>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}>
                          <button type="button" className="btn btn-assinauai btn-sm">
                            Exibir documentos
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  )
                }
                </tbody>
              </Table>
            </div>

            <div className="contacts-pagination">
              <ReactPaginate
                previousLabel={'Anterior'}
                nextLabel={'Próximo'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pagination.numberPages}
                forcePage={pagination.currentPage - 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(result) => {
                  let selected = result.selected;
                  changePage(selected + 1);
                }}
                containerClassName={'pagination'}
                pageClassName={'paginate_button page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'paginate_button page-item previous'}
                nextClassName={'paginate_button page-item next'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>
          </div>
        </>
        :
        <ListaVazia />
    }
    </>
  )

}