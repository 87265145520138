import axios from 'axios';
import history from "../routers/History";
import {URL_BASE} from "./endpoint";

const api = axios.create ({
	baseURL: `${URL_BASE}/web`
});

api.interceptors.response.use(
	function(successRes) {
		return successRes;
	},
	function(result) {
		if (result.response.status === 401 && history.location.pathname !== "/login") {
			window.location.href = '/login';
		} else if (result.response.status === 403) {
			const msg = result.response.data.erro;
			window.location.href = `/acesso-proibido?msg=${msg}`;
		}
		return Promise.reject(result);
	}
);

export default api;
