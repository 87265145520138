import {store} from "react-notifications-component";

export default function getTitle(code) {
  switch (code) {
    case "E001":
      return "E-mail em uso";
    case "E002":
      return "";
    case "E003":
      return "Código expirado";
    case "E004":
      return "Código já utilizado";
    case "E005":
      return "Dados inválidos";
    case "E006":
      return "Não encontrado";
    case "E007":
      return "CPF já cadastrado";
    case "E008":
      return "CNPJ já cadastrado";
    case "E009": // Verificação de identidade
      return "";
    case "E010": // Login inválido
      return "";
    case "E011":
      return "Conta desativada";
    case "E012":
      return "Sem assinatura ativa";
    case "E013":
      return "Pagamento pendente";
    case "E014":
      return "Senha de assinatura";
    case "E015":
      return "Erro inesperado";
    case "E016":
      return "Documento já assinado";
    case "E017":
      return "Senha inválida";
    case "E018":
      return "Processo não encontrado";
    case "E022":
      return "Cota atingida";
    case "E023":
      return "Processo encerrado";
    case "E024":
      return "";
    default:
      return "Erro";
  }
}

export function handleError(response) {
  if (response.code !== undefined && response.code !== -1) {
    const title = getTitle(response.code);
    store.addNotification({
      title: title,
      message: response.error,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  } else {
    store.addNotification({
      title: "Erro inesperado",
      message: "Infelizmente ocorreu algum erro inesperado. Por favor, verifique as informações e tente novamente!",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  }
}

export function getDefaultError() {
  return {
    title: "Erro inesperado",
    message: "Infelizmente ocorreu algum erro inesperado. Por favor, verifique as informações e tente novamente!"
  }
}