import { useState, useEffect } from 'react';

import api from '../../../services/api';
import history from '../../History';
import {PERMISSIONS} from "../../../utils/permission/checkPermission";

const queryString = require('query-string');

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      setAuthenticated(true);
    }

    setLoading(false);
  }, []);

  async function handleLogin(authDTO) {
    localStorage.setItem('token', authDTO.token);
    localStorage.setItem('guard', authDTO.guard);
    localStorage.setItem('user', JSON.stringify(authDTO.user));
    localStorage.setItem("profile_picture", null)
    if (authDTO.guard === 'collaborator') {
      localStorage.setItem('permissions', authDTO.permissions.join(","));
    }
    api.defaults.headers.Authorization = `Bearer ${authDTO.token}`;
    setAuthenticated(true);
    const { redirectTo } = queryString.parseUrl(window.location.href).query;
    history.push(redirectTo == null ? getHomePage(authDTO.permissions, authDTO.guard) : redirectTo);
  }

  function handleLogout() {
    setAuthenticated(false)

    const settings = localStorage.getItem('settings')
    localStorage.clear()
    localStorage.setItem('settings', settings)

    api.defaults.headers.Authorization = undefined
    history.push('/login')
  }

  function isValidToken() {
    let token = localStorage.getItem("token");

    if (token) {
      let dataExpiracao = JSON.parse(window.atob(token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/'))).exp * 1000,
        dataAtual = new Date().getTime();

      if (dataAtual < dataExpiracao) {
        return true;
      }
    }

    return false;
  }

  return { authenticated, loading, handleLogin, handleLogout, isValidToken };
}

function getHomePage(permissions, guard) {
  if (guard === 'collaborator') {
    if (permissions.filter(permission => permission === PERMISSIONS.documents).length) {
      return "/documentos"
    }

    if (permissions.filter(permission => permission === PERMISSIONS.profile).length) {
      return "/conta"
    }

    if (permissions.filter(permission => permission === PERMISSIONS.api).length) {
      return "/api"
    }

    if (permissions.filter(permission => permission === PERMISSIONS.payment).length) {
      return "/pagamento"
    }

    if (permissions.filter(permission => permission === PERMISSIONS.contacts).length) {
      return "/contatos"
    }
  }

  return "/documentos"
}