import { gql } from "@apollo/client";

const GET_DADOS_USUARIO = gql`
  {
    usuario {
      id
      primeiroNome
      ultimoNome
      nomeCompleto
      documento
      email
      cadVerificado
    }
  }
`;

const ATUALIZAR_DADOS_USUARIO = gql`
  mutation AtualizarUsuario ($primeiroNome: String!, $ultimoNome: String!) {
    atualizarUsuario(primeiroNome: $primeiroNome, ultimoNome: $ultimoNome) {
      primeiroNome
      ultimoNome
      nomeCompleto
    }
  }
`;

const VERIFIED_IDENTITY = gql`
  {
    usuario {
      cadVerificado
      hasSignature
    }
  }
`;

const querys = {
  GET_DADOS_USUARIO,
  ATUALIZAR_DADOS_USUARIO,
  VERIFIED_IDENTITY
}

export default querys;
