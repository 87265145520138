import React, {useState} from "react";

import { registerLocale } from  "react-datepicker";
import ptBr from 'date-fns/locale/pt-Br';
registerLocale('pt-BR', ptBr)

export default function ContactsFilter({search}) {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  const fetchContacts = () => {
    search({
      name,
      email
    })
  }

  return (
    <div className="row">
      <form className="form-inline">
        <div className="form-group mb-3 input-full-sm">
          <input placeholder="Nome do contato"
                 autoComplete="off" className="form-control form-control-sm"
                 type="search"
                 value={name}
                 onChange={(e) => setName(e.target.value)}
                 onKeyDown={(e) => {
                   if (e.key === 'Enter') fetchContacts()
                 }} />
        </div>

        <div className="form-group mb-3 input-full-sm">
          <input placeholder="E-mail do contato"
                 autoComplete="off" className="form-control form-control-sm"
                 type="search"
                 value={email}
                 onChange={(e) => setEmail(e.target.value)}
                 onKeyDown={(e) => {
                   if (e.key === 'Enter') fetchContacts()
                 }} />
        </div>

        <button
          type="button"
          onClick={() => fetchContacts()}
          className="btn btn-sm btn-assinauai mb-3 btn-sm-block">
          <i className="fa fa-filter"/> Buscar
        </button>
      </form>
    </div>
  )

}