import SinglePage from "../../../components/template/SinglePage";
import React, {useEffect, useState} from "react";
import Progress from "../../../components/template/Progress";
import API from "../../../services/api";
import {handleError} from "../../../utils/handleError";
import {CHECK_PIX, GET_QRCODE} from "../../../services/identityService";
import ButtonPrimaryBlock from "../../../components/template/buttons/ButtonPrimaryBlock";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../../utils/notifications";
import Countdown, {zeroPad} from "react-countdown";
import history from "../../../routers/History";
import ErrorAlert, {WARNING} from "../../../components/utils/error-alert";
import ButtonLinkBlock from "../../../components/template/buttons/ButtonLinkBlock";

export default function PIXValidation() {

  const [progressQRCode, setProgressQRCode] = useState(true)
  const [progress, setProgress] = useState(false)
  const [pix, setPix] = useState(null)
  const [error, setError] = useState(null)
  const [expired, setExpired] = useState(false)
  const time = ({ minutes, seconds, completed }) => {
    if (completed) {
      setExpired(true)
      return null;
    } else {
      return <span><strong>Expira em {zeroPad(minutes)}:{zeroPad(seconds)}</strong></span>;
    }
  }

  useEffect(() => {
    if (pix == null) {
      getQRCode()
    }
  }, [])

  const isPJ = () => {
    if (localStorage.getItem('guard') !== "collaborator") {
      const user = JSON.parse(localStorage.getItem('user'));
      return user.type === "JURIDICAL_PERSON";
    }
    return false;
  }

  const getQRCode = () => {
    setExpired(false)
    setProgressQRCode(true)
    setError(null)
    API.post(GET_QRCODE)
      .then((response) => {
        const result = response.data.data;
        setPix(result)
      })
      .catch((error) => {
        const result = error.response.data;
        if (result.code !== undefined && result.code === "E024") {
          history.push("/criar-senha-assinatura")
        } else {
          handleError(error.response.data)
        }
      })
      .finally(() => {
        setProgressQRCode(false);
      });
  }

  const checkPIX = () => {
    setError(null)
    setProgress(true)
    API.post(CHECK_PIX)
      .then((response) => {
        const result = response.data.data;
        if (result.verified) {
          history.push("/criar-senha-assinatura")
        } else {
          setError({
            title: "Pagamento não identificado",
            message: "Já realizou o pagamento? Por favor, clique novamente no botão: Já paguei, continuar",
            type: WARNING
          });
        }
      })
      .catch((error) => {
        handleError(error.response.data)
      })
      .finally(() => {
        setProgress(false);
      });
  }

  return (
    <SinglePage title="Verificação de identidade via PIX" showBackPress={!isPJ()}>
      <div style={{marginBottom: 15}}>

        <span className="description-sm" style={{marginBottom: 8}}>
          Por gentileza, realize o pagamento de R$0,01 através do QRCode abaixo para sua identidade ser validada.
        </span>
        <p className="description-sm">
          O pagamento <strong style={{color: "var(--au-orange)"}}>deve</strong> ser feito de uma conta <strong style={{color: "var(--au-orange)"}}>vinculada ao seu {isPJ() ? "CNPJ" : "CPF"}</strong>.
        </p>

        <ErrorAlert error={error} />

        {
          !progressQRCode && !expired ?
            <Countdown
              date={pix.deadline.toLocaleString('pt-Br', {
                timeZone: 'America/Sao_Paulo',
              })}
              renderer={time}
            />
            :
            null
        }
      </div>

      {
        progressQRCode ?
          <Progress />
          :
          <>
            {
              !expired ?
                <div className="d-flex flex-column align-items-center" style={{marginBottom: '1rem'}}>
                  <img src={pix.imagemQrcode} alt="QRCode de pagamento" />

                  <CopyToClipboard text={pix.qrcode}
                                   onCopy={() => {
                                     showNotification("", "Código copiado!", NOTIFICATION_TYPE.success, NOTIFICATION_POSITION.topCenter)
                                   }}>
                    <button className="btn btn-link btn-block">
                      <i className="far fa-copy"/> Copiar código do QR Code
                    </button>
                  </CopyToClipboard>
                </div>
                :
                <div className="d-flex flex-column align-items-center">
                  <hr style={{width: '100%'}} />
                  <p className="description-md" style={{marginBottom: 0}}>
                    <strong>
                      <i className="fas fa-exclamation-triangle" style={{color: "var(--au-red)"}}/> QRCode expirado
                    </strong>
                  </p>
                  <p className="description-sm">
                    O QRCode de verificação expirou, caso você tenha pagado, clique no botão: Já paguei, continuar, se não, clique no botão abaixo para gerar um novo QRCode.
                  </p>
                  <ButtonLinkBlock
                    text="Gerar novo QRCode"
                    action={() => getQRCode()}
                    progress={false}
                    icon="fas fa-qrcode"/>
                  <hr style={{width: '100%'}} />
                </div>
            }

            <div>
              <ButtonPrimaryBlock
                text="Já paguei, continuar"
                progress={progress}
                action={() => checkPIX()} />
            </div>
          </>
      }
    </SinglePage>
  )

}