import React from "react";
import {UploadDocumento} from "../components/documentos/UploadDocumento";

import API from "../services/api";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import * as Yup from "yup";
import {ErrorMessage, Form, Formik} from "formik";
import InputMask from "react-input-mask";
import {handleError} from "../utils/handleError";
import SinglePage from "../components/template/SinglePage";
import ErrorAlert, {WARNING} from "../components/utils/error-alert";
import {CHECK_DOCUMENT} from "../services/authenticityService";
import {parseDateToBR} from "../utils/dataUtils";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../utils/notifications";

const queryString = require('query-string');
const validationSchema = Yup.object({
  codDoc: Yup.string()
    .required('O campo código do documento é obrigatório')
    .min(32, 'Informe os 32 caracteres do código do documento'),
});
const renderError = (message) => <div style={{display: 'block'}} className="invalid-feedback">{message}</div>

const initialState = {
  nomeArquivo: "",
  arquivo: null,
  tipoVerificacao: null,
  codDoc: null,
  processInfo: null,
  progress: false,
  initialValues: {
    codDoc: "",
  },
  error: null
}

class VerificarAutenticidade extends React.Component {

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  async componentDidMount() {
    let codDoc = queryString.parseUrl(window.location.href).query.codigo;
    if (codDoc != null) {
      codDoc = codDoc.replaceAll("-", "");
      await this.setState({codDoc: codDoc, tipoVerificacao: 'porCodigo', initialValues: {
          codDoc: codDoc
        }
      });
      await this.getDocumento();
    }
  }

  selecionarDocumento(arquivo) {
    this.setState({arquivo: arquivo, nomeArquivo: arquivo.name});
  }

  async getDocumento() {
    this.setState({progress: true, processInfo: null, error: null});

    let formData = new FormData(),
      config = { headers: { "content-type": "multipart/form-data" }};

    if (this.state.codDoc != null) {
      formData.append("code", this.state.codDoc);
    } else {
      formData.append("file", this.state.arquivo);
    }

    API.post(CHECK_DOCUMENT, formData, config)
      .then((response) => {
        const result = response.data.data;
        this.setState({processInfo: result});
      })
      .catch((error) => {
        const result = error.response.data;
        if (result.code === "E006") {
          this.setState({error: {
              title: "Documento não encontrado",
              message: result.error,
              type: WARNING
            }})
        } else {
          handleError(error.response.data);
        }
      }).finally(() => {this.setState({progress: false})});
  }

  hashCopied = () => {
    showNotification(
      "",
      "Hash copiada!",
      NOTIFICATION_TYPE.success,
      NOTIFICATION_POSITION.center
    )
  }

  render() {
    return (
      <SinglePage title="Verificar autenticidade" showBackPress={this.state.tipoVerificacao != null} action={() => {
        this.setState(initialState);
      }}>

        <ErrorAlert error={this.state.error} />

        {
          this.state.tipoVerificacao == null ?
            <div className="row d-flex justify-content-center">
              <div style={{cursor: 'pointer'}} onClick={() => {this.setState({tipoVerificacao: 'porCodigo'})}} className="col-sm-12">
                <div className="card card-primary card-outline card-outline-tabs" style={{padding: 15, textAlign: 'center'}}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div style={{minWidth: '50%'}}>
                      <i style={{color: '#EC582A'}} className="fa fa-barcode fa-2x"/>
                      <h3 className="nome-pasta">Código do documento</h3>
                    </div>
                    <div>
                      <p className="desc-pasta">Tenho o código do documento em mãos!</p>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{cursor: 'pointer'}} onClick={() => {this.setState({tipoVerificacao: 'porDoc'})}} className="col-sm-12">
                <div className="card card-primary card-outline card-outline-tabs" style={{padding: 15, textAlign: 'center'}}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div style={{minWidth: '50%'}}>
                      <i style={{color: '#EC582A'}} className="fa fa-file-pdf fa-2x"/>
                      <h3 className="nome-pasta">Arquivo PDF</h3>
                    </div>
                    <div>
                      <p className="desc-pasta">Utilize o documento assinado pela Assina Uai.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ''
        }

        <div style={{display: this.state.tipoVerificacao == null ? 'none' : '', marginBottom: '1.5rem'}} className="row" >
          <div className="col-sm-12">
            <div className="row d-flex justify-content-center">
              {
                this.state.tipoVerificacao === 'porCodigo' ?
                  <Formik
                    initialValues={this.state.initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values ) => {
                      await this.setState({codDoc: values.codDoc});
                      await this.getDocumento();
                    }}>
                    {(formik) => (
                      <Form>
                        <div className="form-group">
                          <label className="label">
                            Código do documento
                          </label>
                          <InputMask
                            mask="********-********-********-********"
                            value={formik.values.codDoc}
                            className="form-control"
                            style={{textTransform: 'uppercase'}}
                            onChange={(e) => {
                              let value = e.target.value || '';
                              value = value.replaceAll('_', '');
                              value = value.replaceAll('-', '');
                              formik.setFieldTouched('codDoc');
                              formik.setFieldValue('codDoc', value, true);
                            }}
                          />
                          <ErrorMessage name="codDoc" render={renderError} />
                        </div>

                        <div className="botao-login">
                          <button onClick={() => formik.submitForm()} type="button" disabled={!formik.isValid || this.state.progress} className="btn btn-assinauai btn-sm btn-block">
                            {
                              !this.state.progress ?
                                <><i className="fa fa-search"/> Verificar documento</>
                                :
                                <i className="fas fa-spinner fa-spin"/>
                            } 
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  :
                  <>
                    {
                      this.state.arquivo != null ?
                        <div>
                          <p>
                            <i style={{color: '#EC582A'}} className="fa fa-file-pdf"/> {this.state.arquivo.name}
                            <i style={{color: '#e53935', marginLeft: 8, cursor: 'pointer'}} onClick={() => {
                              this.setState({arquivo: null, processInfo: null, error: null});
                            }} className="fa fa-times"/>
                          </p>
                          <button onClick={() => this.getDocumento()} disabled={this.state.progress} type="button" className="btn btn-block btn-assinauai">
                            {
                              !this.state.progress ?
                                <><i className="fa fa-search"/> Verificar documento</>
                                :
                                <i className="fas fa-spinner fa-spin"/>
                            }
                          </button>
                        </div>
                        :
                        <UploadDocumento selecionarDocumento={(arquivo) => this.selecionarDocumento(arquivo)} />
                    }
                  </>
              }
            </div>
          </div>

          {
            this.state.processInfo ?
              <div className="col-sm-12" style={{marginTop: '1rem'}}>
                <div>
                  <p align="justify">Certificamos que este documento <strong>{this.state.processInfo.name}</strong> foi assinado digitalmente pelas partes
                    abaixo nomeadas e possui eficácia jurídica, nos termos do artigo 10, § 2º, da Medida Provisória nº 2.200-2, de 24 de agosto de 2001 e do artigo 4°, inciso II da Lei Federal
                    n° 14.063/2020, de 23 de setembro de 2020.</p>

                  <p style={{color: 'var(--au-orange)'}}><strong>Dados do documento:</strong></p>

                  <p>Código do documento:<br/>{this.state.processInfo.code}</p>
                  <ul>
                    <li style={{fontSize: '14px'}}>Criado em: {parseDateToBR(this.state.processInfo.createdAt)}</li>
                    <li style={{fontSize: '14px'}}>
                      <div className="d-flex align-items-center">
                        <CopyToClipboard text={this.state.processInfo.hash}
                                         onCopy={() => {this.hashCopied()}}>
                          <button className="btn btn-default btn-sm"><i className="fas fa-copy"/></button>
                        </CopyToClipboard>
                        <p style={{margin: 0, marginLeft: 8}}>Hash sha256</p>
                      </div>
                    </li>
                  </ul>

                  <p style={{color: 'var(--au-orange)'}}><strong>Assinantes do documento:</strong></p>
                  {
                    this.state.processInfo.signatures.map((signatory, i) => (
                      <div key={i}>
                        <span>{signatory.name}</span>
                        <ul>
                          <li style={{fontSize: '14px'}}>Assinado em: {parseDateToBR(signatory.date)}</li>
                          <li style={{fontSize: '14px'}}>
                            <div className="d-flex align-items-center">
                              <CopyToClipboard text={signatory.hash}
                                               onCopy={() => {this.hashCopied()}}>
                                <button className="btn btn-default btn-sm"><i className="fas fa-copy"/></button>
                              </CopyToClipboard>
                              <p style={{margin: 0, marginLeft: 8}}>Hash sha256</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    ))
                  }

                  <div className="d-flex justify-content-end" style={{marginBottom: 8}}>
                    <button className="btn btn-default btn-sm" onClick={() => {
                      window.open(this.state.processInfo.url, '_blank');
                    }}>
                      <i className="fa fa-download"/> Baixar documento
                    </button>
                  </div>

                  <p style={{textAlign: 'justify'}}>Esta certificação é restrita aos documentos de hash acima indicados.</p>

                  <p style={{textAlign: 'justify'}}>Certificado por: <br/> <strong>SMART SIGN TECH em <span style={{color: "var(--au-orange)"}}>ASSINA UAI</span></strong>.</p>
                </div>
              </div>
              :
              ''
          }
        </div>
      </SinglePage>
    )
  }

}

export default VerificarAutenticidade;
