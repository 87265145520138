import React, {useState} from "react";

import PropTypes from "prop-types";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

import {renderError} from "../utils/InvalidInput";
import ErrorAlert from "../utils/error-alert";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

function CreatePassword({progress, onComplete, error}) {

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const validationSchema = Yup.object({
    password: Yup.string()
      .trim()
      .required('O campo senha é obrigatório')
      .min(8, 'A senha deve ter no mínimo 8 caracteres')
      .max(16, 'A senha deve ter no máximo 16 caracteres')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "A senha deve conter no mínimo um caracter minúsculo, um caracter maiúsculo e um número"
      ),
    confirmPassword: Yup.string()
      .trim()
      .required('O campo confirmar senha é obrigatório')
      .oneOf([Yup.ref('password'), null], 'As senhas não conferem')
  });
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, {}) => {
        onComplete(values.password.trim());
      }}>
      {(formik) => (
        <Form>
          <ErrorAlert error={error} />

          <div>
            <label className="label" htmlFor="password">Senha: <span className="au-red">*</span></label>
            <div className="input-group">
              <Field name="password" type={showPassword ? 'text' : 'password'} className="form-control" />
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => (
                  <Tooltip id="button-tooltip" {...props}>
                    {!showPassword ? "Exibir senha" : "Ocultar senha"}
                  </Tooltip>
                )}>
                <div className="input-group-append">
                  <span className="input-group-text" style={{cursor: 'pointer'}}
                        onClick={() => setShowPassword(!showPassword)}>
                    {
                      !showPassword ?
                        <i className="far fa-eye"/>
                        :
                        <i className="far fa-eye-slash"/>
                    }
                  </span>
                </div>
              </OverlayTrigger>
              <ErrorMessage name="password" render={renderError} />
            </div>
          </div>

          <p className="description-sm-center description-top">
            <i className="fas fa-exclamation-triangle au-red"/> Informe uma senha com no <strong>mínimo 8 caracteres e no máximo 16 caracteres</strong>,
            incluindo um <strong>caracter minúsculo</strong>, um <strong>caracter maiúsculo</strong> e um <strong>número</strong>.
          </p>

          <div>
            <label className="label" htmlFor="confirmPassword">Confirmar senha: <span className="au-red">*</span></label>
            <div className="input-group">
              <Field name="confirmPassword" type={showConfirmPassword ? 'text' : 'password'} className="form-control"/>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => (
                  <Tooltip id="button-tooltip" {...props}>
                    {!showConfirmPassword ? "Exibir senha" : "Ocultar senha"}
                  </Tooltip>
                )}>
                <div className="input-group-append">
                  <span className="input-group-text" style={{cursor: 'pointer'}}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {
                      !showConfirmPassword ?
                        <i className="far fa-eye"/>
                        :
                        <i className="far fa-eye-slash"/>
                    }
                  </span>
                </div>
              </OverlayTrigger>
              <ErrorMessage name="confirmPassword" render={renderError} />
            </div>
            <small className="form-text text-muted">
              Digite novamente sua nova senha.
            </small>
          </div>

          <div className="botao-login">
            <button type="submit" disabled={!formik.isValid || progress} className="btn btn-assinauai btn-block">
              { progress ? <span><i className="fa fa-spinner fa-spin"/></span> : 'Continuar' }
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )

}

CreatePassword.propTypes = {
  progress: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  error: PropTypes.object,
}

export default CreatePassword