import React, {useEffect, useState} from "react";

import logo from "../images/logotipo_h.svg";
import Numpad from "../components/assinatura/Numpad";
import queryString from "query-string";
import history from "../routers/History";
import API from "../services/api";
import {store} from "react-notifications-component";
import {NEW_CERTIFICATE} from "../services/certificateService";
import getTitle, {getDefaultError} from "../utils/handleError";
import ErrorAlert, {WARNING} from "../components/utils/error-alert";
import apolloClient from "../services/apolloClient";
import queries from "../querys/usuario/DadosUsuario";

function CriarSenhaAssinatura () {

  const [code, setCode] = useState(null)
  const [progress, setProgress] = useState(false)
  const [checkingIdentity, setCheckingIdentity] = useState(false)
  const [verifiedIdentity, setVerifiedIdentity] = useState(false)
  const [checkingIdentityError, setCheckingIdentityError] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    checkRegistrationVerification()
  }, [])

  const createPassword = () => {
    setProgress(true);
    API.post(NEW_CERTIFICATE, {
      password: parseInt(code, 10)
    })
      .then(() => {
        store.addNotification({
          title: "Senha criado!",
          message: "Senha de assinatura criada com sucesso!",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        popBackStack();
      }).catch((error) => {
      const result = error.response.data;
      if (result.code !== undefined && result.code !== -1) {
        if (result.code === "E019") {
          setVerifiedIdentity(false);
          setError({
            title: "Verificação facial pendente",
            message: "Antes de criar a sua assinatura, precisamos verificar a sua identidade. É um processo bem simples, são apenas 3 fotos.",
            type: WARNING
          });
        } else {
          setError({
            title: getTitle(result.code),
            message: result.error
          });
        }
      } else {
        setError(getDefaultError())
      }
    }).finally(() => {
      setProgress(false);
    });
  }

  const popBackStack = () => {
    const { redirectTo } = queryString.parseUrl(window.location.href).query;
    history.push(redirectTo == null ? "/documentos" : redirectTo);
  }

  const checkRegistrationVerification = () => {
    setCheckingIdentity(true);
    apolloClient.query({
      query: queries.VERIFIED_IDENTITY,
      variables: {},
      fetchPolicy: 'no-cache'
    })
      .then(result => {
        if (!result.data.usuario.cadVerificado) {
          setError({
            title: "Verificação facial pendente",
            message: "Antes de criar a sua assinatura, precisamos verificar a sua identidade. É um processo bem simples, são apenas 3 fotos.",
            type: WARNING
          });
        } else if (result.data.usuario.hasSignature) {
          popBackStack();
        } else {
          setVerifiedIdentity(result.data.usuario.cadVerificado);
        }
      })
      .catch(() => {
        setCheckingIdentityError({
          title: "",
          message: "Não conseguimos carregar todos os dados da tela. Por favor, atualize a página e tente novamente!"
        })
      })
      .finally(() => {
        setCheckingIdentity(false);
      });
  }

  return (
    <div className="container-center-au">
      <div style={{marginBottom: '1.5 rem'}} className="login-logo">
        <img src={logo} alt="Logo Assina uai" width="225"/>
      </div>
      <div className="box-panel">
        {
          !checkingIdentity ?
            <>
              {
                !checkingIdentityError ?
                  <>
                    {
                      verifiedIdentity ?
                        <>
                          <div style={{marginBottom: 15}}>
                            <p className="title-md">Senha de assinatura</p>
                            <span className="description-md">Será com esta senha que você irá assinar os documentos!</span>
                          </div>

                          <ErrorAlert error={error} />

                          <Numpad
                            showNumber={true}
                            sendCode={(code) => setCode(code)} />

                          <button
                            style={{marginTop: '1.5rem'}}
                            disabled={code == null || progress}
                            className="btn btn-block btn-assinauai"
                            onClick={() => createPassword()}>
                            {
                              progress ?
                                <i className="fas fa-spinner fa-spin"/>
                                :
                                null
                            } Definir senha
                          </button>

                          <button
                            style={{marginTop: '1rem'}}
                            className="btn btn-sm btn-link btn-block"
                            onClick={() => popBackStack()}>
                            Deixar para depois
                          </button>
                        </>
                        :
                        <div>
                          <div style={{marginBottom: 15}}>
                            <p className="title-md">Senha de assinatura</p>
                          </div>

                          <ErrorAlert error={error} />

                          <button
                            style={{marginTop: '1rem'}}
                            className="btn btn-sm btn-assinauai btn-block"
                            onClick={() => history.push("/verificar-identidade")}>
                            Verificar minha identidade
                          </button>

                          <button
                            style={{marginTop: '1rem'}}
                            className="btn btn-sm btn-link btn-block"
                            onClick={() => popBackStack()}>
                            Deixar para depois
                          </button>
                        </div>
                    }
                  </>
                  :
                  <div>
                    <div style={{marginBottom: 15}}>
                      <p className="title-md">Senha de assinatura</p>
                    </div>

                    <ErrorAlert error={error} />
                  </div>
              }
            </>
            :
            <div>
              <i className="fa fa-spinner fa-spin"/> carregando
            </div>
        }
      </div>
    </div>
  );
}

export default CriarSenhaAssinatura;
