import React from "react";

import QRCode from 'qrcode.react';
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";

export default function OpenInMobile() {

  return (
    <div>
      <div>
        <p className="description-md" style={{marginBottom: 0}}>
          As <strong>fotos</strong> precisam ser realizadas pelo <strong>celular</strong>.
        </p>
        <p className="description-md">
          Escaneie o QRCode ou clique no botão abaixo para copiar o link desta página.
        </p>
      </div>

      <div className="d-flex justify-content-center" style={{marginBottom: '1.5rem'}}>
        <QRCode value={window.location.href} renderAs="canvas" />
      </div>

      <div>
        <CopyToClipboard text={window.location.href} onCopy={() => {
          showNotification(
            "",
            "O link para acessar a tela de verificação facial foi copiado!",
            NOTIFICATION_TYPE.success,
            NOTIFICATION_POSITION.topCenter
          )
        }}>
          <button type="button" className="btn btn-block btn-sm btn-link">
            <i className="fas fa-copy"/> Copiar link
          </button>
        </CopyToClipboard>
      </div>
    </div>
  )

}