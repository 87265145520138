import {store} from "react-notifications-component";

export const NOTIFICATION_TYPE = {
  success: "success",
  danger: "danger",
  warning: "warning",
  info: "info",
}

export const NOTIFICATION_POSITION = {
  topRight: "top-right",
  topCenter: "top-center",
  bottomLeft: "bottom-left",
  center: "center"
}

export default function showNotification(title, message, type, position) {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: position,
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 7000,
      onScreen: true,
      pauseOnHover: true
    }
  });
}