import React, { useState } from "react";

import ReactCodeInput from 'react-verification-code-input';

import history from "../../routers/History";
import API from "../../services/api";

import "../../styles/login.css";
import {CONFIRM_EMAIL} from "../../services/validationService";
import getTitle from "../../utils/handleError";
import ErrorAlert from "../../components/utils/error-alert";
import Countdown from "react-countdown";
import SinglePage from "../../components/template/SinglePage";
import {getAnalytics, logEvent} from "firebase/analytics";

function ConfirmarEmail (props) {

  const [codigo, setCodigo] = useState('')
  const [progress, setProgress] = useState(false)
  const [error, setError] = useState(null)
  const analytics = getAnalytics()
  const time = ({ minutes, seconds, completed }) => {
    if (completed) {
      history.push("/nova-conta");
    } else {
      return <span><strong>Expira em {minutes}:{seconds}</strong></span>;
    }
  };

  function handleSubmit (e) {
    e.preventDefault();

    // region validate_email
    logEvent(analytics, 'validate_email')
    // endregion

    setError(null);
    setProgress(true);
    API.post(CONFIRM_EMAIL, {
      token: props.location.state.emailToken,
      code: codigo,
    })
      .then((response) => {
        const result = response.data.data;
        history.push('/iniciar-cadastro', {token: result.token});
      }).catch((error) => {
        // Erros de token
        const errors = ["E003", "E004"]
        const result = error.response.data;
        if (errors.indexOf(result.code) > -1) {
          history.push("/nova-conta")
        } else {
          setError({
            title: getTitle(result.code),
            message: result.error
          });
        }
    })
      .finally(() => setProgress(false));
  }

  return (
    <SinglePage>
      <div style={{marginBottom: 15}}>
        <p className="title-md">Verificação de e-mail</p>
        <span className="description-md">
            <strong>2º Passo:</strong> Informe abaixo o código que enviamos para o seu e-mail!<br/>
          </span>
      </div>
      <form onSubmit={handleSubmit}>
        <ErrorAlert error={error} />

        <div className="form-group">
          <label>Código de verificação: <span className="au-red">*</span></label>
          <ReactCodeInput
            fields={6}
            fieldWidth={44}
            onComplete={(e) => setCodigo(e)}
            className="codigo-validacao"
          />
          <p className="description-sm-center description-top">
            <i className="fas fa-exclamation-triangle au-red"/> Verifique se o e-mail chegou na sua caixa de spam.
            <br/>
            <Countdown
              date={(new Date(props.location.state.deadline))}
              renderer={time}
            />
          </p>
        </div>
        <div className="botao-login">
          <button type="submit" disabled={progress} className="btn btn-assinauai btn-block">
            { progress ? <span><i className="fa fa-spinner fa-spin"/></span> : 'Validar e-mail' }
          </button>
        </div>
      </form>
    </SinglePage>
  );
}

export default ConfirmarEmail;
