import React from "react";

export default function Checkbox({id, isChecked, action, text}) {

  return (
    <div className="d-flex justify-content-center">
      <input className="checkbox-au" type="checkbox"
             style={{zoom: '1.5'}}
             checked={isChecked}
             onChange={() => action(id)}
             value=""
             id={`check${id}`} />
      <label className="checkbox-au-input" htmlFor={`check${id}`}>{text}</label>
    </div>
  )

}