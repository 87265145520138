import history from "../../routers/History";
import {Context} from "../../routers/Context/AuthContext";
import {useContext} from "react";

function Avisos() {

  const { pagamentoPendente } = useContext(Context);

  const openPaymentScreen = () => {
    history.push('/pagamento');
  }

  return (
    <div className="row">
      {
        pagamentoPendente ?
          <div className="col-md-12">
            <div onClick={() => openPaymentScreen()} className="d-flex justify-content-center aviso">
              <p style={{margin: 0}}>
                <i className="fas fa-exclamation-triangle"/> O pagamento do seu plano está pendente
              </p>
            </div>
          </div>
          :
          null
      }
    </div>
  )

}

export default Avisos;