import React, {useEffect, useState} from "react";
import API from "../../services/api";
import {CANCEL, GET_INVOICES} from "../../services/payment-service";
import {handleError} from "../../utils/handleError";
import Progress from "../template/Progress";
import {parseDateToBR} from "../../utils/dataUtils";
import {Modal} from "react-bootstrap";
import ButtonPrimary from "../template/buttons/ButtonPrimary";
import ButtonLinkBlock from "../template/buttons/ButtonLinkBlock";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";

export default function Invoices() {

  const [progress, setProgress] = useState(false)
  const [canceling, setCanceling] = useState(false)
  const [confirmCancellation, setConfirmCancellation] = useState(false)
  const [invoices, setInvoices] = useState([])
  const [subscription, setSubscription] = useState(null)

  useEffect(() => {
    getInvoices()
  }, [])

  const getInvoices = () => {
    setProgress(true)
    API.get(GET_INVOICES)
      .then((response) => {
        const result = response.data.data
        setSubscription(result.subscription)
        setInvoices(result.invoices)
      }).catch((error) => {
      handleError(error.response.data)
    })
      .finally(() => setProgress(false))
  }

  const cancelSignature = () => {
    setCanceling(true)
    API.post(CANCEL)
      .then(() => {
        setConfirmCancellation(false)
        showNotification(
          "",
          "Assinatura cancelada",
          NOTIFICATION_TYPE.success,
          NOTIFICATION_POSITION.bottomLeft
        )
        getInvoices()
      }).catch((error) => {
      handleError(error.response.data)
    })
      .finally(() => setCanceling(false))
  }

  const renderStatus = () => {
    if (subscription != null) {
      switch (subscription.status) {
        case 'active':
          return 'ativa'
        case 'canceled':
          return 'cancelada'
        case 'past_due':
          return 'atrasada'
        case 'trialing':
          return 'testes'
        case 'unpaid':
          return 'não paga'
      }
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <p className="description-md text-muted">Assinatura</p>

          {
            progress ?
              <Progress />
              :
              <>
                {
                  !subscription ?
                    <div className="d-flex justify-content-center">
                      <p className="description-md">
                        Você ainda não possui uma assinatura ativa
                      </p>
                    </div>
                    :
                    <>
                      <table className="table table-borderless table-sm cards-table">
                        <tr>
                          <td>Plano</td>
                          <td>
                            <strong>{subscription.plan}</strong> <span className="badge badge-info">{renderStatus()}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {
                              subscription.value !== 0 ? "Valor" : "Uso"
                            }
                          </td>
                          <td>
                            {
                              subscription.value !== 0 ?
                                <strong>{subscription.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                                :
                                <div>
                                  <p className="m-0">
                                    <strong>{subscription.use.documents}</strong> <span className="text-muted">documentos enviados</span>
                                  </p>
                                  <p className="m-0">
                                    <strong>{subscription.use.liveness}</strong> <span className="text-muted">verificações faciais</span>
                                  </p>
                                </div>
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>Período</td>
                          <td>
                            <strong>
                              {parseDateToBR(subscription.period.start)} - {parseDateToBR(subscription.period.end)}
                            </strong>
                          </td>
                        </tr>
                      </table>

                      <div className="d-flex justify-content-between">
                        {
                          subscription && subscription.status !== 'canceled' ?
                            <button
                              className="btn btn-link btn-sm"
                              onClick={() => setConfirmCancellation(true)}
                              style={{color: 'var(--au-red)'}}>
                              <i className="fas fa-times"/> Cancelar assinatura
                            </button>
                            :
                            null
                        }
                      </div>
                    </>
                }
              </>
          }
        </div>

        <div className="col-md-8">
          <p className="description-md text-muted">Faturas anteriores</p>

          {
            progress ?
              <Progress />
              :
              <>
                {
                  invoices.length === 0 ?
                    <div className="d-flex justify-content-center">
                      <p className="description-md">
                        Você ainda não possui faturas.
                      </p>
                    </div>
                    :
                    <table className="table table-borderless table-sm cards-table description-sm">
                      <thead>
                      <tr>
                        <th scope="col">Código</th>
                        <th scope="col">Plano</th>
                        <th scope="col">Período</th>
                        <th/>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        invoices.map((invoice, index) =>
                          <tr key={index}>
                            <th>
                              <a href={invoice.invoicePDF} target="_blank" className="btn btn-link btn-sm text-muted">
                                <i className="fas fa-file-download"/> {invoice.number}
                              </a>
                            </th>
                            <th>{invoice.plan}</th>
                            <th>{parseDateToBR(invoice.period.start)} - {parseDateToBR(invoice.period.end)}</th>
                          </tr>
                        )
                      }
                      </tbody>
                    </table>
                }
              </>
          }
        </div>
      </div>

      <Modal show={confirmCancellation} onHide={() => setConfirmCancellation(false) } size="md" centered>
        <Modal.Body>
          <h4 className="text-muted">Cancelar assinatura</h4>

          <p className="description-md">
            Você tem certeza de que deseja cancelar a assinatura atual? Esta ação não poderá ser desfeita no futuro.
          </p>

          <div className={`d-flex flex-column align-items-center ${canceling ? 'disabled' : ''}`}>
            <ButtonPrimary
              text="Sim, quero cancelar"
              progress={canceling}
              action={() => cancelSignature() } />

            <span style={{margin: 8}} />

            <ButtonLinkBlock
              text="Não, não quero cancelar"
              action={() => setConfirmCancellation(false) } />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )

}