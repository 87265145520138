import React, {useState} from "react";
import {Modal} from "react-bootstrap";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CardForm from "./CardForm";

const stripePromise = loadStripe('pk_test_51Lb58vEV1IUrRiictXgaNulYhmBe5gn5Niw8P0jVWuAOrcgg0eLCKq6kBDSRhxuCQ8rCgUQJiQyeE40qCCobXs8w00BY9g7M4e');

export default function NewCreditCard({refreshCards}) {

  const [show, setShow] = useState(false)
  const options = {
    // passing the client secret obtained from the server
    clientSecret: 'seti_1LbPgeEV1IUrRiicvdLqzJey_secret_MK3oZYXQVovegIxKLDH6yDVJXWSr34w',
    appearance: {
      variables: {
        colorPrimary: '#F1581B',
        colorPrimaryText: '#000',
        colorText: '#888',
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <button className="btn btn-sm btn-link" onClick={() => setShow(true)}>
          <i className="fas fa-plus"/> Adicionar cartão
        </button>
      </div>

      <Modal size="md" show={show} centered>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12">
              <Elements stripe={stripePromise} options={options}>
                <CardForm onHide={() => setShow(false)} refreshCards={refreshCards} />
              </Elements>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )

}