import React, {useState} from "react";
import {Dropdown} from "react-bootstrap";

export default function Filter({search}) {

  const [clientName, setName] = useState("")
  const [email, setEmail] = useState("")
  const [validationType, setValidationType] = useState({
    pix: false,
    nextcode: false,
    none: false
  })

  const fetchClients = () => {
    search({
      clientName,
      email,
      validationType
    })
  }

  return (
    <div style={{marginBottom: '1rem'}}>
      <div className="row">
        <form className="form-inline">
          <div className="form-group mb-3 input-full-sm">
            <input placeholder="Nome"
                   autoComplete="off" className="form-control form-control-sm"
                   type="search"
                   value={clientName}
                   onChange={(e) => setName(e.target.value)}
                   onKeyDown={(e) => {
                     if (e.key === 'Enter') {
                       e.preventDefault()
                       fetchClients()
                     }
                   }} />
          </div>

          <div className="form-group mb-3 input-full-sm">
            <input placeholder="E-mail"
                   autoComplete="off" className="form-control form-control-sm"
                   type="search"
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
                   onKeyDown={(e) => {
                     if (e.key === 'Enter') {
                       e.preventDefault()
                       fetchClients()
                     }
                   }} />
          </div>

          <div id="status-filter" className="mb-3 d-flex align-items-center"
               style={{marginRight: 8}}>
            <div className="form-group input-full-sm" style={{marginBottom: 0}}>
              <Dropdown autoClose="outside">
                <Dropdown.Toggle variant="default" id="dropdown-status">
                  Tipo de validação
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setValidationType({...validationType, none: !validationType.none})}
                                 className="d-flex align-items-center">
                    <div className="d-flex align-items-center" style={{marginRight: 8}}>
                      <input readOnly={true} type="checkbox" className="checkbox-filter" checked={validationType.none} />
                    </div>
                    <div>
                      SEM VALIDAÇÃO
                    </div>
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => setValidationType({...validationType, pix: !validationType.pix})}
                                 className="d-flex align-items-center">
                    <div className="d-flex align-items-center" style={{marginRight: 8}}>
                      <input readOnly={true} type="checkbox" className="checkbox-filter" checked={validationType.pix} />
                    </div>
                    <div>
                      PIX
                    </div>
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => setValidationType({...validationType, nextcode: !validationType.nextcode})}
                                 className="d-flex align-items-center">
                    <div className="d-flex align-items-center" style={{marginRight: 8}}>
                      <input readOnly={true} type="checkbox" className="checkbox-filter" checked={validationType.nextcode} />
                    </div>
                    <div>
                      NEXTCODE
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <button
            type="button"
            onClick={() => fetchClients()}
            className="btn btn-sm btn-assinauai mb-3 btn-sm-block">
            <i className="fa fa-filter"/> Buscar
          </button>
        </form>
      </div>
    </div>
  )

}