import { Route } from "react-router-dom";

import Header from "../components/template/Header";
import Menu from "../components/template/Menu";
import Dashboard from "../components/template/Dashboard";
import Footer from "../components/template/Footer";
import Avisos from "../components/template/Avisos";

function PrivateRoute ({ component: Component, single, ...rest }) {

  return (
    <Route {...rest} render={(props) =>
      <>
        {
          !single ?
            <>
              <Avisos />
              <div className="wrapper">
                <Header {...rest}/>
                <Menu {...props}/>
                <Dashboard>
                  <Component {...rest} />
                </Dashboard>
                <Footer/>
                <div id="sidebar-overlay"/>
              </div>
            </>
            :
            <Component {...rest} />
        }
      </>
    } />
  )
}

export default PrivateRoute;
