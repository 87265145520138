import {Offcanvas} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import locais from "../../utils/localPasta";
import Pastas from "../../pages/Pastas";

function SelecionarPasta(props) {

  const [exibirCanvas, setExibir] = useState(false);

  useEffect (() => {
    setExibir(props.exibirCanvas);
  }, [props]);

  return (
    <Offcanvas show={exibirCanvas} onHide={() => props.setExibirCanvas()} key={1} name="end" placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Selecionar pasta</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {
          props.contato ?
            <Pastas
              local={locais.PASTAS_CONTATO}
              adicionandoPasta={(adicionado) => setExibir(!adicionado)}
              exibirEscolhaPasta={true}
              selecionarPasta={(pasta) => props.selecionarPasta(pasta)}
              nomeContato={props.contato.nome}
              contatoId={props.contato.id} />
            :
            null
        }
      </Offcanvas.Body>
    </Offcanvas>
  )

}

export default SelecionarPasta;
