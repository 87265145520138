import React, {useEffect, useState} from "react";

import {Card} from "react-bootstrap";
import * as PropTypes from "prop-types";
import DatePicker from "react-datepicker";

import MyDocuments from "../../components/documentos/MyDocuments";
import {MY_DOCUMENTS} from "../../services/documentService";
import API from "../../services/api";

import "react-datepicker/dist/react-datepicker.css";
import "./documents.css";
import Filter from "../../components/documentos/filter/Filter";
import Progress from "../../components/template/Progress";
import ErrorAlert from "../../components/utils/error-alert";
import getTitle from "../../utils/handleError";
import {getAnalytics, logEvent} from "firebase/analytics";

DatePicker.propTypes = {
  onChange: PropTypes.func,
  endDate: PropTypes.any,
  selectsRange: PropTypes.bool,
  inline: PropTypes.bool
};
export default function MyDocumentsPage() {

  const [documents, setDocuments] = useState([])
  const [progress, setProgress] = useState(true)
  const [filter, setFilter] = useState(null)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState({
    page: 1,
    perPage: 10
  })
  const [pagination, setPagination] = useState({
    numberPages: 0,
    currentPage: 0
  })
  const analytics = getAnalytics()

  useEffect(() => {
    if (filter != null) {
      getDocuments()
    }
  }, [currentPage])

  const getDocuments = () => {
    const { category, documentName, sentBy, startDate, endDate, showArchive } = filter
    const minDate = startDate !== null ? startDate.toISOString().slice(0, 10) : ""
    const maxDate = endDate !== null ? endDate.toISOString().slice(0, 10) : ""
    let query = `?category=${category}`
    query += `&documentName=${documentName}`
    if (sentBy) {
      query += `&sentBy=${sentBy.trim()}`
    }
    if (minDate) {
      query += `&minDate=${minDate}`
    }
    if (maxDate) {
      query += `&maxDate=${maxDate}`
    }
    query += `&page=${currentPage.page}&perPage=${currentPage.perPage}&showArchive=${showArchive ? 1 : 0}`

    // region fetch_documents_received
    logEvent(analytics, 'fetch_documents_received', {
      category
    })
    // endregion

    setProgress(true)
    setError(null)
    API.get(MY_DOCUMENTS+query)
      .then((response) => {
        const documents = response.data.data;
        setDocuments(documents.result);
        setPagination({
          numberPages: documents.numberPages,
          currentPage: documents.currentPage,
          total: documents.total
        });
      })
      .catch((error) => {
        const result = error.response.data;
        setError({
          title: getTitle(result.code),
          message: result.error
        });
      })
      .finally(() => setProgress(false))
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <Card className="card-mobile">
          <Card.Body>
            <Filter search={(filter) => {
              setFilter(filter)
              setCurrentPage({
                ...currentPage,
                page: 1
              })
            }} />

            {
              progress ?
                <Progress />
                :
                <>
                  {
                    !error ?
                      <MyDocuments
                        documents={documents}
                        category={filter.category}
                        pagination={pagination}
                        currentPage={currentPage}
                        changePage={(page) => {
                          setCurrentPage({
                            ...currentPage,
                            page: page
                          })
                        }}
                        changePerPage={(perPage) => {
                          setCurrentPage({
                            page: 1,
                            perPage: perPage
                          })
                        }} />
                      :
                     <div className="row">
                       <div className="col-sm-12 col-md-4 offset-md-4" style={{marginTop: '1rem'}}>
                         <ErrorAlert error={error} />
                       </div>
                     </div>
                  }
                </>
            }
          </Card.Body>
        </Card>
      </div>
    </div>
  )

}