import React, {useState} from "react";

import DOCUMENT_URL from "../../querys/documentos/getLinkDocumento";
import apolloClient from "../../services/apolloClient";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";
import {getAnalytics, logEvent} from "firebase/analytics";

function BaixarDocumento (props) {
  const [progress, setProgress] = useState(false)
  const analytics = getAnalytics()

  const baixar = () => {
    // region download_document
    logEvent(analytics, 'download_document')
    // endregion

    setProgress(true);
    apolloClient.mutate({
      mutation: DOCUMENT_URL,
      variables: {
        id: props.docId,
      },
      fetchPolicy: 'no-cache'
    })
      .then(result => {
        window.open(result.data.documento.url, '_blank');
      })
      .catch(() => {
        showNotification(
          "",
          "Não foi possível baixar o documento. Tente novamente mais tarde!",
          NOTIFICATION_TYPE.danger,
          NOTIFICATION_POSITION.center
        )
      })
      .finally(() => setProgress(false));
  }

  return (
    <>
      {
        !progress ?
          <button className="btn btn-default btn-sm" onClick={() => baixar()}> <i className="fa fa-download"/> Baixar documento </button>
          :
          <button disabled style={{marginRight: 15}} className="btn btn-default btn-sm">
            <i className="fas fa-spinner fa-spin"/> Carregando
          </button>
      }
    </>
  );
}

export default BaixarDocumento;
