import { gql } from "@apollo/client";

const GET_CONTATOS = gql`
  query getContatos($nomeEmail: String) {
    usuario {
      contatos(nomeEmail: $nomeEmail) {
        id
        usuarioId
        nome
        email
        telefone
        cadVerificado
        qtdDocsPendEnviados
        qtdDocsPendRecebidos
      }
    }
  }
`;

const GET_GRUPOS_CONTATOS = gql`
  query getGruposContatos {
    usuario {
      gruposContato {
        id
        nome
        contatos {
          id
          nome
          email
          telefone
        }
      }
    }
  }
`;

const CRIAR_GRUPO_CONTATOS = gql`
  mutation CriarGrupoContatos($nome: String!) {
    criarGrupo(nome: $nome) {
      id
    }
  }
`;

const REMOVER_GRUPO_CONTATOS = gql`
  mutation RemoverGrupoContatos($id: ID!) {
    removerGrupo(id: $id) {
      id
    }
  }
`;

const ADD_CONTATO = gql`
  mutation AddContato($usuarioId: ID, $email: String) {
    addContato(usuarioId: $usuarioId, email: $email) {
      id
      nome
      email
      telefone
    }
  }
`;

const REMOVER_CONTATO = gql`
  mutation RemoverContato($id: ID!) {
    removerContato(id: $id) {
      id
      nome
      email
      telefone
    }
  }
`;

const GET_CONTATOS_NAO_VINCULADOS_AO_GRUPO = gql`
  query ContatosNaoVinculados($grupoId: ID!) {
    contatosNaoVinculados(grupoId: $grupoId) {
      id
      nome
      email
      telefone
    }
  }
`

const ADD_CONTATOS_AO_GRUPO = gql`
  mutation adicionarContatos($grupoId: ID!, $ids: [RequestGrupo]!) {
    addContatosGrupo(grupoId: $grupoId, ids: $ids) {
      id
      nome
      contatos {
        id
        nome
        email
        telefone
      }
    }
  }
`

const REMOVER_CONTATOS_DO_GRUPO = gql`
  mutation removerContatos($grupoId: ID!, $ids: [RequestGrupo]!) {
    removerContatosGrupo(grupoId: $grupoId, ids: $ids) {
      id
      nome
      contatos {
        id
        nome
        email
        telefone
      }
    }
  }
`

const PESQUISAR_GRUPOS_CONTATO = gql`
  query getGruposContatos($nome: String!) {
    usuario {
      gruposContato(nome: $nome) {
        id
        nome
        qtdContatos
      }
    }
  }
`;

const querys = {
  GET_CONTATOS,
  GET_GRUPOS_CONTATOS,
  CRIAR_GRUPO_CONTATOS,
  REMOVER_GRUPO_CONTATOS,
  GET_CONTATOS_NAO_VINCULADOS_AO_GRUPO,
  ADD_CONTATOS_AO_GRUPO,
  REMOVER_CONTATOS_DO_GRUPO,
  ADD_CONTATO,
  REMOVER_CONTATO,
  PESQUISAR_GRUPOS_CONTATO
}

export default querys;
