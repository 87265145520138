import {diferencaEmSegundos, segundosParaDhms} from "../../utils/dataUtils";
import React, {useContext, useEffect, useState} from "react";
import {getAnalytics, logEvent} from "firebase/analytics";
import {Context} from "../../routers/Context/AuthContext";

function Notificacao(props) {

  const [progress, setProgress] = useState(false)
  const [notification, setNotification] = useState(null)
  const { darkMode } = useContext(Context)
  const analytics = getAnalytics()

  useEffect(() => {
    setNotification(props.notificacao);
  }, [props]);

  const markAsRead = async (id, marcarTodas) => {
    // region mark_as_read
    logEvent(analytics, 'mark_as_read')
    // endregion

    setProgress(true);
    await props.markAsRead(id, marcarTodas, props.index);
    setProgress(false);
  }

  return (
    <>
      {
        notification !== null ?
          <div className="col-md-12">
            <div style={{
              borderLeft: notification.data.aviso.includes("rejeitou") ? '3px solid #dc3545' : '',
              fontSize: '14px'
            }} className={`alert ${darkMode ? 'alert-dark' : 'alert-light'} ${(progress || props.bloquearIteracao ? "disabled" : "")}`} role="alert">
              <p style={{marginBottom: 8}}>{notification.data.aviso}</p>
              <div className="d-flex justify-content-between align-items-center">
                    <span style={{textAlign: 'right', marginRight: '10px'}} className="text-muted text-sm">
                      {segundosParaDhms(diferencaEmSegundos(notification.data))}
                    </span>
                <div className="d-flex justify-content-around align-items-center">
                  {
                    notification.data.docId ?
                      <a onClick={() => {
                        // region open_document_notification
                        logEvent(analytics, 'open_document_notification')
                        // endregion
                        props.abrirDocumento(notification.id, notification.data.docId, props.index)
                      }}
                         style={{textDecoration: 'none', color: '#1e88e5', marginRight: 8, cursor: 'pointer'}} >
                        <i className="far fa-file-pdf"/> Documento
                      </a>
                      :
                      null
                  }
                  <a style={{textDecoration: 'none', color: '#F1581B', cursor: 'pointer'}} onClick={() => {markAsRead(notification.id, false, props.index)}}>
                    {
                      progress ?
                        <i className="fas fa-spinner fa-spin"/>
                        :
                        <i className="fas fa-check"/>
                    } Marcar como lida
                  </a>
                </div>
              </div>
            </div>
          </div>
          :
          null
      }
    </>
  )

}

export default Notificacao;
