import { useEffect, useState } from "react";

import ReactPaginate from "react-paginate";

import ListarDocumentosEmTabela from "../documentos/ListarDocumentosEmTabela";
import ListarDocsPorPessoa from "../documentos/ListarDocsPorPessoa";
import ListarDocumentosEmCards from "../documentos/ListarDocumentosEmCards";

function DocumentosPasta(props) {

  const [width, setWidth] = useState(null);

  useEffect (() => {
    setWidth(window.innerWidth);
  }, [props]);

  return (
    <>
      {
        width !== null ?
          <>
            {
              width > 776 ?
                <ListarDocumentosEmTabela docs={props.lista} />
                :
                <ListarDocumentosEmCards docs={props.lista} />
            }
          </>
          :
          ''
      }
    </>
  )

}

export default DocumentosPasta;
