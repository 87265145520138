import React, {useEffect, useState} from "react";

import {Modal} from "react-bootstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

import {renderError} from "../utils/InvalidInput";
import API from "../../services/api";
import {ADD_CONTACT} from "../../services/contactService";
import getTitle from "../../utils/handleError";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";
import ErrorAlert from "../utils/error-alert";
import {getAnalytics, logEvent} from "firebase/analytics";

export default function NewContact(props) {

  const [showModal, setShowModal] = useState(false)
  const [progress, setProgress] = useState(false)
  const [error, setError] = useState(null)
  const analytics = getAnalytics()
  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required('O campo nome é obrigatório'),
    email: Yup.string()
      .trim()
      .required('O campo e-mail é obrigatório')
      .email('Informe um e-mail válido')
  });
  const initialValues = {
    name: "",
    email: props.email !== null ? props.email : ""
  }

  useEffect(() => {
    if (props.open !== undefined) {
      setShowModal(props.open)
    }
    console.log(props.email)
  }, [props])

  const addContact = (data) => {
    setProgress(true);
    API.post(ADD_CONTACT, data)
      .then((response) => {
        const result = response.data.data;
        setShowModal(false);
        if (props.email !== undefined) {
          props.newContact(result)
        }
        showNotification(
          "",
          "Contato adicionado!",
          NOTIFICATION_TYPE.success,
          NOTIFICATION_POSITION.topRight
        )
      })
      .catch((error) => {
        const result = error.response.data;
        setError({
          title: getTitle(result.code),
          message: result.error
        })
      })
      .finally(() => setProgress(false))
  }

  return (
    <>
      <div>
        {
          props.showButton === undefined || props.showButton ?
            <button className="btn btn-assinauai btn-sm" onClick={() => {
              // region add_contact
              logEvent(analytics, 'add_contact')
              // endregion
              setShowModal(true)
            }}>
              <i className="fa fa-user-plus"/> Adicionar contato
            </button>
            :
            null
        }
      </div>

      <Modal size="sm" show={showModal} centered>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, {}) => { addContact(values) }}>
            {(formik) => (
              <Form>
                <ErrorAlert error={error} />

                <div className="form-group">
                  <label>Nome: <span className="au-red">*</span></label>
                  <Field type="text" name="name" className="form-control" placeholder="Nome do contato" />
                  <ErrorMessage name="name" render={renderError} />
                </div>

                <div className="form-group">
                  <label>E-mail: <span className="au-red">*</span></label>
                  <Field type="email"
                         name="email"
                         className="form-control"
                         placeholder="E-mail do contato"
                         disabled={props.email !== undefined} />
                  <ErrorMessage name="email" render={renderError} />
                </div>

                <div>
                  <button type="submit" disabled={!formik.isValid || progress} className="btn btn-assinauai btn-block">
                    { progress ? <span><i className="fa fa-spinner fa-spin"/></span> : "Adicionar" }
                  </button>

                  <button className="btn btn-link btn-block"
                          style={{marginTop: 8}}
                          disabled={progress}
                          onClick={() => setShowModal(false)}>
                    Cancelar adição de contato
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )

}