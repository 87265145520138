import Lottie from 'react-lottie';
import animationData from '../../lotties/good-job-black.json';

function ListaVazia() {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div style={{textAlign: 'center'}}>
      <Lottie
        options={defaultOptions}
        height={250}
        width={250}
        paddingBottom={0}
      />
      <p style={{marginBottom: 0}}><strong>Nenhum resultado encontrado!</strong></p>
      <span>Agora é só relaxar! <i className="far fa-smile-wink"/></span>
    </div>
  )

}

export default ListaVazia;
