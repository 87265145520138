export const PERMISSIONS = {
  documents: "documents",
  contacts: "contacts",
  profile: "profile",
  api: "api",
  signature: "signature",
  payment: "payment",
  collaborators: "collaborators",
}

export const isAuthorized = (permission) => {
  if (permission === undefined) return true

  if (localStorage.getItem('guard') === 'collaborator') {
    const permissions = localStorage.getItem('permissions').split(',')
    console.log(permissions, permission)
    return permissions != null && permissions.filter(p => p === permission).length > 0
  }
  return true;
}
