import React, { useState, useEffect } from "react";
import API from "../../services/api";
import {POLICY_TERMS} from "../../services/authService";
import Progress from "../template/Progress";
import {parseDateToBR} from "../../utils/dataUtils";
import {handleError} from "../../utils/handleError";

export default function Terms() {

  const [links, setLinks] = useState(null)
  const [approvedDate, setApprovedDate] = useState(null)
  const [progress, setProgress] = useState(true)

  useEffect(() => {
    getLinks()
    const user = JSON.parse(localStorage.getItem("user"))
    setApprovedDate(user.approvedTermsDate)
  }, [])

  const getLinks = () => {
    setProgress(true)
    API.get(POLICY_TERMS)
      .then((response) => {
        const result = response.data.data;
        setLinks(result);
      })
      .catch((error) => {
        handleError(error.response.data)
      })
      .finally(() => setProgress(false))
  }

  return (
    <div>
      {
        progress ?
          <Progress />
          :
          <div>
            <div style={{marginBottom: '1rem'}}>
              <span className="description-md">Confirmo que li, entendi e concordo em vincular-me aos Termos de Uso e Política de privacidade.</span>
              <br />
              {
                approvedDate != null ?
                  <span className="text-muted">Aprovado em: {parseDateToBR(approvedDate)}</span>
                  :
                  null
              }
            </div>

            <ul className="policy-terms">
              <li className="d-flex align-items-center">
                <a href={links.termosUso} target="_blank" style={{marginLeft: 8}}>Termos de uso</a>
              </li>

              <li className="d-flex align-items-center">
                <a href={links.politicaPrivacidade} target="_blank" style={{marginLeft: 8}}>Política de privacidade</a>
              </li>

              <li className="d-flex align-items-center">
                <a href={links.marcoJuridico} target="_blank" style={{marginLeft: 8}}>Marco jurídico</a>
              </li>
            </ul>
          </div>
      }
    </div>
  )

}