import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";

const Numpad = forwardRef((props, ref) => {

  const [code, setCode] = useState([
    null, null, null, null, null, null
  ]);
  const [preview, setPreview] = useState("");

  useEffect(() => {
    createPreview(code);
  }, []);

  useImperativeHandle(ref, () => ({
    reset() {
      clear();
    }
  }));

  const createPreview = (newCode) => {
    let newPreview = "";
    newCode.forEach((num) => {
      if (num != null) {
        if (props.showNumber) {
          newPreview += (num + " ");
        } else {
          newPreview += ("* ");
        }
      } else {
        newPreview += "- ";
      }
    });
    setPreview(newPreview);
  }

  const addNumber = (number) => {
    const newCode = Array.from(code);
    for(let i = 0; i < 6; i++){
      if (newCode[i] == null) {
        newCode[i] = number;
        break;
      }
    }
    setCode(newCode);
    createPreview(newCode);
    sendCode(newCode);
  }

  const removeLast = () => {
    const newCode = code;
    for(let i = 5; i >= 0; i--){
      if (newCode[i] != null) {
        newCode[i] = null;
        break;
      }
    }
    setCode(newCode);
    createPreview(newCode);
    sendCode(newCode);
  }

  const sendCode = (newCode) => {
    let result = "";
    for(let i = 0; i < 6; i++){
      if (newCode[i] == null) {
        props.sendCode(null);
        return;
      } else {
        result += newCode[i];
      }
    }
    props.sendCode(result);
  }

  const clear = () => {
    const newCode = [null, null, null, null, null, null];
    setCode(newCode);
    createPreview(newCode);
    sendCode(newCode);
  }

  return (
    <div style={{marginBottom: 8}}>
      <div style={{textAlign: 'center', fontSize: 12}}>
        <h3>{preview}</h3>
      </div>

      <div className="d-flex justify-content-center numpad">
        <button className="btn btn-numpad col-3" onClick={() => addNumber(7)}>7</button>
        <button className="btn btn-numpad col-3" onClick={() => addNumber(8)}>8</button>
        <button className="btn btn-numpad col-3" onClick={() => addNumber(9)}>9</button>
      </div>
      <div className="d-flex justify-content-center numpad">
        <button className="btn btn-numpad col-3" onClick={() => addNumber(4)}>4</button>
        <button className="btn btn-numpad col-3" onClick={() => addNumber(5)}>5</button>
        <button className="btn btn-numpad col-3" onClick={() => addNumber(6)}>6</button>
      </div>
      <div className="d-flex justify-content-center numpad">
        <button className="btn btn-numpad col-3" onClick={() => addNumber(1)}>1</button>
        <button className="btn btn-numpad col-3" onClick={() => addNumber(2)}>2</button>
        <button className="btn btn-numpad col-3" onClick={() => addNumber(3)}>3</button>
      </div>
      <div className="d-flex justify-content-center numpad">
        <button className="btn btn-numpad col-3" onClick={() => {clear()}}>
          <i className="fas fa-trash-alt"/>
        </button>
        <button className="btn btn-numpad col-3" onClick={() => addNumber(0)}>0</button>
        <button className="btn btn-numpad col-3" onClick={() => {removeLast()}}>
          <i className="fas fa-arrow-left"/>
        </button>
      </div>
    </div>
  )

});

export default Numpad;