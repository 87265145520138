import React from "react";

export default function ButtonLinkBlock({text, progress, action, icon}) {

  return (
    <button style={{marginTop: 8, marginBottom: 8}}
            className="btn btn-link btn-block"
            disabled={progress}
            onClick={() => action()}>
      {
        progress ?
          <><i className="fa fa-spinner fa-spin"/></>
          :
          <><i className={icon} /> {text}</>
      }
    </button>
  )

}