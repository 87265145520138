import React, { useState, useContext } from "react";

import { Context } from '../routers/Context/AuthContext';
import history from "../routers/History";
import API from '../services/api';

import "../styles/login.css";

import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import getTitle from "../utils/handleError";
import ErrorAlert from "../components/utils/error-alert";
import {LOGIN} from "../services/authService";
import SinglePage from "../components/template/SinglePage";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { getAnalytics, logEvent } from "firebase/analytics";

function Login () {

  const [showPassword, setShowPassword] = useState(false)
  const analytics = getAnalytics()
  const validacaoLogin = Yup.object({
    login: Yup.string()
      .required('O campo login é obrigatório'),
    senha: Yup.string()
      .required('O campo senha é obrigatório')
      .min(8, 'A senha deve ter no mínimo 8 caracteres')
      .max(16, 'A senha deve ter no máximo 16 caracteres')
  });

  const dataFormLogin = {
    login: "",
    senha: "",
  };

  const renderError = (message) => <div style={{display: 'block'}} className="invalid-feedback">{message}</div>;

  const [progress, setProgress] = useState(false);
  const [error, setError] = useState(null);
  const { handleLogin } = useContext(Context);

  async function logar(data) {
    setError(null);
    setProgress(true);
    await API.post(LOGIN, data).then((response) => {
      logEvent(analytics, 'login');
      setProgress(false)
      const result = response.data.data;
      handleLogin(result)
    })
      .catch((error) => {
        setProgress(false)
        const result = error.response.data;
        setError({
          title: getTitle(result.code),
          message: result.error
        });
      });
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {!showPassword ? "Exibir senha" : "Ocultar senha"}
    </Tooltip>
  );

  return (
    <SinglePage>
      <div>
        <p className="title-md">Bem-Vindo!</p>
        <Formik
          initialValues={dataFormLogin}
          validationSchema={validacaoLogin}
          onSubmit={async (values, {}) => {
            await logar({
              login: values.login,
              password: values.senha
            });
          }}>
          {(formik) => (
            <Form>
              <ErrorAlert error={error} />

              <div className="form-group">
                <label>E-mail: <span className="au-red">*</span></label>
                <Field type="text" name="login" className="form-control" />
                <ErrorMessage name="login" render={renderError} />
              </div>

              <div style={{marginBottom: '1rem'}}>
                <label>Senha: <span className="au-red">*</span></label>
                <div className="input-group">
                  <Field type={showPassword ? 'text' : 'password'} name="senha" className="form-control" />
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}>
                    <div className="input-group-append">
                  <span className="input-group-text" style={{cursor: 'pointer'}}
                        onClick={() => setShowPassword(!showPassword)}>
                    {
                      !showPassword ?
                        <i className="far fa-eye"/>
                        :
                        <i className="far fa-eye-slash"/>
                    }
                  </span>
                    </div>
                  </OverlayTrigger>
                  <ErrorMessage name="senha" render={renderError} />
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <button type="button" className="btn btn-link"
                        style={{padding: 0, fontSize: 14}}
                        onClick={() => {
                          // region forgot_password
                          logEvent(analytics, 'forgot_password')
                          // endregion
                          history.push("/esqueci-minha-senha")
                        }}>
                  <i className="fas fa-user-lock"/> Esqueceu a senha?
                </button>
              </div>

              <div className="botao-login">
                <button type="submit" disabled={!formik.isValid || progress} className="btn btn-assinauai btn-block">
                  { progress ? <span><i className="fa fa-spinner fa-spin"/></span> : 'Acessar' }
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div style={{fontSize: 14, marginTop: 16}}>
          <p>Ainda não possui uma conta?</p>
          <button type="button" className="btn btn-assinauai btn-sm"
                  onClick={() => {
                    // region first_access
                    logEvent(analytics, 'first_access')
                    // endregion
                    history.push("/nova-conta")
                  }}>
            <i className="fas fa-user-plus"/> Primeiro acesso
          </button>
        </div>
      </div>
    </SinglePage>
  );
}

export default Login;
