import history from "../../routers/History";

function ListarDocumentosEmCards (props) {

  function renderData (doc) {
    switch (props.categoria) {
      case 'pendente':
        return (
          doc.prazo ? 
            <div style={{display: "flex", alignItems: "center", color: "red"}}> 
              <i className="fas fa-clock"/> 
              <span style={{margin: "auto 5px"}}>Assine até</span> 
              {formatarData(doc.prazo)} 
            </div> 
          : null
        );
      case 'assinado':
        return (
          <div style={{display: "flex", alignItems: "center"}}> 
            <i className="fas fa-calendar-check"></i> 
            <span style={{margin: "auto 5px"}}>Assinado em</span>
            {formatarData(doc.dataAss)} 
          </div>
        );
      case 'rejeitado':
        return (
          <div style={{display: "flex", alignItems: "center"}}>
            <i className="fas fa-calendar-times"></i>
            <span style={{margin: "auto 5px"}}>Rejeitado em</span>
            {formatarData(doc.dataRej)} 
          </div>
        );
      case 'enviado':
        return (
          <div style={{display: "flex", alignItems: "center"}}>
            <i className="fas fa-calendar-day"></i>
            <span style={{margin: "auto 5px"}}>Enviado em</span>
            {formatarData(doc.dataCriacao)}
          </div>
        );
      default:
        return "";
    }
  }

  function formatarData (data) {
    let dia = data.split(" ")[0].split("-").reverse().join("/"),
      hora = data.split(" ")[1] ? data.split(" ")[1] : null;
    
    if (hora) {
      return `${dia}, às ${hora}`;
    }

    return dia;
  }

  function abrirDocumento (doc) {
    history.push(`/documentos/${doc.docId}`);
  }

  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      {
        props.docs.map((doc, i) =>
          <div key={i} style={{display: "flex", flexDirection: "column", margin: "0 10px"}} onClick={() => abrirDocumento(doc)}>
            <div>
              <span style={{fontWeight: 600, fontSize: 18}}>{doc.nome}</span>
              <span style={{fontSize: 14, fontStyle: "italic"}}> | Enviado por {doc.dono}</span>
            </div>
            <div style={{fontSize: 12, marginTop: 10}}>
              {
                doc.prazo ?
                  <span>{renderData(doc)}</span>
                : "Não há prazo limite para assinar este documento"
              }
            </div>
            {
              i < (props.docs.length) ?
                <div style={{backgroundColor: "#dee2e6", width: "100%", height: 1, margin: "15px 0"}}></div>
              : null
            }
          </div>
        )
      }
    </div>
  );
}

export default ListarDocumentosEmCards;