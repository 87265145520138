import {Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import API from "../../services/api";
import {handleError} from "../../utils/handleError";
import ReactPaginate from "react-paginate";
import {WEBHOOK_NOTIFICATIONS} from "../../services/apiService";
import Progress from "../template/Progress";
import {parseDateToBR} from "../../utils/dataUtils";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import ReactJson from "react-json-view";
import WebhookURL from "./WebhookURL";
import PerPage from "../documentos/PerPage";

export default function WebhookNotifications() {

  const [progress, setProgress] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [notification, setNotification] = useState(null)
  const [currentPage, setCurrentPage] = useState({
    page: 1,
    perPage: 10
  })
  const [pagination, setPagination] = useState({
    numberPages: 0,
    currentPage: 0
  })

  useEffect(() => {
    getNotifications()
  }, [currentPage])

  const getNotifications = () => {
    let query = `?page=${currentPage.page}&perPage=${currentPage.perPage}`

    setProgress(true)
    API.get(WEBHOOK_NOTIFICATIONS+query)
      .then((response) => {
        const notifications = response.data.data;
        setNotifications(notifications.result);
        setPagination({
          numberPages: notifications.numberPages,
          currentPage: notifications.currentPage,
          total: notifications.total
        });
      })
      .catch((error) => {
        handleError(error.response.data)
      })
      .finally(() => setProgress(false))
  }

  const changePage = (page) => {
    setCurrentPage({
      ...currentPage,
      page: page
    })
  }

  const renderStatus = (status) => {
    switch (status) {
      case "succeeded":
        return <><i className="fas fa-flag" style={{color: "var(--au-green)"}} /> sucedido</>
      case "error":
        return <><i className="fas fa-flag" style={{color: "var(--au-yellow)"}} /> erro</>
      case "failed":
        return <><i className="fas fa-flag" style={{color: "var(--au-red)"}} /> falha</>
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between" style={{marginBottom: '1rem'}}>
        <p className="description-md m-0">Notificações via Webhook</p>

        <WebhookURL />
      </div>

      {
        progress ?
          <Progress />
          :
          <>
            <PerPage
              total={pagination.total}
              currentPage={currentPage}
              changePerPage={(perPage) => {
                setCurrentPage({
                  page: 1,
                  perPage: perPage
                })
              }} />

            <div className="d-none d-sm-block">
              <Table borderless hover style={{fontSize: 12}}>
                <thead>
                <tr>
                  <th style={{verticalAlign: "middle" }}>Enviado em</th>
                  <th style={{verticalAlign: "middle" }}>Tentativa</th>
                  <th style={{verticalAlign: "middle" }}>Código de retorno</th>
                  <th style={{verticalAlign: "middle" }}>Status</th>
                </tr>
                </thead>
                <tbody>
                {
                  notifications.map((notification, index) =>
                    <tr key={index} style={{cursor: 'pointer'}} onClick={() => setNotification(notification)}>
                      <td>
                        <p className="description-sm m-0">{parseDateToBR(notification.sentIn)}</p>
                        <p style={{color: 'var(--au-orange)', fontSize: 10}} className="m-0">
                          Clique para ver o payload enviado
                        </p>
                      </td>

                      <td>{notification.attempt}</td>
                      <td>{notification.statusCode}</td>
                      <td>{renderStatus(notification.status)}</td>
                    </tr>
                  )
                }
                </tbody>
              </Table>
            </div>

            <div className="d-flex justify-content-center" style={{marginTop: '1rem'}}>
              <ReactPaginate
                previousLabel={'Anterior'}
                nextLabel={'Próximo'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pagination.numberPages}
                forcePage={pagination.currentPage - 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(result) => {
                  let selected = result.selected
                  changePage(selected + 1)
                }}
                containerClassName={'pagination'}
                pageClassName={'paginate_button page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'paginate_button page-item previous'}
                nextClassName={'paginate_button page-item next'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>
          </>
      }

      <Modal isOpen={notification} size="md" centered>
        <ModalHeader toggle={() => setNotification(null)}>Payload</ModalHeader>
        <ModalBody>

          {
            notification != null ?
              <div>
                <p className="text-muted">
                  Webhook URL: <span style={{color: 'var(--au-orange)'}}>{notification.webhookUrl}</span>
                </p>
                <ReactJson
                  style={{borderRadius: '1rem', padding: '0.5rem'}}
                  src={JSON.parse(notification.payload)}
                  name={"payload"}
                  theme={"harmonic"}
                  displayDataTypes={false}
                  indentWidth={2} />
              </div>
              :
              null
          }
        </ModalBody>
      </Modal>
    </>
  )
}