import { useEffect, useState } from "react";

function BreadcrumbPastas(props) {

  const [diretorios, setDiretorios] = useState([]);

  useEffect (() => {
    setDiretorios(props.diretorios);
  }, [props]);

  return (
    <nav aria-label="breadcrumb">
      <ol style={{marginBottom: 0, padding: 0}} className="breadcrumb">
        {
          diretorios.map((dir, i) =>
            (i + 1) == diretorios.length ?
              <li key={i} className="breadcrumb-item active" aria-current="page">{dir.nome}</li>
              :
              <li key={i} onClick={() => props.reabrirDiretorio(dir, i)} style={{cursor: 'pointer'}} className="breadcrumb-item"><a>{dir.nome}</a></li>
          )
        }
      </ol>
    </nav>
  );

}

export default BreadcrumbPastas;
