import React from "react";

export default function ButtonLink({text, progress, action, icon}) {

  return (
    <button className="btn btn-link" disabled={progress} onClick={() => action()}>
      {
        progress ?
          <><i className="fa fa-spinner fa-spin"/></>
          :
          <><i className={icon} /> {text}</>
      }
    </button>
  )

}