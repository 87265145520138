import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement, CardCvcElement
} from '@stripe/react-stripe-js';
import React, {useState} from "react";
import ErrorAlert from "../utils/error-alert";
import API from "../../services/api";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";
import {handleError} from "../../utils/handleError";
import {ADD_CARD} from "../../services/payment-service";

const options = {
  'placeholder': '',
  style: {
    base: {
      'lineHeight': '1.8',
      'fontSize': '12px',
      'color': '#adb5bd',
      'fontFamily': 'apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif'
    }
  }
}

export default function CardForm({onHide, refreshCards}) {

  const [name, setName] = useState("")
  const [progress, setProgress] = useState(false)
  const [error, setError] = useState(null)
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event) => {
    event.preventDefault()
    setError(null)

    if (!stripe || !elements) {
      return
    }

    if(name.trim().length <= 3) {
      setError({
        title: " ",
        message: "O nome do titular está incompleto."
      })
      return
    }

    setProgress(true)
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: name,
      },
    });
    setProgress(false)


    if (result.error) {
      setError({
        title: " ",
        message: result.error.message
      })
    } else {
      saveCard(result.paymentMethod.id)
    }
  }

  const saveCard = (cardId) => {
    setProgress(true)
    API.post(ADD_CARD, {
      cardId
    }).then(() => {
      showNotification(
        "",
        "Cartão adicionado!",
        NOTIFICATION_TYPE.success,
        NOTIFICATION_POSITION.bottomLeft
      )
      onHide()
      refreshCards()
    }).catch((error) => {
      handleError(error.response.data);
    })
      .finally(() => setProgress(false));
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label>Nome do titular</label>
            <input value={name} onChange={(e) => setName(e.target.value)} className="form-control" />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label>Número do cartão</label>
            <CardNumberElement className="form-control" options={options} />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label>Expiração</label>
            <CardExpiryElement className="form-control" options={options} />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label>CVC</label>
            <CardCvcElement className="form-control" options={options} />
          </div>
        </div>
      </div>

      <ErrorAlert error={error} />

      <div className={`d-flex flex-column align-items-center ${progress ? 'disabled' : ''}`}>
        <button
          className="btn btn-assinauai btn-sm"
          style={{margin: '1rem'}}
          type="submit"
          disabled={!stripe}>
          {
            progress ?
              <><i className="fa fa-spinner fa-spin"/></>
              :
              <>Incluir cartão</>
          }
        </button>

        <button onClick={() => onHide()} className="btn btn-sm btn-link">
          Cancelar inclusão
        </button>
      </div>
    </form>
  )
};