import React, { useState, useEffect } from "react";

import ListaVazia from "./ListaVazia";
import history from "../../routers/History";
import ProcessStatus from "./ProcessStatus";

function ListarDocumentosEmTabela(props) {

  const [titulo, setTitulo] = useState("");
  const [documentosChecados, setDocsChecados] = useState(
    new Array(props.docs.length).fill(false)
  );
  const [mostrarBtnOcultarDocs, setMostrarBtnOcultarDocs] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    renderTitulo();
  }, [props]);

  function renderTitulo() {
    switch (props.categoria) {
      case "pendente":
        setTitulo("PRAZO PARA ASSINAR");
        break;
      case "assinado":
        setTitulo("ASSINADO EM");
        break;
      case "rejeitado":
        setTitulo("REJEITADO EM");
        break;
      case "enviado":
        setTitulo("ENVIADO EM");
        break;
      default:
        setTitulo("");
        break;
    }
  }

  function renderData(doc) {
    switch (props.categoria) {
      case "pendente":
        return doc.prazo ? formatarData(doc.prazo) : "Não há";
      case "assinado":
        return doc.dataAss ? formatarData(doc.dataAss) : "Não há";
      case "rejeitado":
        return doc.dataRej ? formatarData(doc.dataRej) : "Não há";
      case "enviado":
        return doc.dataCriacao ? formatarData(doc.dataCriacao) : "Não há";
      default:
        return "";
    }
  }

  function formatarData(data) {
    let dia = data.split(" ")[0].split("-").reverse().join("/"),
      hora = data.split(" ")[1] ? data.split(" ")[1] : null;

    if (hora) {
      return `${dia}, às ${hora}`;
    }

    return dia;
  }

  function abrirDocumento(doc) {
    const lastLocation = window.location.pathname + window.location.search;
    history.push(`/documentos/${doc.docId}?redirectTo=${lastLocation}`);
  }

  const handleCheck = (position) => {
    const docsChecados = documentosChecados.map((item, index) =>
      index === position ? !item : item
    );
    setDocsChecados(docsChecados);
    const qtdDocsChecados = docsChecados.filter(function (c) {
      return c === true;
    }).length;
    setMostrarBtnOcultarDocs(qtdDocsChecados > 0);
    setCheckAll(qtdDocsChecados === props.docs.length);
  };

  const handleCheckAll = () => {
    setDocsChecados(new Array(props.docs.length).fill(!checkAll));
    setCheckAll(!checkAll);
    setMostrarBtnOcultarDocs(!checkAll);
  }

  const handleArquivarDocs = () => {
    let ids = "";
    documentosChecados.forEach((docCheck, i) => {
      if (docCheck) {
        ids += props.docs[i].docId+',';
      }
    });
    props.arquivarDocs(ids.substring(0, ids.length -1));
  }

  return (
    <>
      {
        props.docs.length === 0 ?
          <ListaVazia categoria={props.categoria} />
          :
          <div>
            {
              mostrarBtnOcultarDocs ?
                <div style={{marginBottom: '10px'}}>
                  <button disabled={props.arquivando} onClick={handleArquivarDocs} className="btn btn-default">
                    {
                      !props.arquivando ?
                        <> <i style={{color: '#F1581B'}} className="fa fa-archive"/> Arquivar documentos </>
                        : <> <i className="fas fa-spinner fa-spin"/> Arquivando </>
                    }
                  </button>
                </div>
                :
                ''
            }
            <div className="table-responsive">
              <table className="table table-hover table-borderless">
                <thead>
                <tr>
                  <th style={{verticalAlign: "middle" }}>
                    <div>
                      <input type="checkbox"
                             className="checkbox-appearance"
                             checked={checkAll}
                             onChange={handleCheckAll}
                             value=""
                             id="checkAll" />
                      <label htmlFor="checkAll"/>
                    </div>
                  </th>
                  <th style={{fontSize: 12, fontWeight: 600, verticalAlign: "middle" }}>ENVIADO POR</th>
                  <th style={{fontSize: 12, fontWeight: 600, verticalAlign: "middle" }}>DOCUMENTO</th>
                  <th style={{fontSize: 12, fontWeight: 600, verticalAlign: "middle" }}>STATUS</th>
                  <th style={{fontSize: 12, fontWeight: 600, verticalAlign: "middle" }}>{titulo}</th>
                  <th style={{fontSize: 12, fontWeight: 600, verticalAlign: "middle"}}/>
                </tr>
                </thead>
                <tbody>
                {
                  props.docs.map((doc, i) =>
                    <tr key={i} style={{ cursor: "pointer" }}>
                      <td style={{verticalAlign: "middle" }}>
                        <div>
                          <input type="checkbox"
                                 className="checkbox-appearance"
                                 checked={documentosChecados[i]}
                                 onChange={() => handleCheck(i)}
                                 value=""
                                 id={`check${i}`} />
                          <label htmlFor={`check${i}`}/>
                        </div>
                      </td>
                      <td onClick={() => abrirDocumento(doc)} style={{ minWidth: 100, verticalAlign: "middle" }}>{doc.dono}</td>
                      <td onClick={() => abrirDocumento(doc)} style={{ minWidth: 200, verticalAlign: "middle" }}>
                        <strong>{doc.nome}</strong>
                      </td>
                      <td onClick={() => abrirDocumento(doc)} style={{ minWidth: 100, verticalAlign: "middle" }}>
                        <ProcessStatus document={doc} />
                      </td>
                      <td onClick={() => abrirDocumento(doc)} style={{ minWidth: 100, verticalAlign: "middle" }}>{renderData(doc)}</td>
                      <td onClick={() => abrirDocumento(doc)} style={{ minWidth: 100, verticalAlign: "middle" }}><button className="btn btn-sm btn-outline-secondary">Abrir</button></td>
                    </tr>
                  )
                }
                </tbody>
              </table>
            </div>
          </div>
      }
    </>
  );
}

export default ListarDocumentosEmTabela;
