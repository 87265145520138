import React, {useContext} from "react";

import Version from "./Version";

import logo from "../../images/logotipo_h.svg";
import "./css/single-page.css"
import history from "../../routers/History";
import {Context} from "../../routers/Context/AuthContext";

export default function SinglePage(props) {

  const { darkMode } = useContext(Context)

  return (
    <div className="container-sp">
      <div style={{marginBottom: '1.5 rem'}} className="login-logo">
        <img src={logo} alt="Logo Assina uai" width="225"/>
      </div>
      <div className={`box-panel ${darkMode ? 'dark-mode' : ''}`}>
        {
          props.title ?
            <div className="d-flex align-items-center" style={{marginBottom: '1rem'}}>
              {
                props.showBackPress ?
                  <a style={{marginRight: 16, color: "var(--au-orange)", fontSize: 24, cursor: 'pointer'}}
                          onClick={() => props.action !== undefined ? props.action() : history.goBack()}>
                    <i className="fas fa-chevron-left"/>
                  </a>
                  :
                  null
              }
              <p className="title-md" style={{marginBottom: 0}}>{props.title}</p>
            </div>
            :
            null
        }

        {props.children}
      </div>
      <div className="footer-sp">
        <Version />
      </div>
    </div>
  )

}