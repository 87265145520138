import React, {useEffect, useState} from "react";
import {ProgressBar} from "react-bootstrap";
import API from "../../services/api";
import {handleError} from "../../utils/handleError";
import Progress from "../template/Progress";
import {CONSUMPTION} from "../../services/documentService";

function Consumption() {

  const [progress, setProgress] = useState(false)
  const [values, setValues] = useState(null)

  useEffect(() => {
    fetchConsumption()
  }, [])

  const fetchConsumption = () => {
    setProgress(true)
    API.get(CONSUMPTION)
      .then((response) => {
        const result = response.data.data
        setValues(result)
        console.log(result)
      })
      .catch((error) => {
        handleError(error.response.data)
      })
      .finally(() => setProgress(false))
  }

  return (
    <div>
      {
        progress ?
          <Progress />
          :
          <>
            {
              values ?
                <>
                  <ProgressBar now={values.onDemand ? 0 : ((values.used * 100) / values.total)} />
                  <p className="text-sm text-muted">
                    Documentos disponíveis: {values.onDemand ? 'ilimitado' : `${values.total - values.used}`}
                  </p>
                </>
                :
                null
            }
          </>
      }
    </div>
  )

}

export default Consumption;
