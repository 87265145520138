import React, {useState} from "react";

import {Modal} from "react-bootstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

import {renderError} from "../utils/InvalidInput";
import API from "../../services/api";
import {ADD_CONTACT} from "../../services/contactService";
import getTitle from "../../utils/handleError";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";
import {getAnalytics, logEvent} from "firebase/analytics";

export default function EditContact({contact, updateContact}) {

  const [showModal, setShowModal] = useState(false)
  const [progress, setProgress] = useState(false)
  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required('O campo nome é obrigatório')
  });
  const initialValues = {
    name: contact.name !== null ? contact.name : "",
  }
  const analytics = getAnalytics()

  const saveContact = (data) => {
    setProgress(true);
    API.post(ADD_CONTACT, {
      ...data,
      email: contact.email
    })
      .then(() => {
        contact = {
          ...contact,
          name: data.name
        }
        updateContact(contact)
        setShowModal(false)
        showNotification(
          "",
          "Contato atualizado!",
          NOTIFICATION_TYPE.success,
          NOTIFICATION_POSITION.topRight
        )
      })
      .catch((error) => {
        const result = error.response.data;
        showNotification(
          getTitle(result),
          result.error,
          NOTIFICATION_TYPE.danger,
          NOTIFICATION_POSITION.center
        )
      })
      .finally(() => setProgress(false));
  }

  return (
    <>
      <div>
        <button className="btn btn-assinauai btn-sm" onClick={() => {
          // region edit_contact_btn
          logEvent(analytics, 'edit_contact_btn')
          // endregion
          setShowModal(true)
        }}>
          <i className="fas fa-user-edit"/> Editar contato
        </button>
      </div>

      <Modal size="sm" show={showModal} centered>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, {}) => { saveContact(values) }}>
            {(formik) => (
              <Form>
                {/*<ErrorAlert error={error} />*/}

                <div className="form-group">
                  <label>Nome: <span className="au-red">*</span></label>
                  <Field type="search" name="name" className="form-control" placeholder="Nome do contato" />
                  <ErrorMessage name="name" render={renderError} />
                </div>

                <div>
                  <button type="submit" disabled={!formik.isValid || progress} className="btn btn-assinauai btn-block">
                    { progress ? <span><i className="fa fa-spinner fa-spin"/></span> : "Salvar" }
                  </button>

                  <button className="btn btn-link btn-block"
                          style={{marginTop: 8}}
                          disabled={progress}
                          onClick={() => setShowModal(false)}>
                    Cancelar edição
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )

}