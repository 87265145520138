import {Card} from "react-bootstrap";
import {CardBody} from "reactstrap";
import React, {useEffect, useState} from "react";
import API from "../../services/api";
import {GET_CLIENTS} from "../../services/dashboard";
import {handleError} from "../../utils/handleError";
import Progress from "../template/Progress";
import ReactPaginate from "react-paginate";
import PerPage from "../documentos/PerPage";
import Filter from "./Filter";
import {parseDateToBR} from "../../utils/dataUtils";

export default function Clients() {

  const [progress, setProgress] = useState(false)
  const [clients, setClients] = useState([])
  const [pagination, setPagination] = useState({
    numberPages: 0,
    currentPage: 0,
    total: 0
  })
  const [currentPage, setCurrentPage] = useState({
    page: 1,
    perPage: 10
  })
  const [filter, setFilter] = useState({
    clientName: "",
    email: "",
    validationType: {
      pix: false,
      nextcode: false,
      none: false
    }
  })

  useEffect(() => {
    getClients()
  }, [currentPage])

  const getClients = () => {
    const { clientName, email, validationType } = filter
    let query = `?page=${currentPage.page}&perPage=${currentPage.perPage}`
    if (clientName) {
      query += `&name=${clientName}`
    }

    if (email) {
      query += `&email=${email}`
    }

    if (validationType.pix) {
      query += "&validationType[]=pix"
    }

    if (validationType.nextcode) {
      query += "&validationType[]=nextcode"
    }

    if (validationType.none) {
      query += "&validationType[]=none"
    }

    setProgress(true)
    API.get(GET_CLIENTS+query)
      .then((response) => {
        const clients = response.data.data;
        setClients(clients.result);
        setPagination({
          numberPages: clients.numberPages,
          currentPage: clients.currentPage,
          total: clients.total
        });
      })
      .catch((error) => {
        handleError(error.response.data);
      })
      .finally(() => setProgress(false))
  }

  const changePerPage =  (perPage) => {
    setCurrentPage({
      page: 1,
      perPage: perPage
    })
  }

  return (
    <Card className="card-mobile">
      <CardBody>
        <Filter search={(filter) => {
          setFilter(filter)
          setCurrentPage({
            ...currentPage,
            page: 1
          })
        }} />

        {
          !progress ?
            <>
              <div>
                <PerPage total={pagination.total} currentPage={currentPage} changePerPage={changePerPage} />
              </div>

              <div className="table-responsive" style={{overflow: 'inherit'}}>
                <table className="table table-hover table-borderless">
                  <thead>
                  <tr>
                    <th style={{fontSize: 12, fontWeight: 600}}>USUÁRIOS</th>
                    <th />
                  </tr>
                  </thead>
                  <tbody>
                  {
                    clients.map((client, index) =>
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <p className={`${client.name === "" ? "without-name" : ""} m-0`}>
                                {client.name ? client.name : "Sem nome cadastrado"}
                              </p>
                              <p className="description-sm text-muted m-0">
                                <i className="fas fa-envelope"/> {client.email}
                              </p>
                              <p className="description-sm text-muted m-0">
                                Cadastrado em: {parseDateToBR(client.createdAt)}
                              </p>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div>
                            <p className="description-sm m-0">
                              {
                                client.verificationMethod ?
                                  <p className="m-0">
                                    <span style={{color: 'var(--au-orange)', fontWeight: 700}}>{client.verificationMethod.toUpperCase()}</span>
                                    <span> em {parseDateToBR(client.verificationDate)}</span>
                                  </p>
                                  :
                                  <p className="text-muted m-0">
                                    Identidade ainda não verificada
                                  </p>
                              }
                            </p>
                            <p className="description-sm text-muted m-0">
                              <i className="fas fa-file-pdf"/> Documentos: {client.numDocumentsSent} enviado(s), {client.numDocumentsReceived} recebido(s) e {client.numSignedDocuments} assinado(s)
                            </p>
                          </div>
                        </td>
                      </tr>
                    )
                  }
                  </tbody>
                </table>
              </div>

              <div className="documents-pagination">
                <ReactPaginate
                  previousLabel={'Anterior'}
                  nextLabel={'Próximo'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pagination.numberPages}
                  forcePage={pagination.currentPage - 1}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(result) => {
                    let selected = result.selected
                    setCurrentPage({
                      ...currentPage,
                      page: selected+1
                    })
                  }}
                  containerClassName={'pagination'}
                  pageClassName={'paginate_button page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'paginate_button page-item previous'}
                  nextClassName={'paginate_button page-item next'}
                  previousLinkClassName={'page-link'}
                  nextLinkClassName={'page-link'}
                  activeClassName={'active'}
                />
              </div>
            </>
            :
            <Progress />
        }
      </CardBody>
    </Card>
  )

}