import { useEffect, useState } from "react";

function Pasta(props) {

  const pasta = props.pasta;

  return (
    <div className={"pasta " + (props.pastaSelecionada != null && pasta.id == props.pastaSelecionada.id ? 'active' : '')}
         onClick={(e) => {e.stopPropagation(); props.selecionarPasta(pasta);}}
         onDoubleClick={() => props.abrirPasta(pasta, true, "Pasta.jsx")}>
      <i className="fa fa-folder fa-2x"/>
      <h3 className="nome-pasta">{pasta.nome}</h3>
      <p className="desc-pasta">{pasta.qtdDocs} documentos | {pasta.qtdDirs} pastas</p>
    </div>
  );

}

export default Pasta;
