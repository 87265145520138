import React from "react";

import "../../styles/login.css";

import SinglePage from "../../components/template/SinglePage";
import CreatePassword from "../../components/auth/CreatePassword";
import history from "../../routers/History";

export default function CreatePasswordPage (props) {

  return (
    <SinglePage>
      <div style={{marginBottom: 15}}>
        <p className="title-md">Nova conta</p>
        <span className="description-md">
            <strong>5º Passo:</strong> Chegamos ao fim!<br/>
            Precisamos que você crie uma senha para acessar a sua conta!
          </span>
      </div>
      <CreatePassword
        progress={false}
        onComplete={(password) =>
          history.push('/politica-privacidade-e-termos-de-uso?redirectTo=/verificar-identidade', {
            password: password.trim(),
            confirmPassword: password.trim(),
            ...props.location.state
          })}
        error={null} />
    </SinglePage>
  );
}
