import { gql } from "@apollo/client";

const DOCUMENT_URL = gql`
  query DOCUMENT_URL ($id: ID!) {
    documento (id: $id) {
      url
    }
  }
`;

export default DOCUMENT_URL;