import {useEffect, useState} from "react";

export default function ProcessStatus({document}) {

  const info = {
    pending: {
      title: "Pendente",
      color: "var(--au-yellow)"
    },
    inProgress: {
      title: "Em andamento",
      color: "var(--au-blue)"
    },
    rejected: {
      title: "Documento não assinado",
      color: "var(--au-red)"
    },
    concluded: {
      title: "Concluído",
      color: "var(--au-green)"
    }
  }

  const [status, setStatus] = useState({
    title: "",
    color: ""
  })

  useEffect(() => {
    if ((document.qtdAssinaram + document.qtdRejeitaram) === 0 ) {
      setStatus(info.pending)
    } else if ((document.qtdAssinaram + document.qtdRejeitaram) !== document.qtdAssinantes && !document.possuiVersaoFinal) {
      setStatus(info.inProgress)
    } else if(document.qtdRejeitaram === document.qtdAssinantes) {
      setStatus(info.rejected)
    } else if (document.possuiVersaoFinal) {
      setStatus(info.concluded)
    }
  }, [document])

  return (
    <div>
      <p style={{margin: 0, color: status.color}}>
        <stron><i className="fas fa-flag"/> {status.title}</stron>
      </p>
      {
        status.title !== info.rejected.title?
          <span className="text-muted" style={{fontSize: 12}}>
            {document.qtdAssinaram} de {document.qtdAssinantes} já assinaram
          </span>
          :
          null
      }
    </div>
  )

}