import {useMemo, forwardRef, useImperativeHandle, useContext} from "react";
import { useDropzone } from "react-dropzone";
import {Context} from "../../routers/Context/AuthContext";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#343a40",
  outline: "none",
  minHeight: "300px",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

export const UploadDocumento = forwardRef((props, ref) => {

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      props.selecionarDocumento(acceptedFiles[0]);
    }
  };

  const { darkMode } = useContext(Context)
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: props.accept,
      maxFiles: 1,
      maxSize: 10485760
    });
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  useImperativeHandle(ref, () => ({
    removeAll () {
      acceptedFiles.splice(0, acceptedFiles.length);
    }
  }));

  return (
    <>
      <div {...getRootProps({style})} className={`${darkMode ? 'dark-mode text-white' : ''}`}>
        <input {...getInputProps()} />
        <i style={{color: darkMode ? "#fff" : "var(--au-grey)", fontSize: 32}} className={`${props.accept === '.zip' ? 'fas fa-file-archive' : 'fas fa-file-pdf'}`}/>
        <p style={{textAlign: "center", marginTop: 5, fontSize: 18}}>
          Clique ou arraste um arquivo <strong style={{textTransform: 'uppercase', color: "var(--au-orange)"}}>{props.accept}</strong> para fazer
          o envio!
        </p>
        <p className="description-md">
          <i style={{color: 'var(--au-red)'}} className="fas fa-exclamation-triangle"/> O documento deve ter no máximo <strong style={{color: "var(--au-orange)"}}>10mb</strong>
        </p>
      </div>
      {
        props.accept === ".zip" ?
          <p style={{fontSize: 12, marginBottom: 0}}><span style={{color: "#dc3545"}}>*</span> Somente arquivos ZIP são aceitos.</p>
          : <p style={{fontSize: 12, marginBottom: 0}}><span style={{color: "#dc3545"}}>*</span> Somente arquivos PDFs são aceitos.</p>
      }
    </>
  );
});