import React from 'react';
import AccountUsers from "../components/users/AccountUsers";

export default function Users() {

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <AccountUsers />
        </div>
      </div>
    </>
  )

}
