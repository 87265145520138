import React, {useContext, useState} from "react";

import {ListGroup, Table} from "react-bootstrap";
import {parseDateToBR} from "../../utils/dataUtils";
import history from "../../routers/History";
import ListaVazia from "./ListaVazia";
import ReactPaginate from "react-paginate";
import PerPage from "./PerPage";
import Checkbox from "../template/Checkbox";
import ButtonPrimary from "../template/buttons/ButtonPrimary";
import API from "../../services/api";
import {ARCHIVE} from "../../services/documentService";
import {handleError} from "../../utils/handleError";
import {Context} from "../../routers/Context/AuthContext";

export default function DocumentsSent({documents, pagination, currentPage, changePage, changePerPage}) {

  const [checkAll, setCheckAll] = useState(false)
  const [checked, setChecked] = useState(new Array(documents.length).fill(false))
  const [archiving, setArchiving] = useState(false)
  const [showButtonArchive, setShowButtonArchive] = useState(false)
  const { darkMode } = useContext(Context)

  const handleCheckAll = () => {
    setChecked(new Array(documents.length).fill(!checkAll));
    setCheckAll(!checkAll);
    setShowButtonArchive(!checkAll)
  }

  const handleCheck = (position) => {
    const aux = checked.map((item, index) =>
      index === position ? !item : item
    );
    setChecked(aux)
    const quantity = aux.filter(function (checked) {return checked === true}).length
    setShowButtonArchive(quantity > 0);
    setCheckAll(quantity === documents.length);
  }

  const getStatus = (document) => {
    switch (document.status) {
      case "concluded":
        return "Concluído"
      case "pending":
        return "Pendente"
      case "rejected":
        return "Rejeitado"
      case "not_signed":
        return "Não assinado"
      case "canceled":
        return "Cancelado"
      default:
        return "Indefinido"
    }
  }

  const getStatusColor = (document) => {
    switch (document.status) {
      case "concluded":
        return "var(--au-green)"
      case "pending":
        return "var(--au-yellow)"
      case "rejected":
        return "var(--au-red)"
      case "not_signed":
        return "var(--au-grey)"
      case "canceled":
        return "var(--au-black)"
      default:
        return "--au-text-color"
    }
  }

  const openDocument = (document) => {
    const lastLocation = window.location.pathname + window.location.search;
    history.push(`/documentos/${document.id}?redirectTo=${lastLocation}`);
  }

  const mobileVersion = (document) => {
    return (
      <div>
        <p style={{color: darkMode ? 'white' : 'var(--au-text-color)'}} className="description-md m-0">
          {document.name}
        </p>
        <p className="description-sm text-muted m-0">
          Enviado em: {parseDateToBR(document.createdAt)}
        </p>
        <p className="number-of-signatures text-muted m-0">
                    <span style={{marginRight: 8}}>
                      {document.signatories.total} assinante(s)
                    </span>
          <strong>
            {document.signatories.signed} { document.signatories.signed === 1 ? "assinatura" : "assinaturas"}
            <span>/</span>
            {document.signatories.notSigned} { document.signatories.notSigned === 1 ? "não assinou" : "não assinaram"}
          </strong>
        </p>
        {
          document.isArchived ?
            <p className="description-sm text-muted">
              <i className="fa fa-archive" /> documento arquivado
            </p>
            :
            null
        }
        <br />
        <div className="d-flex justify-content-between" style={{marginBottom: 4}}>
          {
            document.deadline ?
              <p className="deadline">
                <i className="far fa-clock"/> Prazo: {parseDateToBR(document.deadline)}
              </p>
              :
              <p className="m-0"/>
          }

          <p className="description-sm text-muted m-0 text-right">
            <i className="fas fa-flag" style={{color: getStatusColor(document)}} /> {getStatus(document)}
          </p>
        </div>
      </div>
    )
  }

  const handleArchive = () => {
    let ids = [];
    checked.forEach((isChecked, index) => {
      if (isChecked) {
        ids.push({
          id: documents[index].id
        })
      }
    })
    archive(ids)
  }

  const archive = (ids) => {
    setArchiving(true)
    API.post(ARCHIVE, {
      documents: ids, archive: true
    })
      .then(() => {
        if ((ids.length === documents.length) && currentPage.page > 1) {
          changePage(currentPage.page - 1)
        } else {
          changePage(currentPage.page)
        }
      })
      .catch((error) => {
        handleError(error.response.data);
      })
      .finally(() => setArchiving(false))
  }

  return (
    <>
      {
        documents.length > 0 ?
          <>
            <div className="d-flex align-top">
              <PerPage total={pagination.total} currentPage={currentPage} changePerPage={changePerPage} />

              {
                showButtonArchive ?
                  <div>
                    <ButtonPrimary
                      text="Arquivar documento(s)"
                      progress={archiving}
                      style="btn-sm"
                      icon="fas fa-archive"
                      action={() => handleArchive()} />
                  </div>
                  :
                  null
              }
            </div>

            <div className="d-block d-sm-none">
              <ListGroup>
                {
                  documents.map((document, index) =>
                    <ListGroup.Item key={index} onClick={() => openDocument(document)}>
                      {mobileVersion(document)}
                    </ListGroup.Item>
                  )
                }
              </ListGroup>
            </div>

            <div className="d-none d-sm-block">
              <Table borderless hover className="documents-table">
                <thead>
                <tr>
                  <th style={{verticalAlign: "middle" }}>
                    <Checkbox
                      id="all"
                      action={() => handleCheckAll()}
                      isChecked={checkAll}
                    />
                  </th>
                  <th style={{verticalAlign: "middle" }}>Documento</th>
                  <th style={{verticalAlign: "middle" }}>Status</th>
                </tr>
                </thead>
                <tbody>
                {
                  documents.map((document, index) =>
                    <tr key={index}>
                      <td onClick={(event => event.stopPropagation())}>
                        <Checkbox
                          id={index}
                          isChecked={checked[index]}
                          action={(index) => handleCheck(index)} />
                      </td>
                      <td onClick={() => openDocument(document)} style={{cursor: 'pointer'}}>
                        {document.name}
                        <p className="number-of-signatures text-muted">
                          <span style={{marginRight: 8}}>{document.signatories.total} assinante(s)</span>

                          <strong>
                            {document.signatories.signed} { document.signatories.signed === 1 ? "assinatura" : "assinaturas"}
                            <span>/</span>
                            {document.signatories.notSigned} { document.signatories.notSigned === 1 ? "não assinou" : "não assinaram"}
                          </strong>
                        </p>
                        {
                          document.deadline ?
                            <p className="deadline">
                              <i className="far fa-clock"/> Prazo: {parseDateToBR(document.deadline)}
                            </p>
                            :
                            null
                        }
                        {
                          document.isArchived ?
                            <p className="description-sm text-muted">
                              <i className="fa fa-archive" /> documento arquivado
                            </p>
                            :
                            null
                        }
                      </td>
                      <td>
                        <p style={{fontWeight: 600, margin: 0}}>
                          <i className="fas fa-flag" style={{color: getStatusColor(document)}} /> {getStatus(document)}
                        </p>
                        <p className="created-at text-muted">
                          <span>Enviado em: {parseDateToBR(document.createdAt)}</span>
                        </p>
                      </td>
                    </tr>
                  )
                }
                </tbody>
              </Table>
            </div>

            <div className="documents-pagination">
              <ReactPaginate
                previousLabel={'Anterior'}
                nextLabel={'Próximo'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pagination.numberPages}
                forcePage={pagination.currentPage - 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(result) => {
                  let selected = result.selected
                  changePage(selected + 1)
                }}
                containerClassName={'pagination'}
                pageClassName={'paginate_button page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'paginate_button page-item previous'}
                nextClassName={'paginate_button page-item next'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>
          </>
          :
          <ListaVazia />
      }
    </>
  )

}