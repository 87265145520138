const PASTAS_USUARIO = 0;
const PASTAS_CONTATO = 1;
const PASTAS_GRUPO = 2;

const locais = {
  PASTAS_USUARIO,
  PASTAS_CONTATO,
  PASTAS_GRUPO
}

export default locais;
