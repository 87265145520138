import ButtonPrimary from "../template/buttons/ButtonPrimary";
import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import API from "../../services/api";
import {handleError} from "../../utils/handleError";
import {WEBHOOK_URL} from "../../services/apiService";
import Progress from "../template/Progress";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";
import ButtonDefault from "../template/buttons/ButtonDefault";
import Swal from "sweetalert2";

const validationSchema = Yup.object({
  url: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Informe uma URL válida!'
    )
    .required('O campo é obrigatório')
    .max(140, 'A URL deve ter no máximo 140 caracteres'),
});
const renderError = (message) => <div style={{display: 'block'}} className="invalid-feedback">{message}</div>

export default function WebhookURL() {

  const [showFow, setShowForm] = useState(false)
  const [initialValues, setInitialValues] = useState(null)
  const [progressGet, setProgressGet] = useState(false)
  const [progressPost, setProgressPost] = useState(false)
  const [progressDelete, setProgressDelete] = useState(false)
  const [webhookEnabled, setWebhookEnabled] = useState(false)

  useEffect(() => {
    getWebhookURL()
  }, [])

  const getWebhookURL = () => {
    setProgressGet(true)
    API.get(WEBHOOK_URL)
      .then((response) => {
        const result = response.data.data;
        setInitialValues({url: result.url})
        setWebhookEnabled(result.url != null)
      })
      .catch((error) => {
        handleError(error.response.data);
      })
      .finally(() => setProgressGet(false))
  }

  const saveWebhookURL = (data) => {
    setProgressPost(true)
    API.post(WEBHOOK_URL, data)
      .then(() => {
        showNotification(
          "",
          "URL atualizada!",
          NOTIFICATION_TYPE.success,
          NOTIFICATION_POSITION.bottomLeft,
        )
        setWebhookEnabled(true)
      })
      .catch((error) => {
        handleError(error.response.data);
      })
      .finally(() => setProgressPost(false))
  }

  const deleteWebhook = () => {
    Swal.fire({
      title: "",
      text: "Deseja realmente desativar as notificações via webhook?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#EC582A',
      cancelButtonColor: '#e53935',
      confirmButtonText: "Sim, desativar!",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        setProgressDelete(true)
        API.delete(WEBHOOK_URL)
          .then(() => {
            showNotification(
              "",
              "Webhook desativado!",
              NOTIFICATION_TYPE.success,
              NOTIFICATION_POSITION.bottomLeft,
            )
            getWebhookURL()
          })
          .catch((error) => {
            handleError(error.response.data);
          })
          .finally(() => setProgressDelete(false))
      }
    });
  }

  return (
    <>
      <div className="d-flex justify-content-around">
        <ButtonPrimary
          style="btn-assinauai btn-sm"
          text="Alterar URL"
          action={() => setShowForm(true)}/>

        <span style={{padding: 8}} />

        {
          webhookEnabled ?
            <ButtonDefault
              text="Desativar webhook"
              style="btn-sm btn-danger"
              progress={progressDelete}
              action={() => deleteWebhook()}/>
            :
            null
        }
      </div>

      <Modal isOpen={showFow} size="md" centered>
        <ModalHeader toggle={() => setShowForm(false)}>
          Alterar URL
        </ModalHeader>
        <ModalBody>
          { progressGet ? <Progress /> : null }

          {
            initialValues ?
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, {}) => {
                  saveWebhookURL(values)
                }}>
                {(formik) => (
                  <Form>
                    <div className="form-group">
                      <label>URL: <span className="au-red">*</span></label>
                      <Field type="text" name="url" className="form-control" placeholder="Digite sua URL" />
                      <ErrorMessage name="url" render={renderError} />
                    </div>

                    <div className="d-flex justify-content-center">
                      <ButtonPrimary
                        text="Continuar"
                        style="btn-sm"
                        progress={progressPost}
                        disabled={!formik.isValid}
                        action={() => formik.submitForm()} />
                    </div>
                  </Form>
                )}
              </Formik>
              :
              null
          }
        </ModalBody>
      </Modal>
    </>
  )

}