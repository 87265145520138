import {useEffect} from "react";

function Dashboard (props) {

  useEffect(() => {
    window.sidebarOverlay();
  }, []);

  return (
    <div id="mainDiv" className="content-wrapper">
      <section className="content section-mobile" style={{padding: '1rem'}}>
        { props.children }
      </section>
    </div>
  );
}

export default Dashboard;
