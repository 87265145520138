import React from "react";

export default function ProcessClosed({isArchived}) {

  return (
    <div>
      <p className="title-sm">Processo encerrado </p>
      <p className="description-sm">Não é mais necessário a sua assinatura neste documento, pois o processo de assinatura deste documento foi encerrado/cancelado.</p>
      {
        !isArchived ?
          <>
            <p className="description-sm">
              Clique no botão <strong style={{color: "var(--au-orange)"}}>arquivar</strong> para ocultar este documento na sua lista de documentos recebidos.
            </p>
          </>
          :
          null
      }
    </div>
  )

}