import {useContext, useState} from "react";
import {parseDateToBR} from "../../utils/dataUtils";
import {getAnalytics, logEvent} from "firebase/analytics";
import {Context} from "../../routers/Context/AuthContext";

function DocumentSignatories({signatories}) {

  const [showSignatories, setShowSignatories] = useState(false)
  const analytics = getAnalytics()
  const { darkMode } = useContext(Context)

  return (
    <>
      <div style={{marginBottom: '1rem'}}>
        <button type="button"
                className="btn btn-link btn-sm btn-block"
                onClick={() => {
                  // region show_signatories
                  logEvent(analytics, 'show_signatories')
                  // endregion
                  setShowSignatories(!showSignatories)
                }}>
          {
            !showSignatories ?
              <><i className="fas fa-eye"/> Exibir signatários</>
              :
              <><i className="fas fa-eye-slash"/> Ocultar signatários</>
          }
        </button>
      </div>

      {
        showSignatories ?
          <ul className="list-group">
            {
              signatories.map((signatory, index) => {
                return (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center"
                      style={{color: darkMode ? '#fff' : 'var(--au-text-color)'}}>
                    <div className="d-flex justify-content-between align-items-center">
                      {
                        signatory.profilePicture ?
                          <div style={{marginRight: 8}} className="image">
                            <img style={{width: 40}} src={signatory.profilePicture} className="img-circle elevation-2" alt="Foto do assinante" />
                          </div>
                          :
                          null
                      }
                      <div className="d-flex flex-column info-assinante">
                        <span><strong>{signatory.name}</strong></span>
                        <span style={{fontSize: 12}}><i className="fa fa-envelope"/> {signatory.email}</span>
                        {
                          signatory.signedIn != null || signatory.notSignedIn != null ?
                            signatory.signedIn != null ?
                              <span style={{fontSize: 12}}><i className="fa fa-signature"/> Assinado em: {parseDateToBR(signatory.signedIn)}</span>
                              :
                              <span style={{fontSize: 12}}><i style={{color: 'red'}} className="fa fa-times"/> Rejeitado em: {parseDateToBR(signatory.notSignedIn)}</span>
                            :
                            <span style={{fontSize: 12}}><i style={{color: 'var(--warning)'}} className="fa fa-exclamation-triangle"/> Assinatura pendente</span>
                        }
                      </div>
                    </div>
                  </li>
                )
              })
            }
          </ul>
          :
          null
      }
    </>
  );
}

export default DocumentSignatories;
