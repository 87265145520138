import React, {useState, useEffect, useRef} from "react";

import Swal from "sweetalert2";

import API from "../../services/api";
import Numpad from "../assinatura/Numpad";
import {MY_CERTIFICATE} from "../../services/certificateService";
import {handleError} from "../../utils/handleError";
import history from "../../routers/History";
import {REFUSED_DOCUMENT, SIGN_DOCUMENT} from "../../services/signatureService";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";
import Progress from "../template/Progress";
import ButtonPrimaryBlock from "../template/buttons/ButtonPrimaryBlock";
import {getAnalytics, logEvent} from "firebase/analytics";

function SignDocument (props) {

  let numPad = useRef(null)
  const [loading, setLoading] = useState(true)
  const [showProgressSign, setShowProgressSign] = useState(false)
  const [showProgressNotSign, setShowProgressNotSign] = useState(false)
  const [certificate, setCertificate] = useState(null)
  const [location, setLocation] = useState(null)
  const [code, setCode] = useState(null)
  const [locationLoading, setLocationLoading] = useState(false)
  const analytics = getAnalytics()

  useEffect (() => {
    getMyCertificate()
  }, []);

  const getMyCertificate = () => {
    API.get(MY_CERTIFICATE)
      .then((response) => {
        const result = response.data.data;
        setCertificate(result);
        getLocation()
      }).catch((error) => {
      const result = error.response.data;
      if (
        result.code === undefined ||
        result.code === -1 ||
        result.code !== "E006") {
        handleError(error.response.data);
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  function sign () {
    // region sign_document
    logEvent(analytics, 'sign_document')
    // endregion

    setShowProgressSign(true);
    const {document} = props;
    API.post(SIGN_DOCUMENT, {
      password: code,
      documentID: document.id,
      latitude: location.latitude,
      longitude: location.longitude,
    })
      .then(() => {
        Swal.fire({
          title: 'Documento assinado',
          text: "Assinatura registrada com sucesso!",
          icon: 'success',
          confirmButtonColor: '#EC582A',
          confirmButtonText: 'Ok'
        })
          .then(() => {
            props.refresh();
          });
      })
      .catch((error) => {
        const result = error.response.data;
        if (result.code !== undefined && result.code === "E017") {
          if (numPad != null) {
            numPad.current.reset();
          }
        }
        handleError(error.response.data);
      })
      .finally(() => {
        setShowProgressSign(false);
      });
  }

  function notSign () {
    Swal.fire({
      title: 'Não assinar o documento',
      text: "Deseja realmente não assinar este documento?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#EC582A',
      cancelButtonColor: '#e53935',
      confirmButtonText: 'Sim, não assinar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        // region not_sign_document
        logEvent(analytics, 'not_sign_document')
        // endregion

        setShowProgressNotSign(true);
        const {document} = props;

        API.post(REFUSED_DOCUMENT, {
          documentID: document.id
        })
          .then(() => {
            showNotification(
              "",
              "Documento não assinado",
              NOTIFICATION_TYPE.success,
              NOTIFICATION_POSITION.center
            )
            props.refresh();
          })
          .catch((error) => {
            handleError(error.response.data)
        }).finally(() => setShowProgressNotSign(false));
      }
    });
  }

  const getLocation = () => {
    setLocationLoading(true)
    navigator.geolocation.getCurrentPosition(function(position) {
        setLocationLoading(false)
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        })
      }, function () {
        setLocationLoading(false)
        showNotification(
          "Localização",
          "Não foi possível obter a sua localização. Por favor, atualize a página.",
          NOTIFICATION_TYPE.warning,
          NOTIFICATION_POSITION.center
        )
      }
    )
  }

  return (
    <>
      {
        loading ?
          <Progress />
          :
          <>
            {
              certificate !== null?
                <>
                  <div>
                    <h6 className="my-3"><strong>Assinar documento</strong></h6>
                    {
                      location != null ?
                        <>
                          <div style={{
                            fontSize: 12,
                            textAlign: 'center',
                            marginBottom: '1rem'
                          }}>
                            <span>Informe sua senha de assinatura</span>
                          </div>
                          <Numpad showNumber={false}
                                  ref={numPad}
                                  sendCode={(code) => setCode(code)}  />

                          <ButtonPrimaryBlock
                            text="Assinar documento"
                            progress={showProgressSign}
                            action={() => sign()} />

                          <hr />

                          <div style={{marginTop: 15}}>
                            <p style={{fontSize: 13, marginBottom: 10}}>
                              Se você não estiver de acordo com o conteúdo do documento, clique no botão abaixo.
                            </p>
                            <button type="button"
                                    className="btn btn-block btn-danger btn-sm"
                                    disabled={showProgressNotSign}
                                    onClick={() => notSign()}>
                              {
                                showProgressNotSign ?
                                  <><i className="fas fa-spinner fa-spin"/></>
                                  :
                                  <>Não assinar o documento</>
                              }
                            </button>
                          </div>
                        </>
                        :
                        <div>
                          <p style={{textAlign: 'center'}} className="description-md">
                            Habilite o acesso à sua localização para maior valor jurídico.
                          </p>

                          <button onClick={() => getLocation()}
                                  disabled={locationLoading}
                                  className="btn btn-assinauai btn-sm btn-block">
                            {
                              !locationLoading ?
                                <><i className="fas fa-map-marked-alt"/> Habilitar localização</>
                                :
                                <><i className="fas fa-spinner fa-spin"/> Obtendo localização</>
                            }
                          </button>
                        </div>
                    }
                  </div>
                </>
                :
                <div style={{textAlign: 'center'}}>
                  <h6>Você ainda não criou a sua senha de assinatura</h6>
                  <p style={{fontSize: '12px', margin: 0}}>Clique no botão abaixo para criar a sua senha de assinatura!</p>
                  <p style={{fontSize: '12px'}}>Será com esta senha que você irá assinar os seus documentos recebidos.</p>
                  <button className="btn btn-assinauai" onClick={() => {
                    const lastLocation = window.location.pathname;
                    history.push(`/criar-senha-assinatura?redirectTo=${lastLocation}`);
                  }}>
                    Criar senha de assinatura
                  </button>
                </div>
            }
          </>
      }
    </>
  );
}

export default SignDocument;
