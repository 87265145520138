import React, { useState, useEffect } from "react";

const queryString = require('query-string');

export default function ForbiddenAccess() {

  const [msgErro, setMsgErro] = useState(null);

  useEffect(() => {
    const { msg } = queryString.parseUrl(window.location.href).query;
    setMsgErro(msg);
  }, []);

  return (
    <div className="error-page">
      <h2 className="headline text-danger"> 403</h2>

      <div className="error-content">
        <h3><i className="fas fa-exclamation-triangle text-danger"/> Acesso proibido</h3>

        {
          msgErro != null ?
            <p>{msgErro}</p>
            :
            <div>
              <p className="description-md">
                Você não possui permissão para acessar esta página,
                entre em contato com o administrador da conta para obter a permissão de acesso.
              </p>
            </div>
        }
      </div>
    </div>
  );

}
