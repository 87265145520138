import React, {useContext, useState} from "react";

import SinglePage from "../../components/template/SinglePage";
import CreatePassword from "../../components/auth/CreatePassword";
import API from "../../services/api";
import {NEW_PASSWORD} from "../../services/authService";
import queryString from "query-string";
import {Context} from "../../routers/Context/AuthContext";
import getTitle from "../../utils/handleError";

export default function RecoveryPasswordPage() {

  const [progress, setProgress] = useState(false)
  const [error, setError] = useState(null)
  const { handleLogin } = useContext(Context)

  function newPassword(password) {
    setError(null)
    const { token } = queryString.parseUrl(window.location.href).query

    setProgress(true)
    API.post(NEW_PASSWORD, {
      password,
      token
    })
      .then((response) => {
        const result = response.data.data;
        handleLogin(result);
      })
      .catch((error) => {
        const result = error.response.data;
        setError({
          title: getTitle(result.code),
          message: result.error
        });
      })
      .finally(() => {
        setProgress(false)
      });
  }

  return (
    <SinglePage>
      <div style={{marginBottom: 15}}>
        <p style={{fontWeight: 700, fontSize: 24, marginBottom: 0}}>Recuperar senha</p>
      </div>
      <CreatePassword
        progress={progress}
        onComplete={ (password) => newPassword(password) }
        error={error} />
    </SinglePage>
  )

}