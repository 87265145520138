import React, {useState} from "react";
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";

export default function StatusInfo() {

  const [showModal, setShowModal] = useState(false)

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Descubra o significado de cada status dos processos
    </Tooltip>
  )

  return(
    <>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}>
        <a onClick={() => setShowModal(true)}
           className="float-right btn-tool" style={{color: "var(--au-orange)", cursor: 'pointer'}}>
          <i className="fas fa-question"/>
        </a>
      </OverlayTrigger>

      <Modal size="sm" show={showModal} centered>
        <Modal.Body>
          <p className="title-md">Descrição dos status</p>

          <ul>
            <li>
              <strong>
                <i className="fas fa-flag" style={{color: "var(--au-yellow)"}}/> Em andamento
              </strong>
              <p>Ainda há assinantes que não assinaram o documento.</p>
            </li>

            <li>
              <strong>
                <i className="fas fa-flag" style={{color: "var(--au-green)"}}/> Concluído
              </strong>
              <p>Todos os assinantes assinaram o documento.</p>
            </li>

            <li>
              <strong>
                <i className="fas fa-flag" style={{color: "var(--au-red)"}}/> Rejeitado
              </strong>
              <p>Há assinantes que decidiram não assinar o documento e o processo de assinatura foi cancelado.</p>
            </li>

            <li>
              <strong>
                <i className="fas fa-flag" style={{color: "var(--au-grey)"}}/> Não assinado
              </strong>
              <p>Processo encerrado e ninguém assinou ou rejeitou o documento.</p>
            </li>

            <li>
              <strong>
                <i className="fas fa-flag" style={{color: "var(--au-black)"}}/> Cancelado
              </strong>
              <p>O processo de assinatura foi cancelado pelo dono do documento.</p>
            </li>
          </ul>

          <button type="button" className="btn btn-assinauai btn-block"
                  onClick={() => setShowModal(false)}>
            continuar
          </button>
        </Modal.Body>
      </Modal>
    </>
  )

}