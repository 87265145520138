import React, {useEffect, useState} from "react";
import {getTokenFCM} from "../../services/firebase-service";
import API from "../../services/api";
import {SAVE_TOKEN} from "../../services/notificationService";

export default function EnableNotifications() {

  const [show, setShow] = useState(false)
  const [savedToken, setSavedToken] = useState(false)
  const [progress, setProgress] = useState(false)

  useEffect(() => {
    generateToken()
  }, [])

  const generateToken = () => {
    if (!("Notification" in window)) {
      return
    }

    setShow(true)

    if (Notification.permission !== "denied") {
      const savedToken = localStorage.getItem("fcm-token")
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          if (savedToken !== "saved") {
            getTokenFCM().then((token) => {
              if (token) saveToken(token)
            })
          } else {
            setSavedToken(true)
          }
        }
      })
    }
  }

  const saveToken = (token) => {
    setProgress(true)
    API.post(SAVE_TOKEN, {
      token
    })
      .then(() => {
        setSavedToken(true)
        localStorage.setItem("fcm-token", "saved")
      })
      .catch(() => {
        localStorage.setItem("fcm-token", "not-saved")
      })
      .finally(() => setProgress(false));
  }

  return (
    <>
      {
        show && !savedToken && !progress ?
          <>
            <p className="description-sm">
              Que tal receber notificações acerca dos status de assinatura dos documentos?
            </p>

            <button className="btn btn-link btn-sm btn-block"
                    onClick={() => generateToken()}>
              <i className="fas fa-bell"/> Ativar notificações
            </button>
          </>
          :
          null
      }
    </>
  )

}