import {Card} from "react-bootstrap";
import {CardBody} from "reactstrap";
import React from "react";
import CreditCard from "../../components/payment/CreditCard";

import "./payment.css"
import Invoices from "../../components/payment/Invoices";
import Plans from "../../components/payment/Plans";
import RegisteredUse from "../../components/payment/RegisteredUse";

export default function Payment() {

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Card className="card-mobile">
            <CardBody>
              <div className="row">
                <div className="col-md-12">
                  <Invoices />
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <CreditCard />
                </div>

                <div className="col-sm-12 col-md-8">
                  <Plans />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <Card className="card-mobile">
            <CardBody>
              <div className="row">
                <div className="col-md-12">
                  <RegisteredUse />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )

}