import React, {useEffect, useState} from "react";

import {Card} from "react-bootstrap";

import DocumentsSent from "../../components/documentos/DocumentsSent";
import API from "../../services/api";
import {DOCUMENTS_SENT} from "../../services/documentService";
import Progress from "../../components/template/Progress";

import "./documents.css"
import DocumentsSentFilter from "../../components/documentos/filter/DocumentsSentFilter";
import ErrorAlert from "../../components/utils/error-alert";
import getTitle from "../../utils/handleError";

export default function DocumentsSentPage(props) {

  const [documents, setDocuments] = useState([])
  const [progress, setProgress] = useState(true)
  const [error, setError] = useState(null)
  const [filter, setFilter] = useState({
    documentName: "",
    status: "",
    startDate: null,
    endDate: null,
    showArchive: false
  })
  const [currentPage, setCurrentPage] = useState({
    page: 1,
    perPage: 10
  })
  const [pagination, setPagination] = useState({
    numberPages: 0,
    currentPage: 0,
    total: 0
  })

  useEffect(() => {
    getDocuments()
  }, [currentPage])

  const getDocuments = () => {
    const { documentName, status, startDate, endDate, showArchive } = filter
    const minDate = startDate !== null ? startDate.toISOString().slice(0, 10) : "";
    const maxDate = endDate !== null ? endDate.toISOString().slice(0, 10) : "";
    let query = `?documentName=${documentName.trim()+status}`;
    if (minDate) {
      query += `&minDate=${minDate}`
    }
    if (maxDate) {
      query += `&maxDate=${maxDate}`
    }
    if (props.contactID !== undefined) {
      query += `&contactID=${props.contactID}`
    }
    query += `&page=${currentPage.page}&perPage=${currentPage.perPage}&showArchive=${showArchive ? 1 : 0}`

    setProgress(true)
    setError(null)
    API.get(DOCUMENTS_SENT+query)
      .then((response) => {
        const documents = response.data.data;
        setDocuments(documents.result);
        setPagination({
          numberPages: documents.numberPages,
          currentPage: documents.currentPage,
          total: documents.total
        });
      })
      .catch((error) => {
        const result = error.response.data;
        setError({
          title: getTitle(result.code),
          message: result.error
        });
      })
      .finally(() => setProgress(false))
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <Card className="card-mobile">
          <Card.Body>
            <DocumentsSentFilter search={(filter) => {
              setFilter(filter)
              setCurrentPage({
                ...currentPage,
                page: 1
              })
            }} />

            {
              progress ?
                <Progress />
                :
                <>
                  {
                    !error ?
                      <DocumentsSent
                        documents={documents}
                        pagination={pagination}
                        currentPage={currentPage}
                        changePage={(page) => {
                          setCurrentPage({
                            ...currentPage,
                            page: page
                          })
                        }}
                        changePerPage={(perPage) => {
                          setCurrentPage({
                            page: 1,
                            perPage: perPage
                          })
                        }}
                      />
                      :
                      <div className="row">
                        <div className="col-sm-12 col-md-4 offset-md-4" style={{marginTop: '1rem'}}>
                          <ErrorAlert error={error} />
                        </div>
                      </div>
                  }
                </>
            }
          </Card.Body>
        </Card>
      </div>
    </div>
  )

}