import React, { useEffect, useState } from "react";

import {Card, CardBody} from "reactstrap";
import API from "../../services/api";
import Progress from "../template/Progress";
import {handleError} from "../../utils/handleError";
import {GET_USERS} from "../../services/usersService";
import PerPage from "../documentos/PerPage";
import ReactPaginate from "react-paginate";
import {parseDateToBR} from "../../utils/dataUtils";
import "./users.css"
import AccountUser from "./AccountUser";
import NewUser from "./NewUser";

export default function AccountUsers () {

  const [progress, setProgress] = useState(false)
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState({page: 1, perPage: 10})
  const [pagination, setPagination] = useState({numberPages: 0, currentPage: 0})

  useEffect(() => {
    getUsers()
  }, [currentPage]);

  const getUsers = () => {
    let query = `?page=${currentPage.page}&perPage=${currentPage.perPage}`

    setProgress(true)
    API.get(GET_USERS+query)
      .then((response) => {
        const pagination = response.data.data;
        setUsers(pagination.result)
        setPagination({
          numberPages: pagination.numberPages,
          currentPage: pagination.currentPage,
          total: pagination.total
        });
      })
      .catch((error) => {
        handleError(error.response.data);
      })
      .finally(() => setProgress(false))
  }

  const changePage = (page) => {
    setCurrentPage({
      ...currentPage,
      page: page
    })
  }

  const getQuantityPermissions = (user) => {
    let permissions = 0;
    user.roles.forEach(role => permissions += role.permissions.length)
    return user.permissions.length + permissions;
  }

  return (
    <>
      <Card className="card-mobile">
        <CardBody>
          {
            progress ?
              <Progress />
              :
              <>
                <div className="d-flex justify-content-between align-items-start">
                  <PerPage
                    total={pagination.total}
                    currentPage={currentPage}
                    changePerPage={(perPage) => {
                      setCurrentPage({
                        page: 1,
                        perPage: perPage
                      })
                    }} />

                  <NewUser onNewUser={(user) => {
                    getUsers()
                    setUser(user)
                  }} />
                </div>

                <div className="table-responsive">
                  <table className="table table-hover table-borderless users-table">
                    <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Chave</th>
                      <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      users.map((user, i) =>
                        <tr key={i} style={{ cursor: "pointer" }} onClick={() => setUser(user)}>
                          <td style={{ minWidth: 100, verticalAlign: "middle" }}>
                            <p className="m-0">{user.name}</p>
                            <p className="m-0 text-muted description-sm">
                              Cadastrado em: {parseDateToBR(user.createdAt)}
                            </p>

                            <p className="m-0 text-muted description-sm">
                              <span style={{color: 'var(--au-orange)'}}>{getQuantityPermissions(user)}</span> permissões habilitadas
                            </p>
                          </td>
                          <td style={{ verticalAlign: "middle"}}>
                          <span style={{color: 'var(--au-orange)', fontWeight: 600}}>
                            {user.key.toUpperCase()}
                          </span>
                          </td>
                          <td style={{ verticalAlign: "middle"}}>
                            {
                              user.active ?
                                <span className="text-muted">
                                <i style={{color: '#43a047'}} className="far fa-check-circle"/> ativo
                              </span>
                                :
                                <span>
                                <i style={{color: '#e53935'}} className="far fa-times-circle"/> desativado
                              </span>
                            }
                          </td>
                        </tr>
                      )
                    }
                    </tbody>
                  </table>
                </div>

                <div className="d-flex justify-content-center" style={{marginTop: '1rem'}}>
                  <ReactPaginate
                    previousLabel={'Anterior'}
                    nextLabel={'Próximo'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pagination.numberPages}
                    forcePage={pagination.currentPage - 1}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(result) => {
                      let selected = result.selected
                      changePage(selected + 1)
                    }}
                    containerClassName={'pagination'}
                    pageClassName={'paginate_button page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'paginate_button page-item previous'}
                    nextClassName={'paginate_button page-item next'}
                    previousLinkClassName={'page-link'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </div>
              </>
          }
        </CardBody>
      </Card>

      <AccountUser
        user={user}
        onClose={() => setUser(null)}
        onUpdate={() => getUsers()} />
    </>
  );

}