import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {Modal, ModalHeader, ModalBody, Card, CardBody} from "reactstrap";
import { store } from "react-notifications-component";

import history from "../routers/History";
import querys from "../querys/usuario/DadosUsuario.js";
import API from "../services/api";

import SenhaAssinatura from "../components/usuario/SenhaAssinatura";
import Terms from "../components/terms/Terms";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../utils/notifications";
import CreatePassword from "../components/auth/CreatePassword";
import {CHANGE_PASSWORD} from "../services/authService";
import {Tab, Tabs} from "react-bootstrap";
import UploadPhoto from "../components/usuario/UploadPhoto";

function ProfilePage() {

  const { data, loading: queryLoading } = useQuery(querys.GET_DADOS_USUARIO);
  const [
    atualizarUsuario,
    {
      data: dadosAtualizados,
      loading: mutationLoading,
      error: mutationError
    }
  ] = useMutation(querys.ATUALIZAR_DADOS_USUARIO);
  const [ usuario, setUsuario ] = useState({});
  const [ errosForm, setErrosForm ] = useState({});
  const [changePassword, setChangePassword] = useState(false);
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState(null)

  useEffect (() => {
    if (!queryLoading && !mutationLoading) {
      setUsuario(data.usuario);
    }

    if (!mutationLoading && (dadosAtualizados !== undefined || mutationError)) {
      if (mutationError === undefined) {
        setUsuario({
          ...usuario,
          ...dadosAtualizados.atualizarUsuario
        });
      } else {
        store.addNotification({
          title: "Ops!",
          message: "Ocorreu um problema ao atualizar os dados, tente novamente mais tarde!",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
      }
    }
  }, [queryLoading, data, mutationLoading, dadosAtualizados]);

  const handlePrimeiroNome = (primeiroNome) => {
    setUsuario({
      ...usuario,
      primeiroNome
    });
  }

  const handleUltimoNome = (ultimoNome) => {
    setUsuario({
      ...usuario,
      ultimoNome
    });
  }

  const handleValidation = () => {
    let formIsValid = true;
    let erros = {};

    if(!usuario.primeiroNome){
      formIsValid = false;
      erros["primeiroNome"] = "O primeiro nome não pode ser vazio.";
    }

    if(!usuario.ultimoNome){
      formIsValid = false;
      erros["ultimoNome"] = "O último nome não pode ser vazio.";
    }

    setErrosForm(erros);
    return formIsValid;
  }

  const atualizarDados = () => {
    if (handleValidation()) {
      atualizarUsuario({ variables: {
          primeiroNome: usuario.primeiroNome,
          ultimoNome: usuario.ultimoNome
        }});
    }
  }

  function handleSubmitNewPassword(password) {
    setUpdatingPassword(true);
    API.post(CHANGE_PASSWORD, {
      password
    })
      .then(() => {
        showNotification(
          "",
          "Senha atualizada!",
          NOTIFICATION_TYPE.success,
          NOTIFICATION_POSITION.topCenter
        )
      })
      .catch((error) => {
        setChangePasswordError(error)
      }).finally(() => {
      setUpdatingPassword(false)
      setChangePassword(false)
    })
  }

  return (
    <>
      {
        usuario != null ?
          <div className="row">
            <div className="col-md-3">
              <Card className="card-mobile">
                <CardBody>
                  <div className="text-center">
                    <UploadPhoto />
                  </div>

                  <h3 style={{marginBottom: '0'}} className="profile-username text-center">{usuario.nomeCompleto}</h3>
                  <p style={{fontSize: '12px'}} className="text-muted text-center">{usuario.email}</p>
                  {
                    !usuario.cadVerificado ?
                      <div className="d-flex justify-content-center flex-column" style={{textAlign: 'center'}}>
                        <p style={{marginBottom: 5}}><i style={{color: '#e53935'}} className="fa fa-id-card"/> Cadastro não verificado</p>
                        <button onClick={() => history.push('/verificar-identidade')} className="btn btn-sm btn-assinauai">Verificar meu cadastro</button>
                      </div>
                      :
                      ''
                  }
                </CardBody>
              </Card>
            </div>
            <div className="col-md-9">
              <Card className="card-mobile">
                <CardBody>
                  <Tabs defaultActiveKey="dadosConta" id="options">
                    <Tab eventKey="dadosConta" title="Dados da conta">
                      <div style={{marginTop: '2rem'}}>
                        <form className="form-horizontal">
                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Primeiro nome</label>
                            <div className="col-sm-10">
                              <input
                                type="text"
                                value={usuario.primeiroNome}
                                onChange={(e) => handlePrimeiroNome(e.target.value)}
                                required
                                className={"form-control " + (errosForm["primeiroNome"] !== undefined ? "is-invalid" : "")}
                                id="primeiroNome" />
                              <div className="input-error">
                                {errosForm["primeiroNome"]}
                              </div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Último nome</label>
                            <div className="col-sm-10">
                              <input
                                type="text"
                                value={usuario.ultimoNome}
                                onChange={(e) => handleUltimoNome(e.target.value)}
                                required
                                className={"form-control " + (errosForm["ultimoNome"] !== undefined ? "is-invalid" : "")}
                                id="ultNome" />
                              <div className="input-error">
                                {errosForm["ultimoNome"]}
                              </div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">E-mail</label>
                            <div className="col-sm-10">
                              <input type="email" value={usuario.email} readOnly={true} className="form-control" id="email" />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">CPF/CNPJ</label>
                            <div className="col-sm-10">
                              <input type="text" value={usuario.documento} readOnly={true} className="form-control" id="documento" />
                            </div>
                          </div>

                          <div className="form-group row">
                            <div className="offset-sm-2 col-sm-10">
                              <button disabled={mutationLoading} onClick={() => atualizarDados()} type="button" className="btn btn-danger">
                                {
                                  mutationLoading ?
                                    <i className="fas fa-spinner fa-spin"/>
                                    :
                                    ''
                                }
                                Alterar dados
                              </button>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Senha</label>
                            <div className="col-sm-10" >
                              <button type="button" onClick={() => setChangePassword(true)}
                                      className="btn btn-link" style={{padding: 0}}>
                                Clique aqui para atualizar a senha
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Tab>
                    <Tab eventKey="senhaAssinatura" title="Senha de assinatura">
                      <div style={{marginTop: '2rem'}}>
                        <SenhaAssinatura />
                      </div>
                    </Tab>
                    <Tab eventKey="termosPoliticas" title="Termos e políticas">
                      <div style={{marginTop: '2rem'}}>
                        <Terms />
                      </div>
                    </Tab>
                  </Tabs>
                </CardBody>
              </Card>
            </div>
          </div>
          : null
      }
      <Modal isOpen={changePassword} size="sm" centered>
        <ModalHeader toggle={() => setChangePassword(false)}>Alterar senha</ModalHeader>
        <ModalBody>
          <CreatePassword
            onComplete={(data) => handleSubmitNewPassword(data)}
            progress={updatingPassword}
            error={changePasswordError} />
        </ModalBody>
      </Modal>
    </>
  );

}

export default ProfilePage;
