import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import API from "../../services/api";
import {GET_PLANS} from "../../services/payment-service";
import {handleError} from "../../utils/handleError";
import Progress from "../template/Progress";
import NewSubscription from "./NewSubscription";

export default function Plans() {

  const [progress, setProgress] = useState(false)
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState(null)
  const [chosenPlan, setChosenPlan] = useState(null)

  useEffect(() => {
    getPlans()
  }, [])

  const getPlans = () => {
    setProgress(true)
    API.get(GET_PLANS)
      .then((response) => {
        const result = response.data.data
        setProducts(result.products)
      }).catch((error) => {
      handleError(error.response.data)
    })
      .finally(() => setProgress(false))
  }

  const convertPrice = (price) => {
    return <p className="m-0">
      {price.nickname} {convertAmount(price.unitAmount)}/<span className="text-muted">{convertInterval(price.interval)}</span>
    </p>
  }

  const convertAmount = (unitAmount) => {
    return unitAmount.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
  }

  const convertInterval = (interval) => {
    switch (interval) {
      case 'year':
        return 'ano'
      default:
        return 'mês'
    }
  }

  return (
    <>
      <p className="description-md text-muted">Planos disponíveis</p>

      {
        progress ?
          <Progress />
          :
          <table className="table table-borderless table-sm cards-table">
            <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Valor</th>
              <th scope="col"/>
            </tr>
            </thead>
            <tbody>
            {
              products.map(product =>
                <tr>
                  <th>
                    <p className="m-0">{product.name}</p>
                    <div>
                      <button className="btn btn-link btn-sm" onClick={() => setProduct(product)}>Ver detalhes</button>
                    </div>
                  </th>
                  <th>
                    {
                      product.prices.map(price =>
                        <>
                          {
                            price.tiersMode === "volume" ?
                              <p className="m-0">{price.nickname}/<span style={{color: 'var(--au-orange)'}}>conforme o uso</span></p>
                              :
                              <p className="m-0">{convertPrice(price)}</p>
                          }
                        </>
                      )
                    }
                  </th>
                  <th>
                    <button
                      onClick={() => setChosenPlan(product)}
                      className="btn btn-default btn-sm">
                      <i className="fas fa-shopping-cart"/>
                    </button>
                  </th>
                </tr>
              )
            }
            </tbody>
          </table>
      }

      <NewSubscription
        plan={chosenPlan}
        onHide={() => setChosenPlan(null)} />

      <Modal show={product} onHide={() => setProduct(null)} size="md" centered>
        <Modal.Body>
          {
            product != null ?
              <>
                <h4 className="text-muted">{product.name}</h4>

                <p className="text-muted">Este plano inclui:</p>
                <ul>
                  <li>
                    {
                      product.metadata.documents !== "unlimited" ?
                        <>{product.metadata.documents} documentos/mês</>
                        :
                        <>Envio de documentos ilimitado</>
                    }
                  </li>
                  <li>{product.metadata.signatory} assinante/documento</li>
                  <li style={{color: "var(--au-orange)"}}>Verificação de identidade dos assinantes</li>
                  <li>{product.metadata.users} usuários</li>
                  <li>{product.metadata.api === 'no' ? 'Sem' : 'Com'} acesso a API de integração</li>
                </ul>

                <p>
                  <span className="text-muted">Valor do plano:</span><br/>
                  {
                    product.prices.map(price =>
                      <>
                        {
                          price.tiersMode === "volume" ?
                            <p className="m-0">{price.nickname}/<span style={{color: 'var(--au-orange)'}}>conforme o uso</span></p>
                            :
                            <p className="m-0">{convertPrice(price)}</p>
                        }
                      </>
                    )
                  }
                </p>
              </>
              :
              null
          }
        </Modal.Body>
      </Modal>
    </>
  )

}