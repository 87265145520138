import React from "react";
import {getAnalytics, logEvent} from "firebase/analytics";

export default function PerPage({total, currentPage, changePerPage}) {

  const analytics = getAnalytics()

  return (
    <div>
      <form className="form-inline" onSubmit={e => { e.preventDefault() }}>
        <div className="form-group mb-3 input-full-sm">
          <select className="form-select form-select-sm"
                  value={currentPage.perPage}
                  onChange={(e) => {
                    const perPage = e.target.value
                    // region change_per_page
                    logEvent(analytics, 'change_per_page', {
                      quantity: perPage
                    });
                    // endregion
                    changePerPage(perPage)
                  }}>
            <option value="10">10 por página</option>
            <option value="25">25 por página</option>
            <option value="50">50 por págnia</option>
          </select>
          <small id="deadlineHelp" className="form-text text-muted">
            Quantidade total: <strong>{total}</strong>
          </small>
        </div>
      </form>
    </div>
  )

}