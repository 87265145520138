import React, {useEffect, useState} from 'react';

import OpenInMobile from "../../../components/liveness/OpenInMobile";
import CheckMobile from "../../../utils/checkMobile";

import logo from "../../../images/logotipo_h.svg";
import background from "../../../images/background_screen_document.png";
import "./photo-document.css";
import DataURIToBlob from "../../../utils/convertToBlog";
import API from "../../../services/api";
import {CHECK_DOCUMENT} from "../../../services/identityService";
import history from "../../../routers/History";
import getTitle from "../../../utils/handleError";
import ErrorAlert from "../../../components/utils/error-alert";
import {Camera} from "react-camera-pro";
import PhotoPreviewIOS from "../../../components/liveness/PhotoPreviewIOS";

export default function DocumentPhotoIOS() {
  let camera = React.useRef(null)

  const [document, setDocument] = useState({
    front: null,
    back: null
  })
  const [preview, setPreview] = useState(null)
  const [showCamera, setShowCamera] = useState(false)
  const [progress, setProgress] = useState(false)
  const [error, setError] = useState(null)
  const checkMobile = CheckMobile()

  useEffect(() => {
    if (document.front != null && document.back != null) {
      checkDocument();
    }
  }, [document])

  const takePicture = () => {
    const imageSrc = camera.current.takePhoto();
    setPreview(imageSrc)
    setShowCamera(false)
  }

  const confirmPhoto = () => {
    if (document.front == null) {
      setDocument({
        ...document,
        front: preview
      });
      setShowCamera(true);
      setPreview(null);
    } else {
      setDocument({
        ...document,
        back: preview
      });
      setShowCamera(false);
      setPreview(null);
    }
  }

  const checkDocument = () => {
    setProgress(true);
    let formData = new FormData();
    let config = { headers: { "content-type": "multipart/form-data" }};
    const frontBlob = DataURIToBlob(document.front);
    const backBlob = DataURIToBlob(document.back);
    formData.append("front", frontBlob);
    formData.append("back", backBlob);

    setDocument({
      front: null,
      back: null
    });

    API.post(CHECK_DOCUMENT, formData, config)
      .then(() => {
        history.push("/criar-senha-assinatura");
      })
      .catch((error) => {
        const result = error.response.data;
        setError({
          title: getTitle(result.code),
          message: result.error
        });
      })
      .finally(() => setProgress(false));
  }

  return (
    <>
      {
        showCamera ?
          <div id="photo-document">
            <div className="overlay-document">
              <img src={background} alt="Máscara de foto do documento"/>
            </div>

            <div className="alert alert-secondary document-side" role="alert">
              {
                document.front == null ?
                  "Frente do documento"
                  :
                  "Verso do documento"
              }
            </div>

            <Camera
              ref={camera}
              facingMode={"environment"}
              errorMessages={{
                noCameraAccessible: 'Câmera não encontrada. Por favor, conecte uma câmera ou tente por outro navegador.',
                permissionDenied: 'Permissão negada. Por favor, atualize a página e permita o acesso a câmera.',
                switchCamera:
                  'Não é possível trocar de câmera, pois não há outro dispositivo conectado.',
                canvas: 'Não foi possível abrir a visualização da câmera, por favor, atualize a página.'
              }} />

            <div className="take-picture">
              <button
                onClick={() => { takePicture() }} className="btn btn-assinauai btn-lg rounded-circle">
                <i className="fas fa-camera"/>
              </button>
            </div>
          </div>
          :
          <>
            {
              !preview && !progress ?
                <div className="container-login">
                  <div style={{marginBottom: '1.5 rem'}} className="login-logo">
                    <img src={logo} alt="Logo Assina uai" width="225"/>
                  </div>
                  <div className="box-panel">
                    <div style={{marginBottom: 15}}>
                      <p className="title-md">Foto do documento</p>
                      <span className="description-md">Precisamos de uma foto do seu documento. Essa é a última etapa.</span>
                    </div>

                    {
                      checkMobile ?
                        <>
                          {
                            !error ?
                              <>
                                <div>
                                  <ul className="liveness-help-ul">
                                    <li><i className="far fa-id-card"/> Tenha em mãos a sua <strong>CNH</strong> ou o seu <strong>RG</strong>.</li>
                                    <li><i className="far fa-lightbulb"/> Encontre um lugar com uma boa iluminação.</li>
                                  </ul>
                                </div>

                                <div className="botao-login">
                                  <button type="button"
                                          onClick={() => { setShowCamera(true); }}
                                          className="btn btn-assinauai btn-block">
                                    Tirar foto
                                  </button>
                                </div>
                              </>
                              :
                              <div>
                                <ErrorAlert error={error} />

                                <div style={{marginTop: '1rem'}} onClick={() => setShowCamera(true)}>
                                  <button className="btn btn-assinauai btn-block">Tentar novamente</button>
                                </div>
                              </div>
                          }
                        </>
                        :
                        <OpenInMobile />
                    }
                  </div>
                </div>
                :
                <>
                  {
                    !progress ?
                      <PhotoPreviewIOS
                        preview={preview}
                        confirm={(result) => {
                          if (result) {
                            confirmPhoto()
                          } else {
                            setShowCamera(true)
                            setPreview(null)
                          }
                        }}/>
                      :
                      <div className="container-login">
                        <div style={{marginBottom: '1.5 rem'}} className="login-logo">
                          <img src={logo} alt="Logo Assina uai" width="225"/>
                        </div>
                        <div className="box-panel">
                          <div style={{marginBottom: 15}}>
                            <p className="title-md">Verificação de documento</p>
                          </div>

                          {
                            progress ?
                              <div>
                                <i className="fas fa-spinner fa-spin"/> Aguarde um momento, estamos verificando seu documento!
                              </div>
                              :
                              <div>
                                <ErrorAlert error={error} />

                                <div style={{marginTop: '1rem'}} >
                                  <button className="btn btn-assinauai btn-block" onClick={() => {
                                    setDocument({
                                      front: null,
                                      back: null
                                    });
                                    setShowCamera(true);
                                  }}>
                                    Tentar novamente
                                  </button>
                                </div>
                              </div>
                          }
                        </div>
                      </div>
                  }
                </>
            }
          </>
      }
    </>
  )
}