import React, {useEffect, useState} from "react";

import {store} from "react-notifications-component";

import history from "../../routers/History";
import API from "../../services/api";

import "../../styles/login.css";
import {useQuery} from "@apollo/client";
import querys from "../../querys/usuario/DadosUsuario";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import SinglePage from "../../components/template/SinglePage";

const queryString = require('query-string');

export default function PrimeiroCertificado () {

  const validationSchema = Yup.object({
    senha: Yup.string()
      .required('O campo senha é obrigatório')
      .min(8, 'A senha deve ter no mínimo 8 caracteres')
      .max(16, 'A senha deve ter no máximo 16 caracteres')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "A senha deve conter no mínimo um caracter minúsculo, um caracter maiúsculo e um número"
      ),
    confirmarSenha: Yup.string()
      .required('O campo confirmar senha é obrigatório')
      .oneOf([Yup.ref('senha'), null], 'As senhas não conferem'),
  });
  const { data, loading: queryLoading } = useQuery(querys.GET_DADOS_USUARIO, {
    fetchPolicy: 'no-cache'
  });
  const [progress, showProgress] = useState(false);
  const initialValues = {
    senha: "",
    confirmarSenha: "",
  };

  useEffect (() => {
    if (!queryLoading) {
      if (!data.usuario.cadVerificado) {
        const lastLocation = window.location.pathname;
        history.push(`/verificar-cadastro?redirectTo=${lastLocation}`);
      }
    }
  }, [queryLoading]);

  async function emitirCertificado(dados) {
    showProgress(true);
    return await API.post("certificados/emitir", dados, {
      'headers': {
        'Authorization': 'Bearer ' + localStorage.getItem('token').replace('"', '')
      }
    }).then(() => {
      store.addNotification({
        title: "Sucesso!",
        message: "Seu certificado já foi emitido e você já pode começar a assinar os seus documentos!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
      return true;
    }).catch(() => {
      store.addNotification({
        title: "Ops!",
        message: "Não foi possível emitir o seu certificado, por favor, tente novamente mais tarde!",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
      return false;
    }).finally(() => showProgress(false));
  }

  const renderError = (message) => <div style={{display: 'block'}} className="invalid-feedback">{message}</div>;

  return (
    <SinglePage>
      <div style={{marginBottom: 15}}>
        <p style={{fontWeight: 700, fontSize: 24, marginBottom: 0}}>Emitir certificado</p>
        <span style={{fontSize: 14}}>Chegou a hora de emitir o seu certificado da Assina Uai!</span>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          const emitiu = await emitirCertificado(values);
          if (emitiu) {
            resetForm();
            const {redirectTo} = queryString.parseUrl(window.location.href).query;
            history.push(redirectTo ? redirectTo : "/documentos");
          }
        }}>
        {(formik) => (
          <Form>
            <div className="form-group">
              <label className="label" htmlFor="senha">
                Senha
              </label>
              <Field
                name="senha"
                type="password"
                className="form-control"
              />
              <ErrorMessage name="senha" render={renderError} />
            </div>

            <div className="form-group">
              <label className="label" htmlFor="confirmarSenha">
                Confirmar senha
              </label>
              <Field
                name="confirmarSenha"
                type="password"
                className="form-control"
              />
              <ErrorMessage name="confirmarSenha" render={renderError} />
            </div>

            <div>
              <button
                type="submit"
                disabled={(!formik.isValid) || progress}
                className="btn btn-assinauai btn-block" >
                { progress ? <i className="fas fa-spinner fa-spin"/> : <i className="fas fa-shield-alt"/> } Emitir certificado
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </SinglePage>
  )
}
