import React, {useContext, useEffect, useState} from "react";

import {ListGroup, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {parseDateToBR} from "../../utils/dataUtils";
import ListaVazia from "./ListaVazia";
import history from "../../routers/History";
import PerPage from "./PerPage";
import ReactPaginate from "react-paginate";
import Checkbox from "../template/Checkbox";
import ButtonPrimary from "../template/buttons/ButtonPrimary";
import API from "../../services/api";
import {ARCHIVE, MARK_AS_READ} from "../../services/documentService";
import {handleError} from "../../utils/handleError";
import {getAnalytics, logEvent} from "firebase/analytics";
import {Context} from "../../routers/Context/AuthContext";

export default function MyDocuments({documents, category, pagination, currentPage, changePage, changePerPage}) {

  const [checkAll, setCheckAll] = useState(false)
  const [checked, setChecked] = useState(new Array(documents.length).fill(false))
  const [archiving, setArchiving] = useState(false)
  const [showButtonArchive, setShowButtonArchive] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [progressMarkAsRead, setProgressMarkAsRead] = useState(false)
  const { darkMode } = useContext(Context)
  const analytics = getAnalytics()

  useEffect(() => {
    checkIsOpen()
  }, [checked])

  const handleCheckAll = () => {
    setChecked(new Array(documents.length).fill(!checkAll));
    setCheckAll(!checkAll);
    setShowButtonArchive(!checkAll)
  }

  const handleCheck = (position) => {
    const aux = checked.map((item, index) =>
      index === position ? !item : item
    );
    setChecked(aux)
    const quantity = aux.filter(function (checked) {return checked === true}).length
    setShowButtonArchive(quantity > 0);
    setCheckAll(quantity === documents.length);
  }

  const checkIsOpen = () => {
    let isOpen = true
    checked.forEach((isChecked, index) => {
      if (isChecked) {
        if (!documents[index].isOpen) {
          isOpen = false
        }
      }
    })
    setIsOpen(isOpen)
  }

  const handleArchive = () => {
    let ids = [];
    checked.forEach((isChecked, index) => {
      if (isChecked) {
        ids.push({
          id: documents[index].id
        })
      }
    })
    archive(ids)
  }

  const markAsRead = () => {
    let ids = [];
    checked.forEach((isChecked, index) => {
      if (isChecked) {
        ids.push({id: documents[index].id})
      }
    })

    // region archive
    logEvent(analytics, 'mark_document_as_read', {
      category: category,
      quantity: ids.length,
      perPage: documents.length
    })
    // endregion

    setProgressMarkAsRead(true)
    API.post(MARK_AS_READ, {
      documents: ids, markAsRead: !isOpen
    })
      .then(() => { changePage(currentPage.page) })
      .catch(error => handleError(error.response.data))
      .finally(() => setProgressMarkAsRead(false))
  }

  const archive = (ids) => {
    // region archive
    logEvent(analytics, 'archive', {
      category: category,
      quantity: ids.length,
      perPage: documents.length
    })
    // endregion

    setArchiving(true)
    API.post(ARCHIVE, {
      documents: ids, archive: true
    })
      .then(() => {
        if ((ids.length === documents.length) && currentPage.page > 1) {
          changePage(currentPage.page - 1)
        } else {
          changePage(currentPage.page)
        }
      })
      .catch((error) => {
        handleError(error.response.data);
      })
      .finally(() => setArchiving(false))
  }

  const openDocument = (document) => {
    const lastLocation = window.location.pathname + window.location.search;
    history.push(`/documentos/${document.id}?redirectTo=${lastLocation}`);
  }

  const mobileVersion = (document) => {
    return (
      <div className="d-flex align-items-center">
        <div>
          {
            document.profilePicture ?
              <div className="profile-img" style={{marginRight: 8}}>
                <img className="img-circle elevation-2"
                     src={document.profilePicture}
                     alt="Foto de perfil" />
              </div>
              :
              null
          }
        </div>
        <div>
          <p style={{color: darkMode ? 'white' : 'var(--au-text-color)'}} className={`${!document.isOpen ? "unread" : ""} m-0`}>
            {document.name}
          </p>
          <p className="description-sm text-muted m-0">
            <span>Enviado por: {document.sentBy}</span>
            <br />
            <span>
              Recebido em: {parseDateToBR(document.createdAt)}
            </span>
          </p>
          {
            document.isArchived ?
              <p className="description-sm text-muted">
                <i className="fa fa-archive" /> documento arquivado
              </p>
              :
              null
          }
          {
            document.signedIn ?
              <p className="signed-in m-0">
                <i className="fa fa-signature"/> Assinado em: {parseDateToBR(document.signedIn)}
              </p>
              :
              null
          }
          {
            document.notSignedIn ?
              <p className="not-signed-in m-0">
                <i className="fa fa-signature"/> Não assinado em: {parseDateToBR(document.notSignedIn)}
              </p>
              :
              null
          }
          {
            document.deadline ?
              <div style={{marginBottom: 4}}>
                <br />
                <p className="deadline text-right">
                  <i className="far fa-clock"/> Prazo: {parseDateToBR(document.deadline)}
                </p>
              </div>
              :
              null
          }
        </div>
      </div>
    )
  }

  return (
    <>
      {
        documents.length > 0 ?
          <>
            <div className="d-flex align-top">
              <PerPage total={pagination.total} currentPage={currentPage} changePerPage={changePerPage} />

              <div className="d-flex justify-content-around">
                {
                  showButtonArchive ?
                    <>
                      <div style={{marginLeft: 4, marginRight: 4}}>
                        <ButtonPrimary
                          text="Arquivar documento(s)"
                          progress={archiving}
                          style="btn-sm"
                          icon="fas fa-archive"
                          action={() => handleArchive()} />
                      </div>

                      <div style={{marginLeft: 4, marginRight: 4}}>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) => (
                            <Tooltip id="button-tooltip" {...props}>
                              {
                                !isOpen ?
                                  "Marcar como lido"
                                  :
                                  "Marcar como não lido"
                              }
                            </Tooltip>
                          )}>
                          <button type="button" className="btn btn-sm btn-default"
                                  disabled={progressMarkAsRead}
                                  onClick={() => markAsRead()}>
                            {
                              progressMarkAsRead ?
                                <><i className="fa fa-spinner fa-spin"/></>
                                :
                                <>
                                  {
                                    !isOpen ?
                                      <i className="fas fa-envelope-open"/>
                                      :
                                      <i className="fas fa-envelope"/>
                                  }
                                </>
                            }
                          </button>
                        </OverlayTrigger>
                      </div>
                    </>
                    :
                    null
                }
              </div>
            </div>

            <div className="d-block d-sm-none">
              <ListGroup>
                {
                  documents.map((document, index) =>
                    <ListGroup.Item key={index} onClick={() => openDocument(document)}>
                      {mobileVersion(document)}
                    </ListGroup.Item>
                  )
                }
              </ListGroup>
            </div>

            <div className="d-none d-sm-block">
              <Table borderless hover className="documents-table">
                <thead>
                <tr>
                  <th style={{verticalAlign: "middle" }}>
                    <Checkbox
                      id="all"
                      action={() => handleCheckAll()}
                      isChecked={checkAll}
                    />
                  </th>
                  <th>Documento</th>
                  <th>Enviado por</th>
                </tr>
                </thead>
                <tbody>
                {
                  documents.map((document, index) =>
                    <tr key={index} style={{cursor: 'pointer'}} onClick={() => openDocument(document)}>
                      <td onClick={(event => event.stopPropagation())}>
                        <Checkbox
                          id={index}
                          isChecked={checked[index]}
                          action={(index) => handleCheck(index)} />
                      </td>
                      <td>
                      <span className={!document.isOpen ? "unread" : ""}>{document.name}</span>
                      {
                        document.deadline !== null ?
                          <p className="deadline">
                            <i className="far fa-clock"/> Prazo: {parseDateToBR(document.deadline)}
                          </p>
                          :
                          null
                      }

                      {
                        document.signedIn ?
                          <p className="signed-in">
                            <i className="fa fa-signature"/> Assinado em: {parseDateToBR(document.signedIn)}
                          </p>
                          :
                          null
                      }

                      {
                        document.notSignedIn ?
                          <p className="not-signed-in">
                            <i className="fa fa-signature"/> Não assinado em: {parseDateToBR(document.notSignedIn)}
                          </p>
                          :
                          null
                      }

                      {
                        document.isArchived ?
                          <p className="description-sm text-muted">
                            <i className="fa fa-archive" /> documento arquivado
                          </p>
                          :
                          null
                      }
                    </td>
                      <td>
                        <div className="d-flex">
                          {
                            document.profilePicture ?
                              <div className="profile-img" style={{marginRight: 8}}>
                                <img className="img-circle elevation-2"
                                     src={document.profilePicture}
                                     alt="Foto de perfil" />
                              </div>
                              :
                              null
                          }

                          <div>
                            <span style={{fontWeight: 600}}>{document.sentBy}</span>
                            <p className="created-at text-muted">
                              <span>Enviado em: {parseDateToBR(document.createdAt)}</span>
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                }
                </tbody>
              </Table>
            </div>

            <div className="documents-pagination">
              <ReactPaginate
                previousLabel={'Anterior'}
                nextLabel={'Próximo'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pagination.numberPages}
                forcePage={pagination.currentPage - 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(result) => {
                  let selected = result.selected;
                  changePage(selected + 1);
                }}
                containerClassName={'pagination'}
                pageClassName={'paginate_button page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'paginate_button page-item previous'}
                nextClassName={'paginate_button page-item next'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>
          </>
          :
          <ListaVazia />
      }
    </>
  )

}