import React, {useEffect, useState} from 'react';

import {Camera} from "react-camera-pro";

import "./selfie.css";
import background from "../../../images/background_selfie_screen.png";
import API from "../../../services/api";
import history from "../../../routers/History";
import getTitle from "../../../utils/handleError";
import {LIVENESS} from "../../../services/identityService";
import ErrorAlert from "../../../components/utils/error-alert";
import DataURIToBlob from "../../../utils/convertToBlog";
import {checkIdentityVerification} from "../../../utils/checkIdentityVerification";
import PhotoPreviewIOS from "../../../components/liveness/PhotoPreviewIOS";
import SinglePage from "../../../components/template/SinglePage";

export default function Seflie() {
  let camera = React.useRef(null)

  const [preview, setPreview] = useState(null)
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    checkIdentityVerification()
  }, []);

  const checkSelfie = (imageFile) => {
    setProgress(true);
    let formData = new FormData();
    let config = { headers: { "content-type": "multipart/form-data" }};
    const imgBlob = DataURIToBlob(imageFile);
    formData.append("selfie", imgBlob);

    API.post(LIVENESS, formData, config)
      .then((response) => {
        const result = response.data.data;
        if (result.isAlive) {
          if (/(android)/i.test(navigator.userAgent)) {
            history.push("/foto-documento")
          } else {
            history.push("/foto-documento-ios")
          }
        } else {
          setError({
            title: "",
            message: "Não foi possível verificar sua identidade, por favor, tente novamente!"
          });
        }
      })
      .catch((error) => {
        const result = error.response.data;
        setError({
          title: getTitle(result.code),
          message: result.error
        });
      })
      .finally(() => setProgress(false));
  }

  const takePicture = () => {
    const imageSrc = camera.current.takePhoto();
    setImage(imageSrc);
    setPreview(imageSrc);
  }

  return (
    <>
      {
        image == null ?
          <div id="selfie">
            <div className="overlay-selfie">
              <img src={background} alt="Máscara de selfie"/>
            </div>

            <Camera
              id="camera-au"
              facingMode={'user'}
              ref={camera}
              errorMessages={{
                noCameraAccessible: 'Câmera não encontrada. Por favor, conecte uma câmera ou tente por outro navegador.',
                permissionDenied: 'Permissão negada. Por favor, atualize a página e permita o acesso a câmera.',
                switchCamera:
                  'Não é possível trocar de câmera, pois não há outro dispositivo conectado.',
                canvas: 'Não foi possível abrir a visualização da câmera, por favor, atualize a página.'
              }} />

            <div className="take-picture">
              <button
                onClick={() => { takePicture() }} className="btn btn-assinauai btn-lg rounded-circle">
                <i className="fas fa-camera"/>
              </button>
            </div>
          </div>
          :
          <>
            {
              !preview ?
                <SinglePage>
                  <div style={{marginBottom: 15}}>
                    <p className="title-md">Verificação de identidade</p>
                  </div>

                  {
                    progress ?
                      <div>
                        <i className="fas fa-spinner fa-spin"/> Aguarde um momento, estamos verificando sua selfie!
                      </div>
                      :
                      <div>
                        <ErrorAlert error={error} />

                        <div style={{marginTop: '1rem'}} onClick={() => setImage(null)}>
                          <button className="btn btn-assinauai btn-block">Tentar novamente</button>
                        </div>
                      </div>
                  }
                </SinglePage>
                :
                <PhotoPreviewIOS
                  preview={preview}
                  confirm={(result) => {
                    if (result) {
                      checkSelfie(image)
                      setPreview(null)
                    } else {
                      setImage(null)
                    }
                  }}/>
            }
          </>
      }
    </>
  )
}