import React from "react";

import "./preview.css";

export default function PhotoPreviewIOS(props) {

  return (
    <div>
      <img src={props.preview} width="100%" height="100%" alt="Foto" />

      <div className="confirm-preview">
        <h3 style={{textAlign: 'center'}}>A foto ficou boa?</h3>
        <div className="d-flex justify-content-center">
          <button onClick={() => { props.confirm(true) }}
                  style={{marginRight: "2rem", borderRadius: 18}} className="btn btn-success">
            Sim
          </button>
          <button onClick={() => { props.confirm(false) }}
                  style={{borderRadius: 18}} className="btn btn-danger">
            Não
          </button>
        </div>
      </div>
    </div>
  )

}