import React from "react";

export default function ButtonPrimary({text, icon, progress, action, style, disabled = false}) {

  return (
    <button className={`btn btn-assinauai ${style}`}
            disabled={progress || disabled}
            onClick={() => action()}>
      {
        progress ?
          <><i className="fa fa-spinner fa-spin"/></>
          :
          <><i className={icon} /> {text}</>
      }
    </button>
  )

}