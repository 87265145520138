import React, {useState} from "react";

import {Modal, ModalBody, ModalHeader} from "reactstrap";
import Avatar from "react-avatar-edit";
import ButtonPrimaryBlock from "../template/buttons/ButtonPrimaryBlock";
import API from "../../services/api";
import {handleError} from "../../utils/handleError";
import {CHANGE_PROFILE_PICTURE} from "../../services/authService";
import DataURIToBlob from "../../utils/convertToBlog";
import ProfilePicture from "./ProfilePicture";

import "./css/upload-photo.css"
import {getAnalytics, logEvent} from "firebase/analytics";

export default function UploadPhoto() {

  const [progress, setProgress] = useState(false)
  const [image, setImage] = useState(null)
  const [showModal, setModalShow] = useState(false)
  const [showButtons, setButtonsShow] = useState(false)
  const analytics = getAnalytics();

  const onCrop = (preview) => {
    setImage(preview)
  }

  const uploadImage = () => {
    let formData = new FormData();
    let config = { headers: { "content-type": "multipart/form-data" }};
    const imgBlob = DataURIToBlob(image);
    formData.append("picture", imgBlob);

    setProgress(true)
    API.post(CHANGE_PROFILE_PICTURE, formData, config)
      .then((response) => {
        logEvent(analytics, 'update_profile_picture');
        const result = response.data.data;
        localStorage.setItem("profile_picture", result.picture)
        setModalShow(false)
        window.location.reload()
      })
      .catch((error) => {
        handleError(error.response.data)
      })
      .finally(() => setProgress(false));
  }

  return (
    <>
      <div className="profilepic" onClick={() => setModalShow(true)}>
        <ProfilePicture isProfile={true} />
        <div className="profilepic__content">
          <span className="profilepic__icon"><i className="fas fa-camera"/></span>
          <span className="profilepic__text">Alterar</span>
        </div>
      </div>

      <Modal isOpen={showModal} centered size="md">
        <ModalHeader toggle={() => {
          logEvent(analytics, 'click_upload_profile_picture')
          setModalShow(false)
        }}>
          Alterar foto de perfil
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column align-items-center">
            <Avatar
              width={250}
              height={250}
              onCrop={onCrop}
              onBeforeFileLoad={() => setButtonsShow(true)}
              label="Escolher imagem"
            />

            {
              showButtons ?
                <div style={{marginTop: '1rem'}}>
                  <ButtonPrimaryBlock
                    text="Alterar foto"
                    progress={progress}
                    action={uploadImage} />
                </div>
                :
                null
            }
          </div>
        </ModalBody>
      </Modal>
    </>
  )

}