import React, {useState} from "react";
import {ListGroup} from "react-bootstrap";
import {validarEmail} from "../../utils/Validacao";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";
import {Tooltip} from "reactstrap";

export default function SendTo({addEmail, removeEmail, emails, addMessage, message}) {

  const [email, setEmail] = useState("")
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const add = () => {
    if (validarEmail(email)) {
      addEmail(email)
      setEmail("")
    } else {
      showNotification(
        "",
        "Informe um e-mail válido",
        NOTIFICATION_TYPE.danger,
        NOTIFICATION_POSITION.center
      )
    }
  }

  const toggle = () => setTooltipOpen(!tooltipOpen)

  return (
    <div>
      <div>
        <label>Enviar cópia para: <i id="email-help" style={{color: "var(--au-blue)"}} className="far fa-question-circle text-sm"/></label>
        <div className={`input-group ${emails.length === 5 ? 'disabled' : ''}`}>
          <input
            id="input-email"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                add()
              }
            }}
            onChange={(e) => setEmail(e.target.value)}
            type="search"
            placeholder="E-mail do destinatário"
            autoComplete="off"
            value={email}
            className="form-control"/>
          <div className="input-group-prepend">
            <button type="button" style={{borderRadius: '0 .25rem .25rem 0'}}
                    onClick={() => add() } className="btn btn-assinauai">
              <i className="fas fa-at"/>
            </button>
          </div>
        </div>
        <span className="text-muted">Limite máximo de 5 e-mails.</span>

        <Tooltip placement="top" isOpen={tooltipOpen} target="email-help" toggle={toggle}>
          Você pode informar uma lista de e-mails para o recebimento do documento assinado.
        </Tooltip>
      </div>

      <div style={{marginTop: '1rem'}}>
        {
          emails.length > 0 ?
            <div>
              <div className="form-group">
                <label className="text-sm">Mensagem aos destinatários:</label>
                <textarea
                  className="form-control"
                  rows="2"
                  value={message}
                  onChange={(e) => addMessage(e.target.value)}/>
              </div>
            </div>
            :
            null
        }

        <ListGroup>
          {
            emails.map((email, index) =>
              <ListGroup.Item key={index}>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="text-sm text-muted m-0">{email}</p>
                  <button className="btn btn-sm btn-link"
                          style={{borderRadius: 32}}
                          onClick={() => removeEmail(index)}>
                    <i className="fas fa-times"/>
                  </button>
                </div>
              </ListGroup.Item>
            )
          }
        </ListGroup>
      </div>
    </div>
  )

}