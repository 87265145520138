const parseDateToBR = (data) => {
  let dia = data.split(" ")[0].split("-").reverse().join("/"),
    hora = data.split(" ")[1] ? data.split(" ")[1] : null;

  if (hora) {
    return `${dia} às ${hora}`;
  }

  return dia;
}

const diferencaEmSegundos = (data) => {
  const startDate = new Date(data);
  const endDate   = new Date();
  return (endDate.getTime() - startDate.getTime()) / 1000;
}

const segundosParaDhms = (segundos) => {
  segundos = Number(segundos);
  var d = Math.floor(segundos / (3600*24));
  var h = Math.floor(segundos % (3600*24) / 3600);
  var m = Math.floor(segundos % 3600 / 60);
  var s = Math.floor(segundos % 60);

  var dDisplay = d > 0 ? d + (d === 1 ? " dia" : " dias") : "";
  var hDisplay = h > 0 && d === 0 ? h + (h === 1 ? " hora" : " horas") : "";
  var mDisplay = m > 0 && (h === 0 && d === 0) ? m + (m === 1 ? " minuto" : " minutos") : "";
  var sDisplay = s > 0 && (m === 0 && h === 0 && d === 0) ? s + (s === 1 ? " segundo" : " segundos") : "";

  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export {
  parseDateToBR,
  segundosParaDhms,
  diferencaEmSegundos
}
