import React from "react";
import Values from "../../components/dashboard/Values";
import Clients from "../../components/dashboard/Clients";

import "./dashboard.css";

export default function Dashboard() {

  return (
    <div className="row">
      <div className="col-md-4 col-sm-12">
        <Values />
      </div>

      <div className="col-md-8 col-sm-12">
        <Clients />
      </div>
    </div>
  )

}