import React, {useContext, useState} from "react";
import {Offcanvas} from "react-bootstrap";
import SendTo from "./SendTo";
import ButtonLinkBlock from "../template/buttons/ButtonLinkBlock";
import {Context} from "../../routers/Context/AuthContext";
import ButtonPrimaryBlock from "../template/buttons/ButtonPrimaryBlock";
import API from "../../services/api";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";
import getTitle from "../../utils/handleError";
import {UPDATE_SEND_TO} from "../../services/documentService";

export default function EditSendTo({documentId, sendTo, onResult}) {

  const [progress, setProgress] = useState(false)
  const [show, setShow] = useState(false)
  const [emails, setEmails] = useState(sendTo.emails)
  const [message, setMessage] = useState(sendTo.message)
  const { darkMode } = useContext(Context)

  const addEmail = (email) => {
    if (!hasEmail(email.trim())) {
      const newEmails = Array.from(emails)
      newEmails.push(email.trim())
      setEmails(newEmails)
    }
  }

  const hasEmail = (email) => {
    return emails.filter((e) => e === email).length === 1
  }

  const removeEmail = (index) => {
    const newEmails = Array.from(emails)
    newEmails.splice(index, 1)
    setEmails(newEmails)
  }

  const updateSendTo = () => {
    setProgress(true);
    API.put(UPDATE_SEND_TO, {
      documentId,
      message,
      emails
    })
      .then(() => {
        showNotification(
          "",
          "Dados atualizados!",
          NOTIFICATION_TYPE.success,
          NOTIFICATION_POSITION.bottomLeft
        )
        onResult({
          emails,
          message
        })
      })
      .catch((error) => {
        const result = error.response.data;
        showNotification(
          getTitle(result),
          result.error,
          NOTIFICATION_TYPE.danger,
          NOTIFICATION_POSITION.center
        )
      })
      .finally(() => setProgress(false));
  }

  return (
    <>
      <ButtonLinkBlock
        text="Enviar cópia para"
        action={() => setShow(true)}
        icon="fas fa-share" />

      <Offcanvas show={show} onHide={() => setShow(false)} key={1} className={`${darkMode ? 'dark-mode' : ''}`} name="end" placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Enviar cópia para</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SendTo
            addEmail={(email) => addEmail(email)}
            removeEmail={(index) => removeEmail(index)}
            emails={emails}
            addMessage={(message) => setMessage(message)}
            message={message}
          />

          {
            emails.length > 0 ?
              <div className="d-flex justify-content-end" style={{marginTop: '1rem'}}>
                <ButtonPrimaryBlock
                  text="Salvar"
                  action={() => updateSendTo()}
                  progress={progress}
                  style="btn-sm" />
              </div>
              :
              null
          }
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )

}