import { createBrowserHistory } from 'history';
import {getAnalytics, logEvent} from "firebase/analytics";

var history = createBrowserHistory();

history.listen((location) => {
  const pagePath = location.pathname + location.search
  const analytics = getAnalytics();
  logEvent(analytics, 'screen_view', {
    firebase_screen: pagePath
  });
});

export default history;