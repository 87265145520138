import React, {useState, useEffect} from "react";

import SelecionarPasta from "../pastas/SelecionarPasta";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import apolloClient from "../../services/apolloClient";
import querys from "../../querys/usuario/Contatos";
import {maskJs} from "mask-js";
import {validarEmail} from "../../utils/Validacao";
import API from "../../services/api";
import {handleError} from "../../utils/handleError";
import {GET_CONTACT} from "../../services/contactService";
import NewContact from "../contatos/NewContact";
import ProfilePicture from "../usuario/ProfilePicture";
import {ListGroup} from "react-bootstrap";

function SignatariosDocumento (props) {

  const [contato, setContato] = useState(null);
  const [pesquisandoContatos, setPesquisandoContatos] = useState(false);
  const [adicionandoContato, setAdicionandoContato] = useState(false);
  const [listaContatos, setListaContatos] = useState([]);
  const [refTypeahead, setRefTypeahead] = useState(null);
  const [email, setEmail] = useState(null)
  const [newContact, setNewContact] = useState(false)

  useEffect (() => {}, [props]);

  const addSignatario = (contato, isGrupo) => {
    props.addSignatario({
      ...contato,
      isGrupo
    });
    refTypeahead.clear();
  }

  const pesquisarContato = (nomeEmail) => {
    setPesquisandoContatos(true);
    apolloClient.query({
      query: querys.GET_CONTATOS,
      variables: {
        nomeEmail: nomeEmail
      },
      fetchPolicy: 'no-cache'
    })
      .then(result => {
        setListaContatos(result.data.usuario.contatos);
      })
      .finally(() => setPesquisandoContatos(false));
  }

  const getByEmail = (email) => {
    if (validarEmail(email)) {
      setAdicionandoContato(true)
      API.get(GET_CONTACT+`?email=${email}`)
        .then((response) => {
          const result = response.data.data;
          addSignatario(result, false)
        }).catch((error) => {
        const result = error.response.data;
        if (result.code === "E006") {
          setEmail(email)
          setNewContact(true)
        } else {
          handleError(error.response.data);
        }
      }).finally(() => {
        setAdicionandoContato(false);
      });
    }
  }

  return (
    <div className="signatory">
      <NewContact
        email={email}
        showButton={false}
        open={newContact}
        newContact={(contact) =>  {
          setNewContact(false)
          addSignatario(contact, false)
          setEmail(null)
        }}/>

      <div>
        <p><strong>Assinantes do documento</strong></p>
      </div>

      <div className={`row ${adicionandoContato ? 'disabled' : ''}`}>
        <div style={{marginBottom: '1rem'}}>
          <label htmlFor="input-emailAssinantes">
            <i className="fas fa-users"/> Pesquisar contato: <span className="au-red">*</span>
          </label>
          <div className="d-flex">
            <AsyncTypeahead
              id="typeaheadContacts"
              style={{width: '100%', marginRight: 8}}
              placeholder="Pesquise pelo nome ou e-mail do contato"
              emptyLabel="Contato não encontrado"
              promptText="Contato não encontrado"
              searchText="Procurando..."
              ref={(ref) => {setRefTypeahead(ref)}}
              labelKey={option => `${option.nome.trim().length > 0 ? option.nome : 'Sem nome cadastrado'} | ${option.email ? option.email : option.telefone}`}
              useCache={false}
              isLoading={pesquisandoContatos}
              onSearch={(query) => {
                pesquisarContato(query);
              }}
              onChange={(selected) => {
                addSignatario({
                  ...selected[0],
                  name: selected[0].nome
                }, false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  getByEmail(refTypeahead.valueOf().state.text.trim())
                }
              }}
              options={listaContatos}
            />
            <div>
              <button
                className="btn btn-assinauai"
                type="button"
                onClick={() => {
                  getByEmail(refTypeahead.valueOf().state.text.trim())
                }}>
                <i className="fas fa-user-plus"/>
              </button>
            </div>
          </div>
          <small className="form-text text-muted">
            Pesquise ou informe o e-mail do assinante.
          </small>
        </div>
      </div>

      {
        adicionandoContato ?
          <div className="progress-materializecss">
            <div className="indeterminate"/>
          </div>
          :
          null
      }

      {
        props.signatarios.length > 0 ?
          <div style={{marginTop: "1rem"}}>
            <div className="row">
              <div className="col-md-12">
                <ListGroup>
                  {
                    props.signatarios.map((assinante, index) =>
                      <ListGroup.Item key={index}>
                        {
                          !assinante.isGrupo ?
                            <div className="user-panel d-flex align-items-center">
                              <div className="image">
                                <ProfilePicture isProfile={false} contactId={assinante.id} />
                              </div>
                              <div className="d-flex justify-content-between align-items-center"
                                   style={{width: '100%', paddingLeft: 16, paddingRight: 8}}>
                                <div>
                                  <p className="text-md text-muted m-0">
                                    <strong>
                                      {
                                        assinante.name != null && assinante.name.trim().length > 0 ?
                                          assinante.name.trim() : 'Sem nome cadastrado'
                                      }
                                    </strong>
                                  </p>
                                  <p className="text-sm text-muted m-0">{assinante.email}</p>
                                  <p className="text-sm text-muted m-0">{maskJs("(99) 9 9999-9999", assinante.telefone)}</p>
                                </div>

                                <div>
                                  <button className="btn btn-sm btn-link"
                                          style={{borderRadius: 32}}
                                          onClick={() => props.rmSignatario(index)}>
                                    <i className="fas fa-times"/>
                                  </button>
                                </div>
                              </div>
                            </div>
                            :
                            null
                        }
                      </ListGroup.Item>
                    )
                  }
                </ListGroup>
              </div>
              {
                props.signatarios.map(() => {}
                  // <div key={i} className="col-sm-12">
                  //   <div className="card">
                  //     <div className="card-body" style={{padding: 12}}>
                  //       {
                  //         !assinante.isGrupo ?
                  //           <div style={{alignItems: 'center', marginBottom: '1rem'}} className="user-panel d-flex">
                  //             <div className="image">
                  //               <ProfilePicture isProfile={false} contactId={assinante.id} />
                  //             </div>
                  //             <div className="info">
                  //               <div style={{textDecoration: 'none', marginTop: '4px'}} className="d-block">
                  //                 <p style={{margin: 0}}><strong>{assinante.name != null ? assinante.name.trim() : 'Sem nome cadastrado'}</strong></p>
                  //                 <p className="text-sm text-muted">{assinante.email}</p>
                  //                 <p className="text-sm text-muted">{maskJs("(99) 9 9999-9999", assinante.telefone)}</p>
                  //               </div>
                  //             </div>
                  //           </div>
                  //           :
                  //           <div style={{alignItems: 'center', marginBottom: '1rem'}} className="user-panel d-flex">
                  //             <div className="info">
                  //               <div style={{color: '#141414', textDecoration: 'none', marginTop: '4px'}} className="d-block">
                  //                 <p style={{margin: 0}}>
                  //                   <i style={{color: '#F1581B'}} className="fas fa-users"/> <strong>{assinante.nome.trim()}</strong>
                  //                 </p>
                  //                 <p style={{margin: 0, fontSize: 12, color: 'rgba(0,0,0,.55)'}}>{assinante.qtdContatos} contatos</p>
                  //               </div>
                  //             </div>
                  //           </div>
                  //       }
                  //
                  //       <div className="d-flex justify-content-end">
                  //         <a style={{textDecoration: 'none', fontSize: 12}} href="#" onClick={() => props.rmSignatario(i)} className="card-link">
                  //           <i style={{color: "#dc3545"}} className="fa fa-trash"/> Remover assinante
                  //         </a>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                )
              }
            </div>
          </div>
          : null
      }

      <SelecionarPasta
        contato={contato}
        exibirCanvas={contato}
        selecionarPasta={(pasta) => {
          setContato(null);
          props.vincularPasta(contato, pasta);
        }}
        setExibirCanvas={() => setContato(null)}
      />
    </div>
  );
}

export default SignatariosDocumento;
