import React, { useState } from "react";

import {ErrorMessage, Field, Form, Formik} from "formik";

import API from "../../services/api";
import * as Yup from "yup";
import {renderError} from "../../components/utils/InvalidInput";
import history from "../../routers/History";
import SinglePage from "../../components/template/SinglePage";
import {FORGOT_PASSWORD} from "../../services/authService";
import getTitle from "../../utils/handleError";
import ErrorAlert from "../../components/utils/error-alert";

export default function ForgotPasswordPage() {

  const [inProgress, setProgress] = useState(false)
  const [linkSent, setLinkSent] = useState(false)
  const [error, setError] = useState(null)

  const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .required('O campo e-mail é obrigatório')
  });
  const initialValues = {
    email: ""
  }

  async function sendPasswordChangeLink (data) {
    setError(null)
    setProgress(true)
    await API.post(FORGOT_PASSWORD, data)
      .then(() => {
        setLinkSent(true)
      })
      .catch((error) => {
        const result = error.response.data
        setError({
          title: getTitle(result.code),
          message: result.error
        })
      })
      .finally(() => {
        setProgress(false)
      })
  }

  return (
    <SinglePage>
      <div>
        {
          !linkSent ?
            <>
              <div style={{marginBottom: 15}}>
                <p style={{fontWeight: 700, fontSize: 24, marginBottom: 0}}>Esqueci minha senha</p>
              </div>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                  await sendPasswordChangeLink({
                    email: values.email.trim()
                  });
                }}>
                {(formik) => (
                  <Form>
                    <ErrorAlert error={error} close={() => setError(null)}/>

                    <div className="form-group">
                      <label>E-mail: <span className="au-red">*</span></label>
                      <Field type="text" name="email" className="form-control" placeholder="E-mail" />
                      <ErrorMessage name="email" render={renderError} />
                    </div>

                    <div className="botao-login">
                      <button type="submit" disabled={!formik.isValid || inProgress} className="btn btn-assinauai btn-block">
                        { inProgress ? <span><i className="fa fa-spinner fa-spin"/></span> : 'Continuar' }
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div style={{fontSize: 14, marginTop: 16}}>
                <p>Lembrou da senha?</p>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={() => history.push("/login")}>
                  <i className="fas fa-arrow-left"/> Acessar minha conta
                </button>
              </div>
            </>
            :
            <div>
              <div style={{marginBottom: 15}}>
                <p className="title-md">Link enviado!</p>
                <span className="description-md">
                  <strong>Cheque seu e-mail.</strong><br /> Acabamos de enviar a você um link para criar uma nova senha de acesso.
                </span>

                <p className="description-sm-center description-top">
                  <i className="fas fa-exclamation-triangle au-red"/> Verifique se o e-mail chegou na sua caixa de spam.
                </p>
              </div>

              <button
                type="button"
                className="btn btn-assinauai btn-block"
                onClick={() => setLinkSent(false)}>
                Tentar com outro e-mail
              </button>
            </div>
        }


      </div>
    </SinglePage>
  )

}