import React, {useEffect, useState} from 'react';

import "./photo-document.css";
import PhotoPreview from "../../../components/liveness/PhotoPreview";
import API from "../../../services/api";
import {CHECK_DOCUMENT} from "../../../services/identityService";
import history from "../../../routers/History";
import getTitle from "../../../utils/handleError";
import ErrorAlert from "../../../components/utils/error-alert";
import {checkIdentityVerification} from "../../../utils/checkIdentityVerification";
import SinglePage from "../../../components/template/SinglePage";

export default function FotoDocumento() {
  let camera = React.useRef(null)

  const [document, setDocument] = useState({
    front: null,
    back: null
  })
  const [preview, setPreview] = useState(null)
  const [progress, setProgress] = useState(false)
  const [error, setError] = useState(null)
  const [verifiedIdentity, setVerifiedIdentity] = useState(false)

  useEffect(() => {
    if (document.front != null && document.back != null) {
      checkDocument();
    }

    if (!verifiedIdentity) {
      setVerifiedIdentity(true)
      checkIdentityVerification()
    }
  }, [document])

  const confirmPhoto = () => {
    if (document.front == null) {
      setDocument({
        ...document,
        front: preview
      });
      setPreview(null);
    } else {
      setDocument({
        ...document,
        back: preview
      });
      setPreview(null);
    }
  }

  const checkDocument = async () => {
    setProgress(true);
    let formData = new FormData();
    let config = {headers: {"content-type": "multipart/form-data"}};
    let frontBlob = await fetch(document.front).then(r => r.blob())
    const backBlob = await fetch(document.back).then(r => r.blob())
    formData.append("front", frontBlob)
    formData.append("back", backBlob)

    setDocument({
      front: null,
      back: null
    });

    API.post(CHECK_DOCUMENT, formData, config)
      .then(() => {
        history.push("/criar-senha-assinatura");
      })
      .catch((error) => {
        const result = error.response.data;
        setError({
          title: getTitle(result.code),
          message: result.error
        });
      })
      .finally(() => setProgress(false));
  }

  const takePicture = () => {
    return (
      <>
        <div style={{marginBottom: 15}}>
          <p className="title-md">Foto do documento</p>
          <span className="description-md">
            Precisamos de uma foto
            <strong style={{color: "var(--au-orange)"}}> {document.front == null ? "da frente" : "do verso" } </strong>
            do seu documento (RG ou CNH).
          </span>
        </div>

        <div>
          <ul className="liveness-help-ul">
            <li><i className="far fa-id-card"/> Tenha em mãos a sua <strong>CNH</strong> ou o seu <strong>RG</strong>.</li>
            <li><i className="far fa-lightbulb"/> Encontre um lugar com uma boa iluminação.</li>
            <hr />
            <span><strong>Fotos realizadas</strong></span>
            <p>
              frente: <i style={{color: document.front ? "var(--au-green)" : "var(--au-yellow)"}} className="far fa-check-circle"/>
              <span> | </span>
              verso: <i style={{color: document.back ? "var(--au-green)" : "var(--au-yellow)"}} className="far fa-check-circle"/>
            </p>
          </ul>
        </div>

        <input
          id="cameraFileInput"
          type="file"
          accept="image/*"
          capture="environment"
          ref={camera}
          style={{display: 'none'}}
          onChange={(e) => setPreview(window.URL.createObjectURL(e.target.files[0])) }/>

        <button type="button" onClick={() => { camera.current.click() }}
                className="btn btn-assinauai btn-block">
          Abrir câmera
        </button>
      </>
    )
  }

  const checking = () => {
    return (
      <>
        <div style={{marginBottom: 15}}>
          <p className="title-md">Verificação de documento</p>
        </div>

        {
          progress ?
            <div>
              <p className="m-0"><i className="fas fa-spinner fa-spin"/> aguarde um momento, estamos verificando seu documento!</p>
              <p className="description-sm" style={{marginTop: 8}}>
                <i style={{color: "var(--au-blue)"}} className="fas fa-info"/> Pode demorar um pouco.
              </p>
            </div>
            :
            null
        }

        {
          error ?
            <div>
              <ErrorAlert error={error} />

              <div style={{marginTop: '1rem'}} >
                <button className="btn btn-assinauai btn-block" onClick={() => {
                  setDocument({front: null, back: null});
                  setError(null)
                }}>
                  Tentar novamente
                </button>
              </div>
            </div>
            :
            null
        }
      </>
    )
  }

  return (
    <SinglePage>
      {
        preview == null ?
          <>
            {
              !progress && error == null ?
                takePicture()
                :
                checking()
            }
          </>
          :
          <PhotoPreview
            preview={preview}
            confirm={(result) => {
              if (result) {
                confirmPhoto()
              } else {
                setPreview(null)
              }
            }}/>
      }
    </SinglePage>
  )
}