import { NavLink } from "react-router-dom";

import $ from 'jquery';

import logotipoHorizontal from "../../images/logotipo_h.svg";
import {useContext, useEffect, useState} from "react";
import useStorage from "../../utils/useStorage";
import ProfilePicture from "../usuario/ProfilePicture";
import {Context} from "../../routers/Context/AuthContext";
import {isAuthorized, PERMISSIONS} from "../../utils/permission/checkPermission";

function Menu (props) {

  const user = useStorage('user')
  const [name, setName] = useState("")
  const { darkMode } = useContext(Context)

  useEffect(() => {
    setName(user[0].firstName);
  }, [])

  function closeMenu () {
    if (window.innerWidth <= 776 || $('body').hasClass("sidebar-mini layout-fixed sidebar-open")) {
      $("#sidebar-overlay").trigger("click");
    }
  }

  // function isPJ() {
  //   if (localStorage.getItem('guard') !== "collaborator") {
  //     const user = JSON.parse(localStorage.getItem('user'));
  //     return user.type === "JURIDICAL_PERSON";
  //   }
  //   return false;
  // }

  return (
    <aside className={`main-sidebar elevation-4 ${darkMode ? 'sidebar-dark-fuchsia' : 'sidebar-light-white'}`}>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10}}>
        <img src={logotipoHorizontal} style={{height: 30, marginTop: 15}} alt="Logotipo Assina Uai" className="brand-text" />
      </div>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <ProfilePicture isProfile={false} />
          </div>
          <div className="info">
            <NavLink to="/conta"
                     onClick={() => closeMenu()}
                     style={{color: darkMode ? "#fff" : "#141414", textDecoration: 'none', marginTop: 4}}
                     className="d-block">
              <strong>Olá, {name}!</strong>
            </NavLink>
          </div>
        </div>

        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

            {
              isAuthorized(PERMISSIONS.documents) ?
                <>
                  <li style={{marginBottom: '10px'}} className="nav-item new-document">
                    <NavLink
                      type="button"
                      to="/novo-documento"
                      className="nav-link"
                      onClick={() => closeMenu()}
                      style={{backgroundColor: "#f1581b", boxShadow: "0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)"}}>
                      <div style={{display: "flex", alignItems: "center", color: "#fff"}}>
                        <i className="nav-icon fa fa-plus"/>
                        <p>Novo documento</p>
                      </div>
                    </NavLink>
                  </li>

                  <li className="nav-item" style={{marginTop: 5}}>
                    {
                      props.match.url === "/documentos" ?
                        <a
                          href="/documentos"
                          className="nav-link active"
                          onClick={() => closeMenu()}>
                          <div style={{display: "flex", alignItems: "center"}}>
                            <i className="nav-icon fas fa-file-pdf"/>
                            <p>Documentos recebidos</p>
                          </div>
                        </a>
                        :
                        <NavLink
                          to="/documentos"
                          className="nav-link"
                          onClick={() => closeMenu()}
                          activeClassName="active">
                          <div style={{display: "flex", alignItems: "center"}}>
                            <i className="nav-icon fas fa-file-pdf"/>
                            <p>Documentos recebidos</p>
                          </div>
                        </NavLink>
                    }
                  </li>

                  <li className="nav-item documents-sent" style={{marginTop: 5}}>
                    <NavLink
                      to="/documentos-enviados"
                      className="nav-link"
                      onClick={() => closeMenu()}
                      activeClassName="active">
                      <div style={{display: "flex", alignItems: "center"}}>
                        <i className="nav-icon fas fa-file-upload"/>
                        <p>Documentos enviados</p>
                      </div>
                    </NavLink>
                  </li>
                </>
                :
                null
            }

            {/*<li className="nav-item my-folders" style={{marginTop: 5}}>*/}
            {/*  <NavLink*/}
            {/*    to="/pastas"*/}
            {/*    className="nav-link"*/}
            {/*    onClick={() => fecharMenu()}*/}
            {/*    activeClassName="active">*/}
            {/*    <div style={{display: "flex", alignItems: "center"}}>*/}
            {/*      <i className="nav-icon fa fa-folder"/>*/}
            {/*      <p>Minhas pastas</p>*/}
            {/*    </div>*/}
            {/*  </NavLink>*/}
            {/*</li>*/}

            {
              isAuthorized(PERMISSIONS.contacts) ?
                <li className="nav-item my-contacts" style={{marginTop: 5}}>
                  <NavLink
                    to="/contatos"
                    className="nav-link"
                    onClick={() => closeMenu()}
                    activeClassName="active">
                    <div style={{display: "flex", alignItems: "center", color: darkMode ? 'var(--au-grey2)' : 'var(--au-text-color)'}}>
                      <i className="nav-icon fas fa-address-book"/>
                      <p>Contatos</p>
                    </div>
                  </NavLink>
                </li>
                : null
            }

            <li className="nav-item verify-authenticity" style={{marginTop: 5}}>
              <NavLink
                to="/verificar-autenticidade"
                target="_blank"
                className="nav-link"
                onClick={() => closeMenu()}
                activeClassName="active">
                <div style={{display: "flex", alignItems: "center"}}>
                  <i className="nav-icon fas fa-file-signature"/>
                  <p>Verificar autenticidade</p>
                </div>
              </NavLink>
            </li>

            {
              isAuthorized(PERMISSIONS.collaborators) || isAuthorized(PERMISSIONS.payment) ?
                <li style={{color: '#888'}} className="nav-header">OPÇÕES DA CONTA</li>
                :
                null
            }

            {
              isAuthorized(PERMISSIONS.collaborators) ?
                <li className="nav-item" style={{marginTop: 5}}>
                  <NavLink
                    to="/usuarios"
                    className="nav-link"
                    onClick={() => closeMenu()}
                    activeClassName="active">
                    <div style={{display: "flex", alignItems: "center"}}>
                      <i className="nav-icon fas fa-users-cog"/>
                      <p>Usuários</p>
                    </div>
                  </NavLink>
                </li>
                : null
            }

            {
              isAuthorized(PERMISSIONS.payment) ?
                <li className="nav-item monthly-payment" style={{marginTop: 5}}>
                  <NavLink
                    to="/pagamento"
                    className="nav-link"
                    onClick={() => closeMenu()}
                    activeClassName="active">
                    <div style={{display: "flex", alignItems: "center"}}>
                      <i className="nav-icon far fa-credit-card"/>
                      <p>Pagamento</p>
                    </div>
                  </NavLink>
                </li>
                : null
            }
          </ul>
        </nav>
      </div>
    </aside>
  );
}

export default Menu;
