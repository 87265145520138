import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import React, {useState} from "react";
import ButtonPrimary from "../template/buttons/ButtonPrimary";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import API from "../../services/api";
import {handleError} from "../../utils/handleError";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";
import {NEW_USER} from "../../services/usersService";

export default function NewUser({onNewUser}) {

  const [progress, setProgress] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [show, setShow] = useState(false)
  const validationSchema = Yup.object({
    name: Yup.string().trim()
      .required('O campo nome é obrigatório'),
    email: Yup.string().trim()
      .required('O campo e-mail é obrigatório')
      .email('Informe um e-mail válido'),
    password: Yup.string().trim()
      .required('O campo senha é obrigatório')
      .min(8, 'A senha deve ter no mínimo 8 caracteres')
      .max(16, 'A senha deve ter no máximo 16 caracteres')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "A senha deve conter no mínimo um caracter minúsculo, um caracter maiúsculo e um número"
      )
  });
  const initialValues = {
    name: "",
    email: "",
    password: ""
  };

  const renderError = (message) => <div style={{display: 'block'}} className="invalid-feedback">{message}</div>;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {!showPassword ? "Exibir senha" : "Ocultar senha"}
    </Tooltip>
  );

  const saveUser = (data) => {
    setProgress(true)
    API.post(NEW_USER, data).then((response) => {
      showNotification(
        "",
        "Usuário adicionado!",
        NOTIFICATION_TYPE.success,
        NOTIFICATION_POSITION.bottomLeft
      )
      const user = response.data.data;
      onNewUser(user)
    })
      .catch((error) => {
        const result = error.response.data;
        handleError(result)
      })
      .finally(() => setProgress(false));
  }

  return (
    <>
      <ButtonPrimary
        text="Adicionar usuário"
        style="btn-sm"
        icon="fas fa-user-plus"
        action={() => setShow(true)}/>

      <Modal
        show={show}
        size="md"
        centered={true}
        onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Novo usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, {}) => {
              saveUser(values)
            }}>
            {(formik) => (
              <Form>
                <div className="form-group">
                  <label>Nome: <span className="au-red">*</span></label>
                  <Field type="text" name="name" className="form-control" />
                  <ErrorMessage name="name" render={renderError} />
                </div>

                <div className="form-group">
                  <label>E-mail: <span className="au-red">*</span></label>
                  <Field type="text" name="email" className="form-control" />
                  <ErrorMessage name="email" render={renderError} />
                </div>

                <div style={{marginBottom: '1rem'}}>
                  <label>Senha: <span className="au-red">*</span></label>
                  <div className="input-group">
                    <Field type={showPassword ? 'text' : 'password'} name="password" className="form-control" />
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}>
                      <div className="input-group-append">
                        <span className="input-group-text" style={{cursor: 'pointer'}} onClick={() => setShowPassword(!showPassword)}>
                          { !showPassword ? <i className="far fa-eye"/> : <i className="far fa-eye-slash"/> }
                        </span>
                      </div>
                    </OverlayTrigger>
                    <ErrorMessage name="password" render={renderError} />
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <ButtonPrimary
                    text="Adicionar usuário"
                    action={() => formik.submitForm()}
                    disabled={!formik.isValid}
                    progress={progress} />
                </div>

              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )

}