import React, {useContext, useEffect} from "react";
import {Context} from "../../routers/Context/AuthContext";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export default function DarkMode() {

  const { darkMode, setDarkMode, setSettings } = useContext(Context)

  useEffect(() => {
    if (darkMode) {
      setSettings({darkMode: true})
      document.body.classList.add('dark-mode')
    } else {
      setSettings({darkMode: false})
      document.body.classList.remove('dark-mode')
    }
  }, [darkMode])

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {darkMode ? "Modo claro" : "Modo escuro"}
    </Tooltip>
  )

  return (
    <OverlayTrigger
      placement="left"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}>
      <li className="nav-item" style={{cursor: 'pointer'}} onClick={() => {setDarkMode(!darkMode)}}>
        <a className="nav-link">
          {
            darkMode ?
              <i className="far fa-lightbulb"/>
              :
              <i className="far fa-moon"/>
          }
        </a>
      </li>
    </OverlayTrigger>
  )

}