import React, {useEffect, useState} from "react";
import Progress from "../template/Progress";
import API from "../../services/api";
import {handleError} from "../../utils/handleError";
import {GET_CARDS, REMOVE_CARD} from "../../services/payment-service";
import NewCreditCard from "./NewCreditCard";
import {Modal} from "react-bootstrap";
import ButtonPrimary from "../template/buttons/ButtonPrimary";
import ButtonLinkBlock from "../template/buttons/ButtonLinkBlock";
import pad from "../../utils/credit-card";

export default function CreditCard({showCheckbox, setPaymentMethod}) {

  const [progress, setProgress] = useState(true)
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [progressDelete, setProgressDelete] = useState(false)
  const [cards, setCards] = useState([])
  const [cardId, setCardId] = useState(null)

  useEffect(() => {
    getCards()
  }, [])

  const getCards = () => {
    setProgress(true)
    API.get(GET_CARDS)
      .then((response) => {
        const result = response.data.data
        setCards(result.cards)
      }).catch((error) => {
      handleError(error.response.data)
    })
      .finally(() => setProgress(false))
  }

  const removeCard = () => {
    setProgressDelete(true)
    API.delete(REMOVE_CARD+cardId)
      .then(() => {
        setOpenDeleteConfirmation(false)
        getCards()
      }).catch((error) => {
      handleError(error.response.data)
    })
      .finally(() => setProgressDelete(false))
  }

  const renderBrand = (brand) => {
    switch (brand) {
      case "visa":
        return <i className="fab fa-cc-visa"/>
      case "mastercard":
        return <i className="fab fa-cc-mastercard"/>
      default:
        return <i className="far fa-credit-card"/>
    }
  }

  return (
    <>
      <p className="description-md text-muted">
        {
          !showCheckbox ? "Cartões cadastrados" : "Com qual cartão você gostaria de iniciar uma nova assinatura?"
        }
      </p>

      {
        progress ?
          <Progress />
          :
          <>
            {
              cards.length > 0 ?
                <table className="table table-borderless table-sm cards-table">
                  <thead>
                  <tr>
                    <th scope="col">Bandeira</th>
                    <th scope="col">Últimos 4 dígitos</th>
                    <th scope="col">Expiração</th>
                    <th scope="col"/>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    cards.map((card) =>
                      <tr>
                        <th style={{fontSize: 18}}>{renderBrand(card.brand)}</th>
                        <th>{card.last4}</th>
                        <th>{pad(card.expMonth, 2)}/{card.expYear}</th>
                        <th>
                          {
                            !showCheckbox ?
                              <button onClick={() => {
                                setCardId(card.id)
                                setOpenDeleteConfirmation(true)
                              }} className="btn btn-link btn-sm">
                                <i style={{color: 'var(--au-red)'}} className="far fa-trash-alt"/>
                              </button>
                              :
                              <ButtonLinkBlock
                                text="Selecionar cartão"
                                action={() => setPaymentMethod(card)}/>
                          }
                        </th>
                      </tr>
                    )
                  }
                  </tbody>
                </table>
                :
                <div className="d-flex justify-content-center">
                  <p className="description-md">
                    Você ainda não possui cartões cadastrados.
                  </p>
                </div>
            }

            <NewCreditCard refreshCards={() => getCards()} />

            <Modal size="sm" show={openDeleteConfirmation} centered>
              <Modal.Body>
                <p className="description-md text-muted">
                  Você tem certeza de que deseja remover este cartão?
                </p>

                <div className={`${progressDelete ? 'disabled' : ''}`}>
                  <div style={{marginBottom: 8}}>
                    <ButtonPrimary
                      text="Sim, remover cartão!"
                      style="btn-block btn-sm"
                      action={() => removeCard()}
                      progress={progressDelete} />
                  </div>

                  <ButtonLinkBlock
                    text="Não, cancelar!"
                    action={() => setOpenDeleteConfirmation(false)} />
                </div>
              </Modal.Body>
            </Modal>
          </>
      }
    </>
  )

}