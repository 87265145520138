import {Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import UserPermissions from "./UserPermissions";
import Switch from "react-switch";
import API from "../../services/api";
import getTitle, {handleError} from "../../utils/handleError";
import {NEW_PASSWORD, UPDATE_STATUS} from "../../services/usersService";
import CreatePassword from "../auth/CreatePassword";
import ButtonLink from "../template/buttons/ButtonLink";
import showNotification, {NOTIFICATION_POSITION, NOTIFICATION_TYPE} from "../../utils/notifications";

export default function AccountUser({user, onClose, onUpdate}) {

  const [progress, setProgress] = useState(false)
  const [active, setActive] = useState(false)
  const [updatePassword, setUpdatePassword] = useState(false)
  const [progressPassword, setProgressPassword] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (user) {
      setActive(user.active)
    }
  }, [user])

  const updateStatus = () => {
    setProgress(true);
    API.put(UPDATE_STATUS, {
      collaboratorId: user.id,
      status: !active
    })
      .then(() => { onUpdate() })
      .catch((error) => {
        handleError(error.response.data)
        setActive(!active)
      })
      .finally(() => setProgress(false))

    setActive(!active)
  }

  const newPassword = (password) => {
    setProgressPassword(true);
    API.put(NEW_PASSWORD, {
      collaboratorId: user.id,
      password
    })
      .then(() => {
        setUpdatePassword(false)
        showNotification(
          "",
          "Senha atualizada!",
          NOTIFICATION_TYPE.success,
          NOTIFICATION_POSITION.bottomLeft
        )
      })
      .catch((error) => {
        const result = error.response.data;
        setError({
          title: getTitle(result.code),
          message: result.error
        });
      })
      .finally(() => setProgressPassword(false))
  }

  return (
    <>
      <Modal
        show={user}
        size="lg"
        centered={true}
        onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>
            {
              user ?
                <p className="m-0">{user.name} - <span className="text-muted">{user.key}</span></p>
                :
                <p className="m-0">Dados do usuário</p>
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className={`col-md-12 ${progress ? 'disabled' : ''}`}>
              {
                user ?
                  <div className="d-flex justify-content-end align-items-center">
                    <label className="d-flex justify-content-center align-items-center" style={{margin: 0}}>
                      <Switch
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onChange={updateStatus}
                        height={14}
                        width={28}
                        onColor="#F1581B"
                        activeBoxShadow="#F1581B"
                        offColor="#6c757d"
                        checked={active} />
                      <span className="checkbox-au-input">Usuário ativo</span>
                    </label>

                    <span style={{width: '1rem'}} />

                    <ButtonLink
                      text="Alterar senha"
                      icon="fas fa-lock"
                      action={() => setUpdatePassword(true)} />
                  </div>
                  : null
              }
            </div>

            <div className="row">
              <div className="col-md-12">
                {
                  user ? <UserPermissions user={user} onUpdate={onUpdate} /> :  null
                }
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={updatePassword}
        size="sm"
        centered={true}
        onHide={() => setUpdatePassword(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Nova senha de acesso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreatePassword
            progress={progressPassword}
            onComplete={ (password) => newPassword(password) }
            error={error} />
        </Modal.Body>
      </Modal>
    </>
  )

}